import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import Input from "@material-ui/core/Input";
// import SearchBar from "./searchbar";
import Utils from "./utils";
// import ExifOrientationImg from "react-exif-orientation-img";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import CardGridTemplate from "./cardgridtemplate";
import Auth from "@aws-amplify/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import PlusIcon from "@material-ui/icons/CreditCard";
import { isMobile } from "react-device-detect";
import Snackbar from "@material-ui/core/Snackbar";
import MoneyIcon from '@material-ui/icons/Money';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: "",
      userProfile: {
        areacode: "",
        availableslots: 0,
        buildingaddress: "",
        businessname: "",
        contact: "",
        contactemail: "",
        contactphone: "",
        contactphonealternate: "",
        federatedid: "",
        city: "",
        id: "",
        listings: { items: [] },
        profilepic: "",
        province: "",
        streetaddress: "",
        suburb: "",
        user: null,
        verified: false,
        cart: [],
        idOfCart: ""
      },
      loading: true,
      hasaddress: false
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    let cog = await Auth.currentAuthenticatedUser();
    if (cog) {
      this.setState({ authed: true });
      // console.log(cog);
      var username = "";
      if (cog.username) username = cog.username;
      else username = cog.id;
      // let list = await Utils.findUserProfilesByCognitoId(username);
      // Utils.log(list);
      let user = await Utils.getUserProfileById(username);
      if (user.user.areacode === null) user.user.areacode = "";
      if (user.user.city === null) user.user.city = "";
      if (user.user.streetaddress === null) user.user.streetaddress = "";
      if (
        (user.user.areacode + user.user.city + user.user.streetaddress).trim()
          .length > 0
      )
        this.setState({ hasaddress: true });
      var cartids = await Utils.getCart(username);

      if (cartids !==null) {

        var idOfCart = cartids.id;
        cartids = JSON.parse(cartids.listings);
        let cartinput = await Promise.all(cartids.map(Utils.getListingById));
        cartinput.forEach(z=>{
          if (z.listing.available === false) {this.doRemoval(user.user.id, z.listing.id)
            this.doSnackBar("Item no longer available")
          }
        })
        var cart = [];
        var imagekeys = [];
        cartinput.forEach(z => {
          cart.push(z.listing);
        });
        cart.forEach(a => {
          if (a.user.profilepic !== null) {
            a.user.profilepic = JSON.parse(a.user.profilepic);
            imagekeys.push(a.user.profilepic.key);
          }
          if (a.photos.items.length > 0)
          a.photos.items.forEach(b => {
            imagekeys.push(b.key);
          });
        });
        let uris = await Promise.all(imagekeys.map(Utils.getLatestPic));
        var defaultpic = await Utils.getLatestPic("defaultpic.png");
        cart.forEach(a => {
          if (a.photos.items.length > 0)
          a.photos.items.forEach(b => {
            uris.forEach(c => {
              if (b.key === c.key) b.uri = c.uri;
            });

          });
          else a.photos.items.push(defaultpic)
        });
        cart.forEach(a => {
          if (a.user.profilepic !== null) {
            uris.forEach(r => {
              if (a.user.profilepic.key === r.key) a.user.profilepic = r;
            });
          } else {
            a.user.profilepic = defaultpic;
          }
        });

        this.setState({ userProfile: user.user, loading: false, cart, idOfCart });
      } else {
        this.doSnackBar("Your cart is empty")
        this.setState({ loading: false ,cart:[]});
      }
    }
  }

  async goToViewItem(id) {
    this.props.history.push("/item/" + id, {});
  }

  async getPeachID(amount) {
    var cartid = this.state.userProfile.transactions.items.filter(
      z => z.status === "cart"
    )[0].id;
    function cleaner(input) {
      if (input !== null && (input !== "") & (input !== " ")) return input;
      else return ".";
    }

    var fetchstring =
      "https://scrapapp.co.za/checkout?gettoken=" + amount + "&cartid=" + cartid;
    fetchstring += "&email=" + cleaner(this.state.userProfile.contactemail);
    fetchstring += "&surname=" + cleaner(this.state.userProfile.contact);
    fetchstring += "&street1=" + cleaner(this.state.userProfile.buildingaddress);
    fetchstring += "&street2=" + cleaner(this.state.userProfile.streetaddress);
    fetchstring += "&city=" + cleaner(this.state.userProfile.city);
    fetchstring += "&state=" + cleaner(this.state.userProfile.province);
    fetchstring += "&postcode=" + cleaner(this.state.userProfile.areacode);
    fetchstring += "&mobile=" + cleaner(this.state.userProfile.contactphone);
    let s = await fetch(fetchstring);

    let t = await s.text();
    await Utils.updateCartTRXID(this.state.idOfCart, t, amount);
    window.location.replace("https://scrapapp.co.za/checkout?payment=" + t);
  }

  async doPeach(amount) {
    await this.getPeachID(amount);
  }

  async doRemoval(userData, idx) {
    await Utils.removeCartItem(userData, idx);
    this.componentDidMount();
  }
  render() {
    if (this.state.loading)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    else {
      let sum = 0;
      this.state.cart.forEach(z => (sum += parseFloat(z.price)));
      return (
        <div
          style={{
            color: "#666",
            margin: isMobile ? 10 : 0,
            justifyContent: isMobile ? "center" : "flex-start",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.statusbar}
            autoHideDuration={3000}
            onClose={() => this.setState({ statusbar: false })}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.statusbartext}</span>}
          />
          <h2
            style={{
              color: "#666",
              margin: isMobile ? 10 : 0,
              justifyContent: isMobile ? "center" : "flex-start"
            }}
          >
            Cart
          </h2>

          <CardGridTemplate
            items={this.state.cart}
            onPress={id => {
              this.goToViewItem(id);
            }}
            showsoldby={true}
            showRemovalFromCart={true}
            delCartItem={(userid, itemid) =>
          {    this.doSnackBar("Removing item")
              this.doRemoval(this.state.userProfile.id, itemid)}
            }
          />
          <Button
            style={{
              margin: 5,
              backgroundColor:
                this.state.hasaddress === true ? "#00a0e2" : "#666",
              color: "#fff",
              alignSelf: "center"
            }}
            disabled={sum===0?true:false}
            variant="contained"
            onClick={() => {
              if (this.state.hasaddress === true) {
                this.doPeach(sum.toFixed(2));
              } else {
                this.doSnackBar("You must complete your address");
              }
            }}
          >
            <PlusIcon /> Checkout {Utils.formatTextToCurrency(sum.toFixed(2))}
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor:
                this.state.hasaddress === true ? "#00a0e2" : "#666",
              color: "#fff",
              alignSelf: "center"
            }}
            disabled={sum===0?true:false}
            variant="contained"
            onClick={() => {
              this.props.history.push("/eft", {cart:this.state.cart,idOfCart:this.state.idOfCart});
            }}
          >
            <MoneyIcon /> Pay by EFT {Utils.formatTextToCurrency(sum.toFixed(2))}
          </Button>
        </div>
      );
    }
  }
}

export default withRouter(Cart);
