import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import CardGridTemplate from "./cardgridtemplate";
// import Auth from "@aws-amplify/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Button from "@material-ui/core/Button";
// import PlusIcon from '@material-ui/icons/Add';
import { isMobile } from "react-device-detect";
import Snackbar from "@material-ui/core/Snackbar";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: "",
      userProfile: {
        areacode: "",
        availableslots: 0,
        buildingaddress: "",
        businessname: "",
        contact: "",
        contactemail: "",
        contactphone: "",
        contactphonealternate: "",
        federatedid: "",
        city: "",
        id: "",
        listings: { items: [] },
        profilepic: "",
        province: "",
        streetaddress: "",
        suburb: "",
        user: null,
        verified: false
      },
      loading: true
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    // let cog = await Auth.currentAuthenticatedUser();

    let seller = atob(this.props.location.search.replace("?seller=","").replace(/%20/g," "))
    // console.log(seller)
    // let sellerid=Utils.getListingById(seller)
   var defaultpic= await Utils.getLatestPic("defaultpic.png")
    // if (cog) {
    //   this.setState({ authed: true });
    //   // console.log(cog);
    //   var username = "";
    //   if (cog.username) username = cog.username;
    //   else username = cog.id;
    //   // Utils.log(list);
      let user = (await Utils.getSellerListingById(seller));
      user.user.listings.items=user.user.listings.items.filter(z=>z.available===true)

       // console.log(user)
      var imagekeys = [];
      if (user.user.profilepic === null)  user.user.profilepic = defaultpic
      user.user.profilepic = await Utils.getLatestPic(JSON.parse(user.user.profilepic).key);
      user.user.listings.items.forEach(a => {
        a.avatarUri = user.user.profilepic.uri;
        a.user = { businessname:user.user.businessname,id:seller}
       a.user.profilepic = user.user.profilepic
        if (a.photos.items.length > 0)
          a.photos.items.forEach(b => {
            imagekeys.push(b.key);
          });
      });
      let uris = await Promise.all(imagekeys.map(Utils.getLatestPic));
      user.user.listings.items.forEach(a => {
        if (a.photos.items.length > 0)
          a.photos.items.forEach(b => {
            uris.forEach(c => {
              if (b.key === c.key) b.uri = c.uri;
            });
          });
          else a.photos.items.push(defaultpic)
      });

      this.setState({ userProfile: user.user, loading: false });
    // }
  }

  async goToViewItem(id) {

    this.props.history.push("/item/" + id, {

    });
  }

  render() {
    if (this.state.loading)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div style={{color: '#666', margin: isMobile?10:0, justifyContent: isMobile?'center':'flex-start', display: 'flex', flexDirection: 'column'}}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />


          <CardGridTemplate
            items={this.state.userProfile.listings.items}
            onPress={id => {
              this.goToViewItem(id);
            }}
            showsoldby={true}
          />
        </div>
      );
  }
}

export default withRouter(Profile);
