import React, { Component } from "react";
import "./App.css";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { withOAuth } from "aws-amplify-react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import logo from "./images/homebutton.png"; // relative path to image
import { isMobile } from "react-device-detect";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import ViewListIcon from "@material-ui/icons/ViewList";
// import AddIcon from '@material-ui/icons/Add';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ForumIcon from "@material-ui/icons/Forum";
import InfoIcon from "@material-ui/icons/Info";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    Hub.listen("auth", data => {
      // console.log({data})
      switch (data.payload.event) {
        case "signIn":
          this.setState({ authState: "signedIn", authData: data.payload.data });
          break;
        case "signIn_failure":
          this.setState({
            authState: "signIn",
            authData: null,
            authError: data.payload.data
          });
          break;
        default:
          break;
      }
    });
    this.state = {
      authState: "loading",
      authData: null,
      authError: null,
      mobinavopen: false
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ authState: "signedIn" });
      })
      .catch(e => {
        this.setState({ authState: "signIn" });
      });
  }

  signOut() {
    Auth.signOut()
      .then(() => {
        this.setState({ authState: "signIn" });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { authState } = this.state;
    return (
      <div className="App">
        {authState === "loading" && <div>loading...</div>}
        {authState === "signIn" && (
          <div>
            <Button onClick={() => this.props.history.push("/")}>
              <img alt="home" height="80" src={logo} />
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={() => {
                this.props.history.push("/termsandconditions");
              }}
            >
              <InfoIcon style={{ marginRight: 5 }} />
              Terms and Conditions
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={this.props.OAuthSignIn}
            >
              Sign in
            </Button>
          </div>
        )}
        {authState === "signedIn" && !isMobile && (
          <div>
            <Button onClick={() => this.props.history.push("/")}>
              <img alt="home" height="80" src={logo} />
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={() => {
                this.props.history.push("/currentlistings");
              }}
            >
              <ViewListIcon style={{ marginRight: 5 }} />
              Your listings
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={() => {
                this.props.history.push("/profile");
              }}
            >
              <PersonIcon style={{ marginRight: 5 }} />
              Profile
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <SearchIcon style={{ marginRight: 5 }} />
              Search
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={() => {
                this.props.history.push("/cart");
              }}
            >
              <ShoppingCartIcon style={{ marginRight: 5 }} />
              Cart
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              variant="contained"
              onClick={() => {
                this.props.history.push("/termsandconditions");
              }}
            >
              <InfoIcon style={{ marginRight: 5 }} />
              Terms and Conditions
            </Button>

            <Button
              variant="contained"
              style={{
                margin: 5,
                backgroundColor: "#00a0e2",
                color: "#fff",
                alignSelf: "center"
              }}
              onClick={this.signOut}
            >
              <ExitToAppIcon style={{ marginRight: 5 }} />
              Sign out
            </Button>
          </div>
        )}
        {authState === "signedIn" && isMobile && (
          <div
            style={{
              width: "100%",

              display: "flex",
              justifyContent: this.state.mobinavopen
                ? "flex-start"
                : "flex-start",
              alignItems: this.state.mobinavopen
                ? "flex-start"
                : "center",
              flexDirection: this.state.mobinavopen ? "column" : "row"
            }}
          >
            <Button
              onClick={() =>
                this.setState({ mobinavopen: !this.state.mobinavopen })
              }
              style={{padding: 0}}
            >
              <MenuIcon style={{ marginTop: 5, color: "#00a0e2" }} />
            </Button>
            {this.state.mobinavopen ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start"
                }}
              >
                <Button
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.setState({ mobinavopen: !this.state.mobinavopen });
                    this.props.history.push("/currentlistings");
                  }}
                >
                  <ViewListIcon style={{ marginRight: 5 }} />
                  Your listings
                </Button>
                <Button
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.setState({ mobinavopen: !this.state.mobinavopen });
                    this.props.history.push("/profile");
                  }}
                >
                  <PersonIcon style={{ marginRight: 5 }} />
                  Profile
                </Button>
                <Button
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.setState({ mobinavopen: !this.state.mobinavopen });
                    this.props.history.push("/");
                  }}
                >
                  <SearchIcon style={{ marginRight: 5 }} />
                  Search
                </Button>
                <Button
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.setState({ mobinavopen: !this.state.mobinavopen });
                    this.props.history.push("/cart");
                  }}
                >
                  <ShoppingCartIcon style={{ marginRight: 5 }} />
                  Cart
                </Button>
                <Button
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.props.history.push("/privacypolicy");
                  }}
                >
                  <InfoIcon style={{ marginRight: 5 }} />
                  Privacy Policy
                </Button>
                <Button
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.props.history.push("/shippingfaq");
                  }}
                >
                  <ForumIcon style={{ marginRight: 5 }} />
                  Shipping FAQ
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center"
                  }}
                  onClick={this.signOut}
                >
                  <ExitToAppIcon style={{ marginRight: 5 }} />
                  Sign out
                </Button>
              </div>
            ) : (
              <div style={{marginRight:  64, display: "flex", justifyContent: 'center', width: "100%"}}>
              <Button onClick={() => this.props.history.push("/")} >
                <img alt="home" height="120" src={logo} />
              </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withOAuth(App));
