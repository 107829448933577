/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:44945829-51f0-4f03-900c-d2b39b52e2f1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_1paWh9bgL",
    "aws_user_pools_web_client_id": "2kifl1i3l8gi2q9un8d3q18ud9",

    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "ottopartz-production",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://rjrq2gwoxfctng3hdjoaehce4e.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM"
};


export default awsmobile;
