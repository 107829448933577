import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import Utils from "./utils";
import ExifOrientationImg from "react-exif-orientation-img";
import ReactBnbGallery from "react-bnb-gallery";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/ShoppingBasket";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Storage } from "aws-amplify";
import { isMobile } from "react-device-detect";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import awsconfig from "./aws-exports";
Storage.configure(awsconfig);

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { mainImage: { key: "defaultpic.png" }, tags: [] },
      galleryToggle: false,
      loading: true,
      myId: "",
      matchedUsers: false,
      image: "",
      setWidth: "320",
      setdivWidth: "320",
      mainImage: {},
      setdivmargintop: 0,
      status: "",
      confirmdelete: false,
      incart: false,
      notloggedin:false
    };
    this.myRef = React.createRef();
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async getItem(id) {
    var defaultpic = await Utils.getLatestPic("defaultpic.png");
    let item = await Utils.getListingById(id);
    if (!item) this.setState({ loading: false, status: "Item not found" });
    for (var i = 0; i < item.listing.photos.items.length; i++) {
      item.listing.photos.items[i].uritn = (
        await Utils.getLatestPic(item.listing.photos.items[i].key)
      ).uri;
      item.listing.photos.items[i].uri = (
        await Utils.getLatestPicHD(item.listing.photos.items[i].key)
      ).uri;
    }
    item.listing.user.profilepic = await Utils.getLatestPic(
      item.listing.user.profilepic
    );
    if (item.listing.photos.items.length === 0)
      item.listing.photos.items.push(defaultpic);
    this.setState({ item: item, loading: false });
    try {
      let thisuser = await Auth.currentAuthenticatedUser();
      if (thisuser.username === item.listing.user.id) {
        this.setState({ matchedUsers: true });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidMount() {
    // Utils.log(this.props);
    this.getItem(this.props.location.pathname.replace("/item/", ""));
    let s = await Utils.checkCurrentUser();
    var thisUser = null;
    if (typeof s.message !== "undefined") {
      thisUser = s.message.username;
      this.setState({ thisUser });
      let user = await Utils.getUserProfileById(thisUser);
      let currentcart = user.user.transactions.items.filter(
        (z) => z.status === "cart"
      );
      if (currentcart.length > 0) {
        let ccartvals = JSON.parse(currentcart[0].listings);
        let curitem = this.props.location.pathname.replace("/item/", "");
        if (ccartvals.find((z) => z === curitem))
          this.setState({ incart: true });
        // console.log(currentcart)
        // console.log()
      }
    } else this.setState({thisUser})
  }

  listAllPhotos(input) {
    return input.listing.photos.items.map((item, index) => (
      <ExifOrientationImg
        alt={" "}
        key={index}
        src={item.uri}
        height={!isMobile ? "100px" : "100%"}
        width={isMobile ? "100%" : ""}
      />
    ));
  }

  async deleteListingById(id) {
    await Utils.deleteListingById(id);
    this.props.history.push("/currentlistings");
    // Utils.log(s);
  }

  askdelete() {
    this.setState({ confirmdelete: true });
  }
  async doAddToCart(a, b) {
    this.doSnackBar("Adding to cart...");
    await Utils.addCart(a, b);
    this.doSnackBar("Added to cart...");
  }

  showEditSection(match) {
    // console.log(this.state.item.listing.available)
    if (match) {
      return (
        <div>
          <Button
            style={{
              margin: 5,
              color:
                this.state.item.listing.available === true ? "#00a0e2" : "#888",
            }}
            disabled={this.state.item.listing.available === true ? false : true}
            transparent="true"
            onClick={() => {
              this.props.history.push(
                "/edititem/" + this.state.item.listing.id
              );
            }}
          >
            <EditIcon /> Edit
          </Button>
          <Button
            style={{
              margin: 5,
              color:
                this.state.item.listing.available === true ? "#00a0e2" : "#888",
            }}
            disabled={this.state.item.listing.available === true ? false : true}
            transparent="true"
            onClick={() => {
              // Utils.log(this.state.item);
              this.askdelete();
            }}
          >
            <DeleteIcon />
            Delete
          </Button>
        </div>
      );
    } else
      return (
        <div>
          <Button
            style={{
              margin: 5,
              color:
                this.state.incart || !this.state.item.listing.available
                  ? "#666"
                  : "#00a0e2",
            }}
            disabled={this.state.incart || !this.state.item.listing.available}
            transparent="true"
            onClick={() => {
              if (this.state.thisUser === null) {
                this.doSnackBar("You must sign in to add to cart");
              } else {
                this.doAddToCart(
                  this.state.item.listing.id,
                  this.state.thisUser
                );
                this.setState({ incart: true });
              }
            }}
          >
            <EditIcon /> Add to cart
          </Button>
        </div>
      );
  }

  remap(input) {
    var output = [];
    input.forEach((z) => {
      output.push({
        photo: z.uri,
        caption:
          z.type === "photo"
            ? "Picture taken from camera"
            : "Picture was uploaded",
        subcaption: "",
        thumbnail: z.uritn,
      });
    });
    return output;
  }

  render() {
    if (this.state.status === "" && this.state.loading)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    else if (this.state.status === "Item not found" && !this.state.loading)
      return (
        <div
          style={{ display: "flex", justifyContent: "center", color: "#666" }}
        >
          <h2>{this.state.status}</h2>
        </div>
      );
    else if (!isMobile)
      return (
        <div style={{ color: "#666" }}>
          <Dialog
            open={this.state.confirmdelete}
            onClose={() => this.setState({ confirmdelete: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete this listing?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => this.setState({ confirmdelete: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  this.deleteListingById(this.state.item.listing.id)
                }
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.statusbar}
            autoHideDuration={3000}
            onClose={() => this.setState({ statusbar: false })}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.statusbartext}</span>}
          />
          <h1>{this.state.item.title}</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: this.state.setdivWidth }}>
              {this.state.item.listing.photos.items.length > 0 ? (
                <ExifOrientationImg
                  ref={this.myRef}
                  src={this.state.item.listing.photos.items[0].uri}
                  width={this.state.setWidth}
                  onLoad={() => {
                    if (
                      this.myRef.current !== null &&
                      this.myRef.current.state.orientation === 6
                    ) {
                      var newratio =
                        this.myRef.current._reactInternalFiber.child.stateNode
                          .clientWidth /
                        this.myRef.current._reactInternalFiber.child.stateNode
                          .clientHeight;
                      this.setState({
                        setWidth: this.state.setWidth * newratio,
                        setdivWidth: 320,
                        setdivmargintop:
                          newratio *
                          (this.myRef.current._reactInternalFiber.child
                            .stateNode.clientWidth -
                            this.myRef.current._reactInternalFiber.child
                              .stateNode.clientHeight),
                      });
                    }
                  }}
                />
              ) : null}
              <div
                style={{ marginTop: this.state.setdivmargintop }}
                onClick={() => {
                  this.setState({ galleryToggle: true });
                }}
              >
                {this.listAllPhotos(this.state.item)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div style={{ marginBottom: 10, fontSize: 20 }}>
                <b>
                  {this.state.item.listing.title}
                  {this.state.item.listing.available === true ? "" : " (Sold)"}
                </b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Details:
                <b>{" " + this.state.item.listing.details}</b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Condition:
                <b>{" " + this.state.item.listing.condition}</b>
              </div>

              <div style={{ marginBottom: 10 }}>
                Price:
                <b>
                  {" " +
                    Utils.formatTextToCurrency(this.state.item.listing.price)}
                </b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Listing date:
                <b>{" " + this.state.item.listing.listdate.substr(0, 10)}</b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Tags:
                {this.state.item.listing.tags
                  .filter((z) => z !== " ")
                  .filter((z) => z !== "")
                  .sort((a, b) => a.length - b.length)
                  .map((e, i) => (
                    <Chip key={i} label={e} />
                  ))}
              </div>

              {this.showEditSection(this.state.matchedUsers)}
            </div>
          </div>

          <ReactBnbGallery
            show={this.state.galleryToggle}
            photos={this.remap(this.state.item.listing.photos.items)}
            onClose={() => this.setState({ galleryToggle: false })}
          />
        </div>
      );
    else
      return (
        <div style={{ color: "#666", margin: 10 }}>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.statusbar}
            autoHideDuration={3000}
            onClose={() => this.setState({ statusbar: false })}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.statusbartext}</span>}
          />
          <h2>{this.state.item.title}</h2>
          <div style={{ display: "flex", flexDirection: "columm" }}>
            <div style={{ width: this.state.setdivWidth }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div style={{ marginBottom: 10 }}>
                <b>
                  {this.state.item.listing.title}
                  {this.state.item.listing.available === true ? "" : " (Sold)"}
                </b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Details:
                <b>{" " + this.state.item.listing.details}</b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Condition:
                <b>{" " + this.state.item.listing.condition}</b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Price:
                <b>
                  {" " +
                    Utils.formatTextToCurrency(this.state.item.listing.price)}
                </b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Listing date:
                <b>{" " + this.state.item.listing.listdate.substr(0, 10)}</b>
              </div>
              <div style={{ marginBottom: 10 }}>
                Tags:
                {this.state.item.listing.tags
                  .filter((z) => z !== " ")
                  .filter((z) => z !== null)
                  .filter((z) => z !== "")
                  .sort((a, b) => a.length - b.length)
                  .map((e, i) => (
                    <Chip key={i} label={e} />
                  ))}
              </div>
              {this.showEditSection(this.state.matchedUsers)}
            </div>
          </div>

          <div
            onClick={() => {
              this.setState({ galleryToggle: true });
            }}
          >
            {this.listAllPhotos(this.state.item)}
          </div>
          <ReactBnbGallery
            show={this.state.galleryToggle}
            photos={this.remap(this.state.item.listing.photos.items)}
            onClose={() => this.setState({ galleryToggle: false })}
          />
        </div>
      );
  }
}

export default withRouter(Item);
