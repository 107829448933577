import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
// import Input from "@material-ui/core/Input";
import Utils from "./utils";
import Auth from "@aws-amplify/auth";
// import Storage  from "@aws-amplify/storage";
import ImageUploader from "react-images-upload";
// var Buffer = require("buffer/").Buffer;
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TagButton from "./tagbutton";
import TagButtonMulti from "./tagbuttonmulti";
import demo1 from "./images/textdemo.png";
import demo2 from "./images/csvdemo.png";
import demo3 from "./images/picdemo.png";
import CSVReader from "react-csv-reader";

class AddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      final: [],
      title: "",
      detail: "",
      listing: { ctag: "" },
      condition: "",
      price: "",
      deliverycondition: "",
      tags: "",
      ctag: "",
      visibleTags: false,
      taglist: null,
      filesToUpload: [{}],
      pictures: [],
      galleryImages: [],
      slotsavailable: true,
      makevisible: false,
      makeSelected: "",
      modelSelected: "",
      modelvisible: false,

      yearsvisible: false,
      parts1visible: false,
      parts2visible: false,
      parts3visible: false,
      parts4visible: false,
      parts5visible: false,
      parts1choice: "",
      parts2choice: "",
      parts3choice: "",
      parts4choice: "",
      parts5choice: "",
      yearsTag: [],
      yearsAvailable: [],
      bodyTags: [],
    };
  }
  async getBulkFile(input) {
    console.log(input);
    let labels = input.splice(0, 1)[0];
    console.log(labels);
    console.log(input);
    let final = [];
    input.forEach((z) => {
      let item = {};
      item[labels[0]] = z[0] === "" ? null : z[0];
      item[labels[1]] = z[1] === "" ? null : z[1];
      item[labels[2]] = z[2] === "" ? null : z[2];
      item[labels[3]] = z[3] === "" ? null : z[3];
      item[labels[4]] = z[4] === "" ? null : z[4];
      item[labels[5]] = z[5] === "" ? null : z[5];
      final.push(item);
    });
    console.log(final);
    this.setState({ final });
  }
  async componentDidMount() {
    const yearsAvailable = [];
    for (let i = 2000; i < 2023; i++) {
      yearsAvailable.push({ year: `${i}`, selected: false });
    }
    this.setState({ yearsAvailable });

    let userid = await Utils.getThisUserId();
    let userprofile = await Utils.getUserProfileById(userid);
    if (userprofile.user.verified !== true) {
      this.doSnackBar("You need to apply for access to list an item");
      this.props.history.push("/Profile");
    }
  }
  addBodyTag(input) {
    let oldbodyTags = this.state.bodyTags;
    oldbodyTags.push(input);
    oldbodyTags = [...new Set(oldbodyTags)];
    this.setState({ bodyTags: oldbodyTags });
  }

  async createOttoPartzListing(inp, pi) {
    let cog = await Auth.currentAuthenticatedUser();
    if (inp.length > 0) {
      for (let i = 0; i < inp.length; i++) {
        let listing = inp[i];
        let valid = true;
        if (
          typeof listing.title === "undefined" ||
          listing.title === "" ||
          listing.title === null
        ) {
          valid = false;
          this.doSnackBar("Name of item cannot be blank ");
        }
        if (
          typeof listing.details === "undefined" ||
          listing.details === "" ||
          listing.details === null
        ) {
          valid = false;
          this.doSnackBar("Details cannot be blank ");
        }
        if (
          typeof listing.condition === "undefined" ||
          listing.condition === "" ||
          listing.condition === null
        ) {
          valid = false;
          this.doSnackBar("Condition cannot be blank ");
        }
        if (
          typeof listing.price === "undefined" ||
          listing.price === "" ||
          listing.price === null
        ) {
          valid = false;
          this.doSnackBar("Price cannot be less than R500 ");
        }
        if (parseFloat(listing.price, 2) < 500) {
          valid = false;
          this.doSnackBar("Price cannot be less than R500 ");
        }

        if (valid) {
          let input = listing;

          console.log(input);
          console.log(input.photofilenames);
          let picnames = input.photofilenames.split(",");
          let picstoload = [];
          let newpics = pi.forEach((z) => {
            let name = z.split(",")[0].split(";")[1].replace("name=", "");
            console.log(name);
            picnames.forEach((a) => {
              if (a === name) picstoload.push(z);
            });
          });
          console.log(picnames);
          console.log(newpics);
          console.log(picstoload);
          this.doSnackBar("Saving " + input.title + " ..." +(i+1) +" of "+ inp.length);
          var username = "";

          if (cog.username) username = cog.username;
          const newinput = input;
          newinput.available = true;
          newinput.tags = newinput.tags.split(",");
          // if (this.state.bodyTags.length > 0) newinput.tags = this.state.bodyTags;
          // if (this.state.yearsTag.length > 0)
          //   newinput.tags = input.tags.concat(this.state.yearsTag);
          // if (this.state.listing.ctag !== "")
          //   newinput.tags = input.tags.concat(this.state.listing.ctag);
          // if (this.state.makeSelected !== "")
          //   newinput.tags = input.tags.concat(this.state.makeSelected);
          // if (this.state.modelSelected !== "") {
          //   newinput.tags = input.tags.concat(this.state.modelSelected);
          // }
          // newinput.tags = input.tags.concat(
          //   this.state.yearsAvailable.filter((a) => a.selected).map((z) => z.year)
          // );
          newinput.hiddentags = input.tags.map((z) => z.toLowerCase());
          newinput.hiddentags = newinput.hiddentags.concat(newinput.title);
          newinput.hiddentags = newinput.hiddentags.concat(newinput.condition);
          newinput.hiddentags = newinput.hiddentags.concat(newinput.details);
          newinput.hiddentags.forEach((z) => {
            z = z.toLowerCase();
          });
          console.log("tags");
          console.log(newinput.tags);
          console.log("htags");
          console.log(newinput.hiddentags);
          newinput.hiddentags = JSON.stringify(newinput.hiddentags);
          newinput.tags = JSON.stringify(newinput.tags);
          newinput.listdate = new Date(Date.now());
          newinput.ottoPartzListingUserId = username;

          const save = await Utils.createOttoPartzListing(newinput);

          async function saveapic(input) {
            var b64 = input.b64.split(",")[1];
            var fname = input.b64.split(",")[0];
            fname = fname.split(";")[1];
            fname = fname.split(".")[1];
            // fname = Utils.generateName()+"."+fname
            var ctype = input.b64.split(",")[0];
            ctype = ctype.split(";")[0];
            ctype = ctype.split(":")[1];

            const pi2 = await Utils.uploadtoS3(b64, fname, ctype);
            let filesave = {
              key: pi2.key,
              id: input.id,
              type: "gallery",
              height: 500,
              width: 500,
            };
            let t = await Utils.createOttoPartzPhotos(filesave);
            return t;
          }
          var picstosave = [];

          picstoload.forEach((z) => picstosave.push({ id: save.listing.id, b64: z }));
          if (picstosave.length > 0) {
            await Promise.all(picstosave.map(saveapic));
          }
        }
      }
    }
    this.doSnackBar("all done ")
  }

  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", color: "#666" }}>
        <Dialog
          open={this.state.makevisible}
          onClose={() => this.setState({ makevisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={Utils.pureCarMakesList()}
                onDone={() =>
                  this.setState({
                    makevisible: false,
                  })
                }
                onAll={() =>
                  this.setState({
                    makevisible: false,
                  })
                }
                onSelected={(make) => {
                  this.setState({
                    makevisible: false,
                    modelvisible: true,
                    makeSelected: make,
                    modelsAvailable: Utils.formattedCarsList().filter(
                      (z) => z.make === make
                    )[0].model,
                  });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.modelvisible}
          onClose={() => this.setState({ modelvisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={this.state.modelsAvailable}
                onDone={() =>
                  this.setState({
                    makevisible: true,
                    modelvisible: false,
                  })
                }
                onAll={() =>
                  this.setState({
                    modelvisible: false,
                  })
                }
                onSelected={(model) => {
                  this.setState({
                    makevisible: false,
                    modelvisible: false,
                    modelSelected: model,
                  });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.parts1visible}
          onClose={() => this.setState({ parts1visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts1visible: false,
                  })
                }
                onAll={() =>
                  this.setState({
                    parts1visible: false,
                  })
                }
                items={[...new Set(Utils.partslist().map((z) => z.Cat1))]}
                onSelected={(input) => {
                  this.setState({
                    parts1visible: false,
                    parts2visible: true,
                    parts1choice: input,
                  });
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts2visible}
          onClose={() => this.setState({ parts2visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={Utils.getSubcatOfParts(this.state.parts1choice)}
                onDone={() =>
                  this.setState({
                    parts1visible: true,
                    parts2visible: false,
                  })
                }
                onAll={() =>
                  this.setState({
                    parts2visible: false,
                  })
                }
                onSelected={(input) => {
                  this.setState({
                    parts2visible: false,
                    parts3visible: true,
                    parts2choice: input,
                  });
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts3visible}
          onClose={() => this.setState({ parts3visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts3visible: false,
                    parts2visible: true,
                  })
                }
                onAll={() =>
                  this.setState({
                    parts3visible: false,
                  })
                }
                items={Utils.getComponentOfSubCatParts(
                  this.state.parts1choice,
                  this.state.parts2choice
                )}
                onSelected={(input) => {
                  if (
                    (Utils.getSubComponentOfSubCatParts(
                      this.state.parts1choice,
                      this.state.parts2choice,
                      input
                    ).length === 1 &&
                      Utils.getSubComponentOfSubCatParts(
                        this.state.parts1choice,
                        this.state.parts2choice,
                        input
                      )[0] === input) ||
                    (Utils.getSubComponentOfSubCatParts(
                      this.state.parts1choice,
                      this.state.parts2choice,
                      input
                    ).length === 1 &&
                      Utils.getSubComponentOfSubCatParts(
                        this.state.parts1choice,
                        this.state.parts2choice,
                        input
                      )[0] === "")
                  ) {
                    this.setState({
                      parts3visible: false,
                      parts4visible: false,
                      parts3choice: input,
                    });
                  } else {
                    this.setState({
                      parts3visible: false,
                      parts4visible: true,
                      parts3choice: input,
                    });
                  }
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts4visible}
          onClose={() => this.setState({ parts4visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts3visible: true,
                    parts4visible: false,
                  })
                }
                onAll={() =>
                  this.setState({
                    parts4visible: false,
                  })
                }
                items={Utils.getSubComponentOfSubCatParts(
                  this.state.parts1choice,
                  this.state.parts2choice,
                  this.state.parts3choice
                )}
                onSelected={(input) => {
                  this.addBodyTag(input);
                  this.setState({ parts4visible: false, parts4choice: input });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.yearsvisible}
          onClose={() => this.setState({ yearsvisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButtonMulti
                items={this.state.yearsAvailable}
                onDone={() => this.setState({ yearsvisible: false })}
                onSelected={(input) => {
                  const yearsTagOld = this.state.yearsAvailable;
                  yearsTagOld.forEach((z) => {
                    if (z.year === input) z.selected = !z.selected;
                  });
                  this.setState({ yearsAvailable: yearsTagOld });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <div style={{ width: 1000 }} />
        <h2>Bulk Listing</h2>
        <div>
          Instructions:
          <br />
          Save your spreadsheet as a CSV file
          <br />
          The columns needed are
          "title,details,condition,tags,price,photofilenames"
          <br />
          eg of a line: <br />
          Range Rover Bumper,Green front 2008,Good used small
          scratch,"2008,Range Rover, Bumper",1234,"front12.jpg,left34234.png"
          <br />
          When you upload pictures, select all the pics that are in your csv
          <br />
          Once you're done uploading the pictures and CSV, click Save. <br />
          NOTE: THERE IS NO UNDO! <br />
          NOTE: CSV must have a heading like in the 2nd picture. <br />
          NOTE: make sure each cell does not have special characters and no
          commas
          <br />
          If your cell contains commas, it needs to be within quotes. <br />
          Upload a few to test what you're doing.
        </div>
        <img alt="" src={demo2} />
        <img alt="" src={demo1} />
        <img alt="" src={demo3} />
        <br />
        <br />
        <br />
        <div>
          <CSVReader
            label="Upload CSV here"
            onFileLoaded={(data, fileInfo) =>
              this.getBulkFile(data.filter((z) => z.length === 6))
            }
          />
        </div>
        <br />
        <br />
        <br />
        <div>
          <ImageUploader
            withIcon={true}
            buttonText="Choose images"
            onChange={(pictureFiles, pictureDataURLs) => {
              Utils.log(pictureFiles);
              Utils.log(pictureDataURLs);
              this.setState({ pictures: pictureDataURLs });
            }}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />
        </div>
        <Button
          style={{
            margin: 5,
            marginTop: 15,
            marginBottom: 50,
            backgroundColor: "#00a0e2",
            color: "#fff",
          }}
          variant="contained"
          onClick={() => {
            this.createOttoPartzListing(this.state.final, this.state.pictures);
          }}
        >
          Save
        </Button>

      </div>
    );
  }
}

export default withRouter(AddItem);
