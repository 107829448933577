import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Switch from "@material-ui/core/Switch";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [] };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    let u = await Utils.getThisUserId();
    let u2 = await Utils.getUserProfileById(u);
    if (u2.user.admin !== true) this.props.history.push("/profile");
    this.doSnackBar("Getting users");
    let users = await Utils.listusers();
    this.setState({ users });
  }
  async changeDisable(input) {
    await Utils.saveDisabledUser(input);
    this.doSnackBar("Changed");
    this.componentDidMount();
  }
  async changeVerify(input) {
    await Utils.saveVerifiedUser(input);
    this.doSnackBar("Changed");

    this.componentDidMount();
  }
  async changeAdmin(input) {
    await Utils.saveAdminUser(input);
    this.doSnackBar("Changed");

    this.componentDidMount();
  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        {this.state.users.map((e, i) => (
          <Card key={i} style={{ marginBottom: 10 }}>
            <CardContent>
              <div>
                <div>
                  <b>Business name: </b>
                  {e.businessname}
                </div>
                <div>
                  <b>Contact: </b>
                  {e.contact}
                </div>
                <div>
                  <b>Email: </b>
                  {e.contactemail}
                </div>
                <div>
                  <b>Security ID: </b>
                  {e.id}
                </div>
                <div>
                  <b>Admin: </b>
                  <Switch
                    checked={e.admin === true ? true : false}
                    onChange={(change) => {
                      this.changeAdmin({
                        id: e.id,
                        admin: change.target.checked,
                      });
                    }}
                  />
                </div>
                <div>
                  <b>Verify Seller: </b>
                  <Switch
                    checked={e.verified === true ? true : false}
                    onChange={(change) => {
                      this.changeVerify({
                        id: e.id,
                        verified: change.target.checked,
                      });
                    }}
                  />
                </div>
                <div>
                  <b>Disable Seller: </b>
                  <Switch
                    checked={e.disabled === true ? true : false}
                    onChange={(change) => {
                      this.changeDisable({
                        id: e.id,
                        disabled: change.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }
}

export default withRouter(Profile);
