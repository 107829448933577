import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SearchBar from "./searchbar";
import Utils from "./utils";
import { Hub } from "@aws-amplify/core";
import { Cache } from "aws-amplify";
import Auth from "@aws-amplify/auth";
// import  Amplify from "@aws-amplify/core";
import awsconfig from "./aws-exports"; // your Amplify configuration
import Snackbar from "@material-ui/core/Snackbar";
import CardGridTemplate from "./cardgridtemplate";
// import Storage from "@aws-amplify/storage";

class AuthSearch extends Component {
  constructor(props) {
    super(props);
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          this.componentDidMount();
          this.setState({ authState: "signedIn", authData: data.payload.data });
          break;
        case "signIn_failure":
          this.setState({
            authState: "signIn",
            authData: null,
            authError: data.payload.data,
          });
          break;
        default:
          break;
      }
    });
    this.state = {
      searchResult: "",
      selfuserid: "",
      defaultpic: null,
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {




    // let r = await Auth.currentAuthenticatedUser();
    // console.log({ r })


    let s = await Cache.getItem("search");
    this.setState({ defaultpic: await Utils.getLatestPic("defaultpic.png") });
    if (s === null) this.getNewestItemsUnauth();
    else this.setState({ searchResult: JSON.parse(s) });
  }

  async getNewestItemsUnauth() {
    await Cache.removeItem("search");
    this.doSnackBar("Getting the latest items...");
    try {
      let s = await Auth.currentAuthenticatedUser();
      this.setState({ selfuserid: s.username });
      console.log({ s });

    } catch (err) {
      window.localStorage.removeItem(`CognitoIdentityId-${awsconfig.aws_cognito_identity_pool_id}`)
      await Auth.currentCredentials();
     // console.log({ r })
      // await Amplify.configure(         awsconfig)
      // await Storage.configure(         awsconfig)
      // await Auth.currentCredentials();
      // console.log({ err });

    }

      let result = await Utils.getNewestItemsUnauth();
      // console.log({ result });
      result = result
        .filter((z) => z.user.id !== this.state.selfuserid)
        .filter((z) => z.user.disabled !== true)
        .filter((z) => z.available !== false);
      var imagekeys = [];
      result.forEach((a) => {
        if (a.user.profilepic !== null) {
          // console.log(a.user.profilepic)
          a.user.profilepic = JSON.parse(a.user.profilepic);
          imagekeys.push(a.user.profilepic.key);
        }
        if (a.photos.items.length > 0)
          a.photos.items.forEach((b) => {
            imagekeys.push(b.key);
          });
      });
      let uris = await Promise.all(imagekeys.map(Utils.getLatestPic));
      // console.log(uris)
      var defaultpic = this.state.defaultpic;
      result.forEach((a) => {
        if (a.photos.items.length > 0)
          a.photos.items.forEach((b) => {
            uris.forEach((c) => {
              if (b.key === c.key) b.uri = c.uri;
            });
          });
        else a.photos.items.push(defaultpic);
      });
      result.forEach((a) => {
        if (a.user.profilepic !== null) {
          uris.forEach((r) => {
            if (a.user.profilepic.key === r.key) a.user.profilepic = r;
          });
        } else {
          a.user.profilepic = defaultpic;
        }
      });
      this.setState({ searchResult: result });

  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />


  <SearchBar
  onResult={(result) => {
    if (this.state.selfuserid !== "")
    result = result.filter(
      (z) => z.user.id !== this.state.selfuserid
    );
    result = result.filter((z) => z.user.disabled !== true);
    if (result.length === 0) this.doSnackBar("Nothing found");
    var date = new Date(Date.now());
    date = date.getTime() + 10000;
    result.forEach((a) => {
      if (a.photos.items.length === 0)
      a.photos.items.push(this.state.defaultpic);
    });
    Cache.setItem("search", JSON.stringify(result), { expires: date });
    this.setState({ searchResult: result });
  }}
  />

        <CardGridTemplate
          items={this.state.searchResult}
          onPress={(id) => this.props.history.push("/item/" + id)}
          showsoldby={true}
        />
      </div>
    );
  }
}

export default withRouter(AuthSearch);
