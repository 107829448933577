import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
// import Button from "@material-ui/core/Button";

class Privacy extends Component {
  render() {
    return (
      <div style={{ color: "#666" }}>
        <h2>Seneca Auto Group Website Terms and Conditions of Use</h2>
        <h2>General Disclaimer</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Anyone may use any information presented on this website for
                non-commercial purposes, subject to any specific terms of use
                that might appear with such information, provided that the use
                of such information is accompanied by an acknowledgement that
                Seneca Auto Group is the source. If you choose to access and use
                any of the information made available to you on this site, you
                do so subject to these Website Terms and Conditions of Use and
                any specific terms that the owner or provider of that
                information has imposed on its use.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Please take note of all Website Terms and Conditions of Use.
                Your use of this website implies an acceptance of the Website
                Terms and Conditions of Use and any other specific terms and an
                agreement to be bound by them. In the event of a conflict
                between the Website Terms and Conditions of Use of this website
                and any specific terms applicable, and only to the extent of the
                conflict, the more specific term will apply.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group grants you a limited, revocable license to use
                this website subject to the Terms. The license is a personal,
                nontransferable, non-sublicensable, revocable license to access
                and use the website only as expressly permitted in these Website
                Terms and Conditions of Use. Except for this limited license, we
                do not grant you any other rights or license with respect to
                this website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Unless we have granted you permission in advance and in writing,
                you may use the website only for your personal, non-commercial
                use, and not to provide services to a third party. Any person
                wishing to use this web site contrary to the Website Terms and
                Conditions of Use must obtain prior written consent from Seneca
                Auto Group.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group makes every effort to ensure, but cannot and
                does not guarantee, and makes no warranties as to, the accuracy,
                accessibility, integrity and timeliness of this information.
                Seneca Auto Group assumes no liability or responsibility for any
                errors or omissions in the content of this site and further
                disclaims any liability of any nature for any loss howsoever
                arising in connection with using this website or any content
                contained herein. Furthermore, Seneca Auto Group reserves the
                right to make changes to these materials at any time without
                notice. If you find any inaccurate, out of date or incomplete
                information or material on this website, or if you suspect that
                something is an infringement of intellectual property rights,
                you must let us know immediately by contacting Seneca Auto Group
                or the owner or provider of the information to which the issue
                relates.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Unless otherwise stated, all right, title, interest, and
                ownership (including all rights under all copyright, patent, and
                other intellectual property laws) in, to or of this website are
                the sole property of or will vest in Seneca Auto Group or a
                third-party licensor. All moral rights are reserved.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Trademarks. Seneca Auto Group’s logo and sub-logos, marks, and
                trade names are the trademarks of Seneca Auto Group and no
                person may use them without permission. Any other trademark or
                trade name that may appear on this web site or other marketing
                material of Seneca Auto Group is the property of its respective
                owner.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You should independently verify any information and material on
                this website before relying upon it. The information and
                material on this website are not substitutes for the exercise of
                professional judgment. If you are not qualified or experienced
                enough to make that judgment, you should take professional
                advice or contact the relevant information owner or provider for
                further information and advice.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Hyperlinks to other websites are provided as a convenience only,
                and imply neither responsibility for, nor approval of, the
                information contained in those other websites on the part of
                Seneca Auto Group. Seneca Auto Group makes no warranty, either
                express or implied, as to the accuracy, availability,
                reliability or content of such information, text, graphics and
                hyperlinks. Seneca Auto Group will not be liable for any
                indirect or consequential loss, or for any loss of business,
                profit, revenue, goodwill or data, lost or wasted management
                time or the lost time of other employees arising from your use
                of this website or any information or material on it, or your
                inability to use it (whether that loss is direct or indirect).{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                While Seneca Auto Group makes every effort to ensure that any
                executable material available to be downloaded from Seneca Auto
                Group' website is free of any virus, it cannot guarantee that
                the material is free from any or all viruses. Seneca Auto Group
                is not responsible for any loss or damage howsoever caused by
                the executable material and potentially malicious code contained
                therein.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You are responsible for ensuring that your computer systems are
                suitable to access and use this website. You are responsible for
                implementing sufficient anti-virus and other security checks to
                ensure the accuracy of data input and output.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The collection and retention of personal information by Seneca
                Auto Group through its website and through the registration
                process is subject to the terms of the Seneca Auto Group privacy
                policy currently in force and available for review on this
                website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Restrictions on Use of Website:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.a
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Framing. No person, business, or website may frame this website
                or any of the pages on this website, or use any other framing
                technique to enclose any portion or aspect of the website, or
                mirror or replicate any portion of the website;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.b
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Linking. We prohibit “deep linking” to any other pages in a
                manner that would incorrectly suggest endorsement or support of
                Seneca Auto Group or suggests you are the owner of any
                intellectual property belonging to Seneca Auto Group.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.c
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Spiders and Crawlers. No person, business, or web site may use
                any technology (including spiders or crawlers) to search and
                gain any information from this website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You specifically may not:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.i
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Copy, reproduce, upload, post, display, republish, distribute,
                transmit, any part of the content in any form whatsoever;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.ii
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Modify, translate into any language or computer language, or
                create derivative works from, any content or any part of this
                website;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.iii
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Reverse engineer any part of this website;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.iv
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Use the website other than to make legitimate reservations or
                bookings;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.v
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Use the website to make any false, fraudulent or speculative
                reservation, or any reservation in anticipation of demand;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.vi
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Disguise the information transmitted through the website;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.vii
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Sell, offer for sale, transfer, or license any portion of the
                website in any form to any third parties;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.viii
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Use or access the website in any way that, in our reasonable
                judgment, adversely affects the performance or function of the
                website, or any other computer systems or networks used by
                Seneca Auto Group other website users;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.ix
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Upload or transmit to the website or use any device, software or
                routine that contains viruses, Trojan horses, worms, time bombs,
                or other computer programming routines that may damage,
                interfere or attempt to interfere with, intercept, the normal
                operation of our website, or appropriate the website or any
                system, or take any action that imposes an unreasonable load on
                our computer equipment, or that infringes upon the rights of a
                third party;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.x
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Use any device, software, or routine that interferes, or
                attempts to interfere, with the normal operation of our website,
                or take any action that impose an unreasonable load on our
                equipment; or
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.d.xi
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                disguise the origin of the information transmitted through the
                website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                All warranties, representations, terms, conditions and
                undertakings, whether implied by statute, common law, custom,
                trade usage, course of dealing or otherwise (including any
                implied warranty, representation, term, condition or undertaking
                of satisfactory quality or fitness for a particular purpose) are
                excluded to the fullest extent allowed by law by Seneca Auto
                Group and the information owners and providers.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                15
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If the use of any information or material on this website is
                unlawful in any jurisdiction (because of your nationality,
                residence or for some other reason), that information or
                material is not offered. If you are outside the Republic of
                South Africa you must satisfy yourself that you are lawfully
                able to use any such information and materials. We accept no
                liability, to the extent allowed by the law, for any costs,
                losses or damages resulting from or related to the access or
                attempted access of any information or materials by anyone
                outside the Republic of South Africa.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                No delay, neglect or forbearance on the part of Seneca Auto
                Group in enforcing any of their rights under the Website Terms
                and Conditions of Use will be, or be deemed to be, a waiver and
                it will not prejudice any right of Seneca Auto Group.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                17
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If any of the Website Terms and Conditions of Use is held to be
                unenforceable, illegal or in some other way invalid, the
                unenforceable, illegal or invalid provision will be deemed to be
                severable and will not affect the remainder of the Website Terms
                and Conditions of Use which will continue to be of full force
                and effect.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                18
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                All conditions and terms of use contained in this website are
                governed by South African law and you agree to submit to the
                non-exclusive jurisdiction of the courts of South Africa.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                No addition to or modification of any provision of these Website
                Terms and Conditions of Use will be binding on Seneca Auto Group
                unless made in writing and signed by their duly authorised
                representatives.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                20
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group may modify, suspend, or discontinue providing
                this web site (with or without notice) and will not be liable.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}
export default withRouter(Privacy);
