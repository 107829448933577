import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import Utils from "./utils";
import ExifOrientationImg from "react-exif-orientation-img";
import { Storage } from "aws-amplify";
import Button from "@material-ui/core/Button";
import TrashIcon from "@material-ui/icons/Delete";

import awsconfig from "./aws-exports";
Storage.configure(awsconfig);

export default class CardTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { image: "" };
  }

  componentDidMount() {}
  render() {
    return (
      <div>
        <Card style={{ width: 300, height: 320, margin: 10 }}>
          <CardContent>

            <div>
              <Button
                onClick={() => this.props.onPress(this.props.item.id)}
                style={{
                  height: 200,
                  marginTop: 5,
                  overflow: "hidden",
                  backgroundColor: "#fff"
                }}
              >
                {this.props.item.photos.items.length > 0 ? (
                  <ExifOrientationImg
                    alt=""
                    src={this.props.item.photos.items[0].uri || ""}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      height: "100%",
                      objectPosition: "50% 50%"
                    }}
                  />
                ) : (
                  <div />
                )}
              </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Avatar
                alt={this.props.title}
                src={this.props.item.user.profilepic.uri}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 5
                }}
              >
                <div style={{ fontSize: 14, color: "#666" }}>
                  {this.props.item.title.length>28?this.props.item.title.substr(0,28)+"...":this.props.item.title}
                </div>
                <div style={{ fontSize: 14, color: "#999" }}>
                  {Utils.formatTextToCurrency(this.props.item.price)}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "#999",
                marginTop: 10,
                marginBottom: 15,
                fontSize: 11
              }}
            >
              {this.props.soldby}
            </div>
          </CardContent>
        </Card>
        {this.props.showRemovalFromCart === true ? (
          <Button
            onClick={() =>
              this.props.delCartItem(
                this.props.item.user.id,
                this.props.item.id
              )
            }
            style={{ color: "#00a0e2" }}
          >
            <TrashIcon style={{ color: "#00a0e2" }} />
            Remove from cart
          </Button>
        ) : null}
      </div>
    );
  }
}
