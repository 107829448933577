/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOttoPartzUser = /* GraphQL */ `
  query GetOttoPartzUser($id: ID!) {
    getOttoPartzUser(id: $id) {
      id
      user
      businessname
      contact
      buildingaddress
      streetaddress
      suburb
      city
      province
      areacode
      profilepic
      contactphone
      contactphonealternate
      verified
      disabled
      admin
      vatnumber
      contactemail
      federatedid
      listings {
        items {
          id
          title
          details
          condition
          price
          available
          listdate
          ref
          tags
          hiddentags
          views
          createdAt
          updatedAt
        }
        nextToken
      }
      transactions {
        items {
          id
          status
          total
          waybilldata
          invoiceNumber
          transactiondate
          transactioncode
          transactionstatus
          listings
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOttoPartzUsers = /* GraphQL */ `
  query ListOttoPartzUsers(
    $filter: ModelOttoPartzUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOttoPartzUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOttoPartzListing = /* GraphQL */ `
  query GetOttoPartzListing($id: ID!) {
    getOttoPartzListing(id: $id) {
      id
      title
      details
      condition
      price
      available
      listdate
      ref
      tags
      hiddentags
      views
      photos {
        items {
          id
          key
          type
          height
          width
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOttoPartzListings = /* GraphQL */ `
  query ListOttoPartzListings(
    $filter: ModelOttoPartzListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOttoPartzListings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        details
        condition
        price
        available
        listdate
        ref
        tags
        hiddentags
        views
        photos {
          nextToken
        }
        user {
          id
          user
          businessname
          contact
          buildingaddress
          streetaddress
          suburb
          city
          province
          areacode
          profilepic
          contactphone
          contactphonealternate
          verified
          disabled
          admin
          vatnumber
          contactemail
          federatedid
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOttoPartzPhotos = /* GraphQL */ `
  query GetOttoPartzPhotos($id: ID!) {
    getOttoPartzPhotos(id: $id) {
      id
      key
      type
      height
      width
      listing {
        id
        title
        details
        condition
        price
        available
        listdate
        ref
        tags
        hiddentags
        views
        photos {
          nextToken
        }
        user {
          id
          user
          businessname
          contact
          buildingaddress
          streetaddress
          suburb
          city
          province
          areacode
          profilepic
          contactphone
          contactphonealternate
          verified
          disabled
          admin
          vatnumber
          contactemail
          federatedid
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOttoPartzPhotoss = /* GraphQL */ `
  query ListOttoPartzPhotoss(
    $filter: ModelOttoPartzPhotosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOttoPartzPhotoss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        type
        height
        width
        listing {
          id
          title
          details
          condition
          price
          available
          listdate
          ref
          tags
          hiddentags
          views
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOttoPartzInvoiceCounter = /* GraphQL */ `
  query GetOttoPartzInvoiceCounter($id: ID!) {
    getOttoPartzInvoiceCounter(id: $id) {
      id
      number
      createdAt
      updatedAt
    }
  }
`;
export const listOttoPartzInvoiceCounters = /* GraphQL */ `
  query ListOttoPartzInvoiceCounters(
    $filter: ModelOttoPartzInvoiceCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOttoPartzInvoiceCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOttoPartzTransactions = /* GraphQL */ `
  query GetOttoPartzTransactions($id: ID!) {
    getOttoPartzTransactions(id: $id) {
      id
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      total
      waybilldata
      invoiceNumber
      transactiondate
      transactioncode
      transactionstatus
      listings
      createdAt
      updatedAt
    }
  }
`;
export const listOttoPartzTransactionss = /* GraphQL */ `
  query ListOttoPartzTransactionss(
    $filter: ModelOttoPartzTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOttoPartzTransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          user
          businessname
          contact
          buildingaddress
          streetaddress
          suburb
          city
          province
          areacode
          profilepic
          contactphone
          contactphonealternate
          verified
          disabled
          admin
          vatnumber
          contactemail
          federatedid
          createdAt
          updatedAt
        }
        status
        total
        waybilldata
        invoiceNumber
        transactiondate
        transactioncode
        transactionstatus
        listings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
