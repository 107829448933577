import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./App.css";
import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import awsconfig from "./aws-exports";
// import UnauthSearch from "./unauthsearch";
import AuthSearch from "./authsearch";
import Profile from "./profile";
import OrderHistory from "./orderhistory";
import VerifySeller from "./verifyseller";
import RequestAccess from "./requestaccess";
import SellerListings from "./sellerlistings";
import ProfileEdit from "./profileedit";
import EFT from "./eft";
import Cart from "./cart";
import Waybills from "./waybills";
import ProcessEFT from "./processeft";
import Thanks from "./thanks";
import AddItem from "./additem";
import AddItemBulk from "./additembulk";
import Privacy from "./privacypolicy";
import Privacy2 from "./privacypolicy2";
import Privacy3 from "./privacypolicy3";
import Privacy4 from "./privacypolicy4";
import ShippingFAQ from "./shippingfaq";
import EditItem from "./edititem";
import ViewItemAuth from "./item.js";
import CurrentListings from "./currentlistings";
import Nav from "./nav";

const oauth = {
  domain: "auth.scrapstore.click",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin",
  ],
  responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  oauth.redirectSignIn = "https://192.168.0.100:3000/";
  oauth.redirectSignOut = "https://192.168.0.100:3000/";
} else {
  oauth.redirectSignIn = "https://scrapapp.co.za/";
  oauth.redirectSignOut = "https://scrapapp.co.za/";
}
Amplify.configure(awsconfig);
Auth.configure({ oauth });
// console.log(Amplify.configure());
// console.log(Auth.configure());


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: true,
    };
  }

  async componentDidMount() {}

  render() {
    if (!isMobile) {
      return (
        <Router>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >


              <Nav />


              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 1100,
                  justifyContent: "center",
                }}
              >
                <Route path="/" exact component={AuthSearch} />
                <Route path="/profile" component={Profile} />
                <Route path="/profileedit" component={ProfileEdit} />
                <Route path="/orderhistory" component={OrderHistory} />
                <Route path="/verifyseller" component={VerifySeller} />
                <Route path="/requestaccess" component={RequestAccess} />
                <Route path="/sellerlistings" component={SellerListings} />
                <Route path="/cart" component={Cart} />
                <Route path="/eft" component={EFT} />
                <Route path="/waybills" component={Waybills} />
                <Route path="/processeft" component={ProcessEFT} />
                <Route path="/termsandconditions" component={Privacy} />
                <Route path="/privacypolicy" component={Privacy2} />
                <Route path="/paiamanual" component={Privacy3} />
                <Route path="/conditionsofuse" component={Privacy4} />
                <Route path="/shippingfaq" component={ShippingFAQ} />
                <Route path="/additem" component={AddItem} />
                <Route path="/additembulk" component={AddItemBulk} />
                <Route path="/edititem" component={EditItem} />
                <Route path="/currentlistings" component={CurrentListings} />
                <Route path="/item" component={ViewItemAuth} />
                <Route path="/thanks" component={Thanks} />
              </div>
            </div>
          </div>
        </Router>
      );
    } else
      return (
        <Router>
          <div>
            <Nav />
            <Route path="/paiamanual" component={Privacy3} />
            <Route path="/sellerlistings" component={SellerListings} />
            <Route path="/conditionsofuse" component={Privacy4} />
            <Route path="/privacypolicy" component={Privacy2} />
            <Route path="/" exact component={AuthSearch} />
            <Route path="/waybills" component={Waybills} />
            <Route path="/eft" component={EFT} />
            <Route path="/processeft" component={ProcessEFT} />
            <Route path="/thanks" component={Thanks} />
            <Route path="/termsandconditions" component={Privacy} />
            <Route path="/profile" component={Profile} />
            <Route path="/profileedit" component={ProfileEdit} />
            <Route path="/cart" component={Cart} />
            <Route path="/orderhistory" component={OrderHistory} />
            <Route path="/shippingfaq" component={ShippingFAQ} />
            <Route path="/verifyseller" component={VerifySeller} />
            <Route path="/requestaccess" component={RequestAccess} />
            <Route path="/additem" component={AddItem} />
            <Route path="/edititem" component={EditItem} />
            <Route path="/currentlistings" component={CurrentListings} />
            <Route path="/item" component={ViewItemAuth} />
          </div>
        </Router>
      );
  }
}

export default App;
