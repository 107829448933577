import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
// import Button from "@material-ui/core/Button";

class Privacy extends Component {
  render() {
    return (
      <div style={{ color: "#666" }}>
        <h1>PRIVACY POLICY</h1>
        <b>
          BY ACCESSING AND USING THIS WEBSITE, SCANNING ANY QR CODE OR ENTERING
          ANY USSD OR OTHER NUMBER, AND/OR OUR SERVICES, THE USER IS PROVIDING
          HIS/HER EXPRESS AND VOLUNTARY CONSENT TO THE PROCESSING OF THEIR
          PERSONAL INFORMATION BY SENECA AUTO GROUP (PTY) LTD (“SAG”) ON THE
          BASIS SET OUT IN THIS PRIVACY NOTICE. IF THE USER DOES NOT CONSENT,
          THE USER MUST IMMEDIATELY STOP ACCESSING AND/OR USING THIS WEBSITE,
          OUR MOBILE APPLICATION AND OUR SERVICES.
        </b>

        <h2>1 Introduction</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG collects, uses and, in some circumstances, shares the
                personal information of Users in and through this website, its
                mobile application and during the provision of services to you.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG respects the rights of Users whose personal information is
                collected and used by it, including their right to protection
                against the unlawful collection, retention, sharing and use of
                such personal information.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The purpose of this Privacy Policy is to provide Users with
                information about the information processing activities of SAG
                and the manner in which their rights are protected.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                This Privacy Policy shall serve as a notification to Users about
                SAG’s processing activities which will remain valid for as long
                as one has an active account with or is using the services of
                SAG, including the browsing of this website. Users will not be
                notified separately on each occasion that SAG processes the same
                personal information in the same way over the period.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG may amend the provisions of this Privacy Policy to comply
                with any changes in the law, and/or pursuant to any changes to
                its information processing activities or privacy practices. Such
                amendments will be published on the website and will become
                operable from the date of such publication.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                This privacy policy, and the interpretation and enforcement
                thereof, will be governed by the laws of the Republic of South
                Africa.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The provisions of this Privacy Policy is, as far as possible, be
                incorporated into any agreement between SAG and Users.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>2 Definitions and interpretation</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                In this Privacy Policy, the following words bear the following
                meanings:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “consent” means any voluntary, specific and informed expression
                of will in terms of which permission is given by or on behalf of
                a User for the processing of their personal information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “direct marketing” means to approach a data subject, either in
                person or by mail or electronic communication, for the direct or
                indirect purpose of promoting or offering to supply any goods or
                services to the data subject;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “information officer” means the CEO of SAG from time to time;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “mobile application” means the mobile application Scrap Store;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “operator” means an outside third party who processes personal
                information for or on behalf of SAG in terms of a contract or
                mandate;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “PAIA” means the Promotion of Access to Information Act 2 of
                2000;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “personal information” means any information linked to a User or
                information that can identify a User, including but not limited
                to:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                information relating to a User’s gender, nationality, age,
                language;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                a User’s e-mail address, physical address, telephone number,
                location information or online identifier;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                a User’s financial information including banking details and
                invoice information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                a User’s preferences;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                correspondence sent by a User which is of a private or
                confidential nature; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.7.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the User’s name if it appears with other personal information
                relating to that User, or if the disclosure of their name on its
                own would reveal further personal information about that User;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “POPIA” means the Protection of Personal Information Act of
                2013, including any regulations or codes of conduct promulgated
                under it;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “process” or “processing” means, in relation to personal
                information, any operation or activity or any set of operations,
                whether or not by automatic means, including:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.9.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the collection, receipt, recording, organisation, collation,
                storage, updating or modification, retrieval, alteration,
                consultation or use of that information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.9.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                dissemination by means of transmission, distribution or making
                available in any other form; or
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.9.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                merging, linking, as well as restriction, degradation, erasure
                or destruction of that information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “services” means the services provided to any User by SAG;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.11
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “User” means a visitor or user of this website, our mobile
                application or any of the content or services associated with
                this website and our mobile application; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1.12
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “the website” means the website or any part thereof which is
                accessible from https://scrapapp.co.za/
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Any reference in this Privacy Policy to:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the singular includes the plural andthe other way around;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                any one gender includes the other genders, as the case may be;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                an act, regulation or other law is to the version of that law in
                force at the effective date of this Policy and includes any
                amendment or re-enactment made to that law after the effective
                date of this Policy.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                When calculating any number 1of days for the purposes of this
                Policy, the first day must be excluded and the last day of the
                relevant interval included, unless the last day is not a
                business day, then the last day will be the next succeeding
                business day.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The word "include" means "include without limitation". Use of
                the word ‘’include’’ or ‘’in particular’’ is for illustration or
                emphasis only and where followed by specific examples must not
                be interpreted as limiting the meaning of the general wording
                preceding it.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A requirement that any Policy, request, demand or other
                communication made in terms of this Privacy Policy must be in
                writing will be met if it is in the form of a data message as
                defined in the Electronic Communications and Transactions Act,
                No. 25 of 2002, and is accessible in a manner usable for
                subsequent reference, provided that this policy may not be
                amended as such.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>3 Responsible party</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG will be the party who will be collecting and processing a
                User’s personal information and as such is designated as the
                ‘’responsible party’’ for the purposes of this Policy.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG‘s contact details are as follows:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Email address: info@senecaautogroup.co.za
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Website address: https://scrapapp.co.za/
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG may instruct third party operators from time to time to
                undertake certain processing activities relating to the User’s
                personal information.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>4 What personal information is collected</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                {" "}
                SAG may collect the following personal information from the
                User:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Initials, first name, surname;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Date of birth;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Email address;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Age and language preference;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Gender;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Cellphone number;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Physical address;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Geo-location data;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Financial Information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Company information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.11
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Tax information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.12
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Employment details; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1.13
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Identity or passport number.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The supply of personal information by the User to SAG is
                voluntary and not mandatory. However, if the User refuses to
                supply any personal information, certain consequences may
                naturally flow from such a refusal, such as preventing SAG from
                concluding or performing any contract with the User, or
                preventing SAG from complying with one or more of its
                obligations in law.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                There are no laws which require or authorise SAG to collect a
                User’s personal information.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>
          5 purpose/s for collection and processing of personal information
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall only collect a User’s personal information for a
                specific, explicitly defined and lawful purpose relating to a
                function or activity of SAG‘s business.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Such purposes may include the following:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to enter into a contract with a User;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to perform any obligations under a contract with a User;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to comply with a legal obligation;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to protect a legitimate interest of a User (unless the User has
                specifically objected in writing to all or some of the
                processing activities on reasonable grounds);
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to pursue its own legitimate interests or the legitimate
                interests of a third party who it is sharing the information
                with (unless the User has specifically objected in writing to
                all or some of the processing activities on reasonable grounds);
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to process personal information for direct marketing purposes
                (only if the User has opted in to receiving any direct marketing
                material);
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to customise and display content to the User in a way that SAG
                feels may interest the User or be most beneficial to them;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to send content including, but not limited to products,
                articles, listings and advertisement content to the User via
                email or other electronic media, where the User has consented to
                be contacted by SAG with such content;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to enable the User to voluntarily participate in interactive
                features;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to notify the User about changes to the website or services.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If SAG intends to process a User’s personal information for any
                other purpose not listed in clause 5.2 or which is otherwise not
                automatically permitted by law, it shall ensure that it obtains
                the User’s written consent to do so.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG will not sell a User’s personal information to any third
                party without the prior written consent of the User.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>6 Collection directly from user</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall, as far as possible, collect personal information
                about a User directly from the User, except in the following
                circumstances:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Where personal information is available on a public record;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the User has given their written consent to SAG to collect
                their information from another source;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where it is more practicable to collect the information from
                another source;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the collection of a User’s personal information from
                another source will not prejudice any of the User’s legitimate
                interests;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the collection of personal information from another source
                is necessary to maintain SAG’s legitimate interests or those of
                any third party it intends sharing the information with;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the collection of personal information directly from the
                User would prejudice the purpose for the collection;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the collection of personal information directly from the
                User is not reasonably practicable in the circumstances.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If SAG collects personal information from a source other than
                the User, it shall record in writing the details of that source,
                including the full names and contact details of that source
                where applicable.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Personal information may be collected from or supplied by the
                User in any of the following ways:{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                during the process of downloading our mobile application or
                registering on our website;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.3.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                when subscribing to a service;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.3.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                when requesting further services or information from SAG;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.3.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                when contacting SAG to report a problem with the website or the
                services or for any other reason;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.3.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                when completing any forms on the website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User may visit the website without providing any personal
                information. However, the website’s servers may still collect
                technical information regarding the use of the website, which is
                aggregated for analytical purposes, technical maintenance and
                for improving the content offered on the website. Such
                information may include details of the User’s visit, information
                about the User’s computer, including IP (Internet Protocol)
                address, operating system and browser type, the User’s location,
                and usage information. An individual User will not be identified
                from or by this information and SAG is entitled to copy,
                distribute or otherwise use such information without limitation.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>7 Cookies</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “Cookies” are small text files transferred by a webserver to a
                User’s hard drive and thereafter stored on their computer. The
                types of information a Cookie collects includes a User’s
                username, the date and time of their visits to the website,
                their browsing history and preferences.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG may use Cookies on this website to:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                distinguish one User from another on the website;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                remember the User’s last session when they return to the
                website;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                estimate the website's audience size and usage patterns;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.2.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                store information about the User’s preferences, which allows SAG
                to customize the website and content according to the Users
                individual preferences; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.2.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                speed up searches on the website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The provisions of this clause are only applicable to Cookies
                used by SAG. In some instances, third-party service providers
                may use Cookies on the website. SAG cannot and does not control
                or access Cookies used by third party service providers and
                takes no responsibility therefore.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User has the right and ability to either accept or decline
                the use of Cookies on their computer’s web browser, whether they
                are logged in as a member, or simply casually visiting the
                website. However, declining the use of Cookies may limit a
                User’s access to certain features on the website. 
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>8 General conditions for processing personal information</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall comply with all laws, contracts or regulations when it
                processes a User’s personal information.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall not act unreasonably when processing a User’s personal
                information. This means that it will collect and process a
                User’s personal information in a way that the User can
                reasonably expect and in a way that is fair.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall respect the User’s right to privacy at all times. If
                there is another way in which it can achieve the same goal
                without posing any risk of harm to the privacy rights of the
                User, then it will choose that option.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Similarly, if SAG needs to process personal information but
                there are less privacy-invasive methods of collecting, using and
                sharing that information, then it will use those methods.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall ensure that the personal information that is collected
                and processed is and remains relevant to the identified
                purpose/s for such processing, and that such information is and
                remains adequate, but not excessive, for achieving the
                identified purpose/s.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If there are any alternative ways to achieve the identified
                purpose/s without processing personal information, SAG shall not
                process that personal information.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall ensure that the processing activities it chooses to
                apply are proportionate to achieving the identified purpose/s
                and that no less privacy invasive measures are available to
                achieve the same purpose/s.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall ensure that, regardless of the stated purpose/s for
                processing personal information, the rights and interests of
                Users will not be unnecessarily prejudiced or infringed, unless
                it cannot be avoided, and then in such cases, it shall ensure
                that its own rights and/or interests justify such prejudice or
                infringement taking place.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall be entitled to store the personal information of Users
                indefinitely unless the User objects thereto. In the event a
                User objects to the indefinite storage, once SAG has achieved
                the purpose for the collection of the User’s personal
                information, it will destroy or delete such information, unless
                the User has directed otherwise in writing, or SAG is required
                by law to retain the information for a longer period of time.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If SAG no longer needs to process personal information to
                achieve the purpose originally specified, it will stop using
                that information.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>9 Disclosue and sharing of personal information</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG may, in the course of providing any content or services on
                this website, or for the purposes of concluding or performing
                any other services or transaction with a User, share certain
                personal information with third party operators who perform
                certain processing activities on behalf of SAG.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The information shared and the categories of third party
                operators with whom it is shared will always be notified to you
                prior to being shared. Notwithstanding thw aforegoing, you
                consent to us sharing your personal information with our
                verified third party suppliers who may offers goods and services
                through our website ot mobile application.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG may also share aggregated information about Users of this
                website and their usage patterns. SAG may also use such
                aggregated information to help advertisers target specific
                audiences. Such aggregated information will be de-identified and
                the User’s personal information will not be disclosed.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Other than as stated in clause 9.1 and 9.3, SAG shall not share
                a User’s personal information with any third parties unless it
                has the User’s express consent to do so.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>
          10 User's rights in relation to the processing of their personal
          information
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Users shall have the following rights in relation to the
                processing of their personal information:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to access and correct any personal information held by SAG about
                them;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to object to the processing of their information; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                to lodge a complaint with the Information Regulator
                (https://justice.gov.za/inforeg/) .{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Users may make a request in terms of clause 10.1.1 by following
                the process for making such a request as set out in SAG’s PAIA
                manual.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>11 Further processing</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall not process a User’s personal information for any
                purpose not previously specified except in the following
                circumstances:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the User has consented to such further processing;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing is necessary for the exercise of
                any contractual rights or the fulfillment of any obligations
                between SAG and the User;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing activities are linked to or
                compatible with the original purpose;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing is necessary for the prevention,
                detection, investigation, prosecution and punishment of an
                offence;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing is necessary to enforce any law;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing is necessary for the conduct of
                legal proceedings in any court or tribunal that have commenced
                or are reasonably contemplated;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing is necessary to prevent or mitigate
                a serious and imminent threat to the life or health of the User
                or another individual;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                where the further processing is necessary for historical,
                statistical or research purposes.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall ensure that if it intends processing personal
                information for other purposes not previously specified, it
                shall notify the User of such further purposes and the possible
                consequences of the intended further processing for the User.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>
          12 Accuracy, correctness and completeness of personal information
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall take reasonably practicable steps to ensure that the
                personal information kept by it about Users is complete,
                accurate, not misleading and is updated when necessary.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                However, if a User is aware of any personal information in SAG’s
                custody that is incorrect, inaccurate or which needs to be
                updated, the User must make a written request to SAG’s
                information officer at to update or correct the relevant
                information.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If a User has contested the accuracy of any personal information
                being used by SAG, it shall immediately stop using that
                information until its accuracy has been verified, if it is
                reasonably practicable to do so.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG reserves its right to only adhere to a request from a User
                in terms of clause 12.2 if the correction or updating of that
                information will result in the personal information being
                correct and accurate.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>13 Security safeguards</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG is committed to protecting the personal information in its
                custody against any loss of, damage to or unauthorised
                destruction of that information, and to prevent any unauthorised
                parties from accessing that information.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG takes steps to continually identify and document any risks
                to the personal information it has in its possession or under
                its control and that appropriate security safeguards are in
                place against those risks.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall ensure that in any contracts entered into with third
                party operators who process personal information on SAG’s
                behalf, include the following obligations:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the operator shall not process any personal information without
                SAG’s knowledge and authority;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the operator shall treat all personal information given to it as
                confidential and shall not disclose it to any unauthorised third
                parties;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the operator shall establish and maintain adequate security
                measures which are the same or offer similar protection over the
                personal information as that employed by SAG;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the operator shall notify SAG immediately where there are
                reasonable grounds to believe that any personal information has
                been leaked to or accessed by any unauthorised person;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                if the operator is situated in another country, it must comply
                with the data protection laws in that country and be able to
                provide verification that it is so compliant;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.3.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                if an operator is legally obliged to disclose any personal
                information processed by them on SAG’ behalf to other parties,
                it must notify SAG beforehand to enable SAG and/or individual
                Users to protect their rights if necessary.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall ensure that all personal information on its systems is
                properly backed-up and that back-up copies are stored separately
                from the live files.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>14 Notification of breach of security</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If personal information about a User is inadvertently leaked or
                SAG’s security has been unlawfully breached by any unauthorised
                party, SAG shall immediately identify the relevant Users who may
                be affected by the security breach, and shall contact them at
                their last known email address or contact details or by the
                quickest means possible.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG shall provide sufficient information to the User to allow
                him or her to take the necessary protective measures against the
                potential consequences of the compromise, or shall advise Users
                of the steps to be taken by them and the possible consequences
                that may ensue from the breach for them.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>15 Children’s personal information</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                {" "}
                SAG shall not process any personal information relating to a
                person under the age of 18 years unless it has obtained consent
                from that person’s parent or legal guardian. If this website is
                being accessed by the parent or guardian of a child under the
                age of 18 years, and personal information pertaining to that
                child is being provided by the parent or guardian, then they
                hereby expressly consent to SAG processing such information
                according to the further provisions of this Privacy Policy.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>16 Cross border Transfers of personal information</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User consents that personal information (including special
                personal information) may be transferred transborder, even to
                countries without data protection laws similar to those of South
                Africa, for storage purposes, or if it is in SAG’s legitimate
                interests or those of a third party to do so.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>17 Retention of information</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User consents that SAG may keep record of his/her personal
                information for an indefinite period of time, unless User
                objects. In such case SAG will only keep such records if it is
                lawfully entitles or obliged to do so
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>18 Returning, destroying or deleting personal information</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                18.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Where SAG is no longer authorised to retain a record of any
                personal information, it shall either:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                18.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                ensure that the information is permanently destroyed or deleted
                as soon as reasonably practicable; or{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                18.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                return the information to the User or transfer it to a third
                party, if requested by the User in writing to do so.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>19 Consent</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User hereby consents to the processing of their personal
                information in terms of the provisions of this Privacy Policy.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User acknowledges and agrees that such consent has been
                given voluntarily after the User has read and understood the
                provisions of this Privacy Policy, in particular, regarding the
                following:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the types of personal information to be processed, including
                specifically special personal information;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the specific processing activities to be undertaken;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the specific purpose/s for such processing; and{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.2.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                the possible consequences for the User that may arise from such
                processing.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                19.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Should a User wish to withdraw any consent previously given by
                the User, they must notify SAG’s information officer in writing.{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>20 Lodging an objection</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                20.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A User may, on reasonable grounds, object to the processing of
                their personal information at any time after that processing has
                started.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                20.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If a User wishes to object to the processing of their personal
                information, they should contact SAG’s information officer in
                writing and provide, reasons for doing so. Users may use the
                process and forms contained within SAG’s PAIA manual available
                on our website.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>21 Choice of Law</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                This Privacy Policy shall be governed and interpreted in
                accordance with the laws of the Republic of South Africa.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>22 Amendment of this privacy policy</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                22.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                SAG reserves the right to change, update, add, remove and/or
                amend any of the provisions of this Privacy Policy from time to
                time. Such changes, updates, additions, removals or amendments
                will become effective from the date of their publication on this
                website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                22.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                It is the User's obligation to periodically check the provisions
                of this Privacy Policy for any such changes, updates, additions,
                removals or amendments.{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                22.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The User's continued use of this website following any changes,
                updates, additions, removals or amendments to this Privacy
                Policy will be considered Policy of the User's acceptance to
                abide by and be bound by this Privacy Policy, as amended.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>Contact</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                For more information on your rights to privacy over your
                information, or the information processing activities of SAG,
                please do not hesitate to contact us directly
                info@senecaautogroup.co.za
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <b>Date of last update: 22 June 2020</b>
      </div>
    );
  }
}
export default withRouter(Privacy);
