import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import CardGridTemplate from "./cardgridtemplate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Snackbar from "@material-ui/core/Snackbar";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        done: false,
        listings: { items: [] },
        transactions: { items: [{ listingdetail: [] }] },
      },
      defaultpic: "",
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    this.setState({ defaultpic: await Utils.getLatestPic("defaultpic.png") });
    const currentID = await Utils.checkCurrentUser();
    let id = currentID.message.id;
    if (typeof currentID.message.id === "undefined")
      id = currentID.message.username;
    var user = await Utils.getUserById(id);
    user = JSON.parse(JSON.stringify(user));
    // console.log(user)
    user.user.transactions.items.forEach(
      (z) => (z.transactiondate = new Date(z.transactiondate))
    );
    user.user.transactions.items = user.user.transactions.items.filter(
      (z) => z.status !== "cart"
    );
    user.user.transactions.items.forEach(
      (a) => (a.listings = JSON.parse(a.listings))
    );
    var ids = [];
    user.user.transactions.items.forEach((a) => {
      ids = ids.concat(a.listings);
    });

    var listings = await Promise.all(ids.map(Utils.getListingById));
    listings = [...new Set(listings)];
    var imagekeys = [];
    listings.forEach((a) => {
      if (a.listing.user.profilepic !== null) {
        a.listing.user.profilepic = JSON.parse(a.listing.user.profilepic);
        imagekeys.push(a.listing.user.profilepic.key);
      }
      if (a.listing.photos.items.length > 0)
        a.listing.photos.items.forEach((b) => {
          imagekeys.push(b.key);
        });
    });

    let uris = await Promise.all(imagekeys.map(Utils.getLatestPic));
    listings.forEach((a) => {
      if (a.listing.photos.items.length > 0)
        a.listing.photos.items.forEach((b) => {
          uris.forEach((c) => {
            if (b.key === c.key) b.uri = c.uri;
          });
        });
    });
    var defaultpic = await Utils.getLatestPic("defaultpic.png");
    listings.forEach((a) => {
      if (a.listing.user.profilepic !== null) {
        uris.forEach((r) => {
          if (a.listing.user.profilepic.key === r.key)
            a.listing.user.profilepic = r;
        });
      } else {
        a.listing.user.profilepic = defaultpic;
      }
    });

    user.user.transactions.items.forEach((z) => {
      z.listingdetail = [];

      z.listings.forEach((y) => {
        listings.forEach((x) => {
          if (y === x.listing.id) {
            if (x.listing.photos.items.length === 0) {
              x.listing.photos.items = [this.state.defaultpic];
            }
            z.listingdetail.push(x.listing);
          }
        });
      });
      delete z.listings;
      z.listings = z.listingdetail;
      delete z.listingdetail;
    });
    user.user.profilepic = JSON.parse(user.user.profilepic);
    // var defaultpic = await Utils.getLatestPic("defaultpic.png");
    if (user.user.profilepic === null) user.user.profilepic = defaultpic;
    else
      user.user.profilepic = await Utils.getLatestPic(user.user.profilepic.key);

    this.setState({ userData: user.user, done: true });
  }

  render() {
    if (!this.state.done) return <div></div>;
    else
      return (
        <div style={{ marginTop: 20 }}>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.statusbar}
            autoHideDuration={3000}
            onClose={() => this.setState({ statusbar: false })}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.statusbartext}</span>}
          />
          {this.state.userData.transactions.items.map((e, i) => (
            <Card key={i} style={{ marginBottom: 10 }}>
              <CardContent style={{ marginBottom: 10 }}>
                <div>
                  <div>
                    <div style={{ color: "#444" }}>
                      {"Date: " + new Date(e.transactiondate).toDateString()}
                    </div>
                    <div style={{ color: "#444" }}>
                      {"Total: " + Utils.formatTextToCurrency(e.total)}
                    </div>
                    <div style={{ color: "#444" }}>
                      {"Status: " + e.status}
                      {e.status==="processing"?" (ref:"+e.transactioncode+")":""}
                    </div>
                  </div>
                </div>
                <CardGridTemplate
                  items={e.listings}
                  onPress={(id) => this.props.history.push("/item/" + id)}
                  showsoldby={true}
                />
              </CardContent>
            </Card>
          ))}
        </div>
      );
  }
}

export default withRouter(Profile);
