/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOttoPartzUser = /* GraphQL */ `
  mutation CreateOttoPartzUser($input: CreateOttoPartzUserInput!) {
    createOttoPartzUser(input: $input) {
      id
      user
      businessname
      contact
      buildingaddress
      streetaddress
      suburb
      city
      province
      areacode
      profilepic
      contactphone
      contactphonealternate
      verified
      disabled
      admin
      vatnumber
      contactemail
      federatedid
      listings {
        items {
          id
          title
          details
          condition
          price
          available
          listdate
          ref
          tags
          hiddentags
          views
          createdAt
          updatedAt
        }
        nextToken
      }
      transactions {
        items {
          id
          status
          total
          waybilldata
          invoiceNumber
          transactiondate
          transactioncode
          transactionstatus
          listings
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOttoPartzUser = /* GraphQL */ `
  mutation UpdateOttoPartzUser($input: UpdateOttoPartzUserInput!) {
    updateOttoPartzUser(input: $input) {
      id
      user
      businessname
      contact
      buildingaddress
      streetaddress
      suburb
      city
      province
      areacode
      profilepic
      contactphone
      contactphonealternate
      verified
      disabled
      admin
      vatnumber
      contactemail
      federatedid
      listings {
        items {
          id
          title
          details
          condition
          price
          available
          listdate
          ref
          tags
          hiddentags
          views
          createdAt
          updatedAt
        }
        nextToken
      }
      transactions {
        items {
          id
          status
          total
          waybilldata
          invoiceNumber
          transactiondate
          transactioncode
          transactionstatus
          listings
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOttoPartzUser = /* GraphQL */ `
  mutation DeleteOttoPartzUser($input: DeleteOttoPartzUserInput!) {
    deleteOttoPartzUser(input: $input) {
      id
      user
      businessname
      contact
      buildingaddress
      streetaddress
      suburb
      city
      province
      areacode
      profilepic
      contactphone
      contactphonealternate
      verified
      disabled
      admin
      vatnumber
      contactemail
      federatedid
      listings {
        items {
          id
          title
          details
          condition
          price
          available
          listdate
          ref
          tags
          hiddentags
          views
          createdAt
          updatedAt
        }
        nextToken
      }
      transactions {
        items {
          id
          status
          total
          waybilldata
          invoiceNumber
          transactiondate
          transactioncode
          transactionstatus
          listings
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOttoPartzListing = /* GraphQL */ `
  mutation CreateOttoPartzListing($input: CreateOttoPartzListingInput!) {
    createOttoPartzListing(input: $input) {
      id
      title
      details
      condition
      price
      available
      listdate
      ref
      tags
      hiddentags
      views
      photos {
        items {
          id
          key
          type
          height
          width
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOttoPartzListing = /* GraphQL */ `
  mutation UpdateOttoPartzListing($input: UpdateOttoPartzListingInput!) {
    updateOttoPartzListing(input: $input) {
      id
      title
      details
      condition
      price
      available
      listdate
      ref
      tags
      hiddentags
      views
      photos {
        items {
          id
          key
          type
          height
          width
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOttoPartzListing = /* GraphQL */ `
  mutation DeleteOttoPartzListing($input: DeleteOttoPartzListingInput!) {
    deleteOttoPartzListing(input: $input) {
      id
      title
      details
      condition
      price
      available
      listdate
      ref
      tags
      hiddentags
      views
      photos {
        items {
          id
          key
          type
          height
          width
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOttoPartzPhotos = /* GraphQL */ `
  mutation CreateOttoPartzPhotos($input: CreateOttoPartzPhotosInput!) {
    createOttoPartzPhotos(input: $input) {
      id
      key
      type
      height
      width
      listing {
        id
        title
        details
        condition
        price
        available
        listdate
        ref
        tags
        hiddentags
        views
        photos {
          nextToken
        }
        user {
          id
          user
          businessname
          contact
          buildingaddress
          streetaddress
          suburb
          city
          province
          areacode
          profilepic
          contactphone
          contactphonealternate
          verified
          disabled
          admin
          vatnumber
          contactemail
          federatedid
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOttoPartzPhotos = /* GraphQL */ `
  mutation UpdateOttoPartzPhotos($input: UpdateOttoPartzPhotosInput!) {
    updateOttoPartzPhotos(input: $input) {
      id
      key
      type
      height
      width
      listing {
        id
        title
        details
        condition
        price
        available
        listdate
        ref
        tags
        hiddentags
        views
        photos {
          nextToken
        }
        user {
          id
          user
          businessname
          contact
          buildingaddress
          streetaddress
          suburb
          city
          province
          areacode
          profilepic
          contactphone
          contactphonealternate
          verified
          disabled
          admin
          vatnumber
          contactemail
          federatedid
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOttoPartzPhotos = /* GraphQL */ `
  mutation DeleteOttoPartzPhotos($input: DeleteOttoPartzPhotosInput!) {
    deleteOttoPartzPhotos(input: $input) {
      id
      key
      type
      height
      width
      listing {
        id
        title
        details
        condition
        price
        available
        listdate
        ref
        tags
        hiddentags
        views
        photos {
          nextToken
        }
        user {
          id
          user
          businessname
          contact
          buildingaddress
          streetaddress
          suburb
          city
          province
          areacode
          profilepic
          contactphone
          contactphonealternate
          verified
          disabled
          admin
          vatnumber
          contactemail
          federatedid
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOttoPartzInvoiceCounter = /* GraphQL */ `
  mutation CreateOttoPartzInvoiceCounter(
    $input: CreateOttoPartzInvoiceCounterInput!
  ) {
    createOttoPartzInvoiceCounter(input: $input) {
      id
      number
      createdAt
      updatedAt
    }
  }
`;
export const updateOttoPartzInvoiceCounter = /* GraphQL */ `
  mutation UpdateOttoPartzInvoiceCounter(
    $input: UpdateOttoPartzInvoiceCounterInput!
  ) {
    updateOttoPartzInvoiceCounter(input: $input) {
      id
      number
      createdAt
      updatedAt
    }
  }
`;
export const deleteOttoPartzInvoiceCounter = /* GraphQL */ `
  mutation DeleteOttoPartzInvoiceCounter(
    $input: DeleteOttoPartzInvoiceCounterInput!
  ) {
    deleteOttoPartzInvoiceCounter(input: $input) {
      id
      number
      createdAt
      updatedAt
    }
  }
`;
export const createOttoPartzTransactions = /* GraphQL */ `
  mutation CreateOttoPartzTransactions(
    $input: CreateOttoPartzTransactionsInput!
  ) {
    createOttoPartzTransactions(input: $input) {
      id
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      total
      waybilldata
      invoiceNumber
      transactiondate
      transactioncode
      transactionstatus
      listings
      createdAt
      updatedAt
    }
  }
`;
export const updateOttoPartzTransactions = /* GraphQL */ `
  mutation UpdateOttoPartzTransactions(
    $input: UpdateOttoPartzTransactionsInput!
  ) {
    updateOttoPartzTransactions(input: $input) {
      id
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      total
      waybilldata
      invoiceNumber
      transactiondate
      transactioncode
      transactionstatus
      listings
      createdAt
      updatedAt
    }
  }
`;
export const deleteOttoPartzTransactions = /* GraphQL */ `
  mutation DeleteOttoPartzTransactions(
    $input: DeleteOttoPartzTransactionsInput!
  ) {
    deleteOttoPartzTransactions(input: $input) {
      id
      user {
        id
        user
        businessname
        contact
        buildingaddress
        streetaddress
        suburb
        city
        province
        areacode
        profilepic
        contactphone
        contactphonealternate
        verified
        disabled
        admin
        vatnumber
        contactemail
        federatedid
        listings {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      total
      waybilldata
      invoiceNumber
      transactiondate
      transactioncode
      transactionstatus
      listings
      createdAt
      updatedAt
    }
  }
`;
