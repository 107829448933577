import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
// import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import ImageUploader from "react-images-upload";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";
import TagButton from "./tagbutton";
import TagButtonMulti from "./tagbuttonmulti";
class EditItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      detail: "",
      listing: { ctag: "",tags:[] ,photos:{items:[]}},
      condition: "",
      price: "",
      deliverycondition: "",
      tags: "",
      ctag: "",
      visibleTags: false,
      taglist: null,
      filesToUpload: [{}],
      pictures: [],
      galleryImages: [],
      slotsavailable: true,
      makevisible: false,
      makeSelected: "",
      modelSelected: "",
      modelvisible: false,

      yearsvisible: false,
      parts1visible: false,
      parts2visible: false,
      parts3visible: false,
      parts4visible: false,
      parts5visible: false,
      parts1choice: "",
      parts2choice: "",
      parts3choice: "",
      parts4choice: "",
      parts5choice: "",
      yearsTag: [],
      yearsAvailable: [],
      bodyTags: [],
      pics:[]
    };
  }

  async getItem(id) {
    // console.log(id)
    let item = await Utils.getListingById(id);
    for (var i = 0; i < item.listing.photos.items.length; i++) {
      item.listing.photos.items[i].uri = (await Utils.getLatestPic(
        item.listing.photos.items[i].key
      )).uri;
    }
    let matchuser = await Utils.getThisUserId();
    if (item.listing.user.id !== matchuser) this.props.history.push("/");
    item.listing.hiddentags = JSON.parse(item.listing.hiddentags)

    this.setState({
      listing: item.listing,
        pics: item.listing.photos.items,
    });
  }

  async componentDidMount() {
    const yearsAvailable = [];
    for (let i = 2000; i < 2023; i++) {
      yearsAvailable.push({ year: `${i}`, selected: false });
    }
    this.setState({ yearsAvailable });
    let userid = await Utils.getThisUserId();
    let userprofile = await Utils.getUserProfileById(userid);
    if (userprofile.user.verified !== true) {
      this.doSnackBar("You need to apply for access to list an item");
      this.props.history.push("/Profile");
    }
    this.getItem(this.props.location.pathname.replace("/edititem/", ""));
  }
  addBodyTag(input) {
    let oldbodyTags = this.state.bodyTags;
    oldbodyTags.push(input);
    oldbodyTags = [...new Set(oldbodyTags)];
    this.setState({ bodyTags: oldbodyTags });
  }

async deletephoto(id){
  await Utils.deleteOttoPartzPhotos(id)
  this.componentDidMount()
}


  showPhotos(input) {
    return input.photos.items.map((item, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <img alt="" src={item.uri} height="300" />
        <Button
          style={{
            margin: 5,
            marginTop: 15,
            alignSelf: "center",
            backgroundColor: "#00a0e2",
            color: "#fff"
          }}
          variant="contained"
          onClick={() => {
          this.deletephoto(item.id)
          }}
        >
          Delete
        </Button>
      </div>
    ));
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async updateOttoPartzListing(input) {
    const newinput = input;
    newinput.tags = input.tags;
    if (this.state.bodyTags.length > 0) newinput.tags = this.state.bodyTags;
    if (this.state.yearsTag.length > 0)
      newinput.tags = input.tags.concat(this.state.yearsTag);
    if (this.state.listing.ctag !== "")
      newinput.tags = input.tags.concat(this.state.listing.ctag);
    if (this.state.makeSelected !== "")
      newinput.tags = input.tags.concat(this.state.makeSelected);
    if (this.state.modelSelected !== "") {
      newinput.tags = input.tags.concat(this.state.modelSelected);
    }
    newinput.tags = input.tags.concat(
      this.state.yearsAvailable.filter(a => a.selected).map(z => z.year)
    );
    newinput.hiddentags = input.tags.filter(a => typeof a!=="undefined").map(z => z.toLowerCase());
    newinput.hiddentags = newinput.hiddentags.concat(this.state.listing.title);
    newinput.hiddentags = newinput.hiddentags.concat(
      this.state.listing.condition
    );
    newinput.hiddentags = newinput.hiddentags.concat(
      this.state.listing.details
    );
    newinput.tags = newinput.tags.filter(item => item!==null)
    newinput.tags = newinput.tags.filter(item => typeof item!=="undefined")
    newinput.hiddentags = JSON.stringify(newinput.hiddentags);
    newinput.tags = JSON.stringify(newinput.tags);
    const save = await Utils.updateOttoPartzListing(newinput);

    async function saveapic(input) {
      var b64 = input.b64.split(",")[1]
      var fname = input.b64.split(",")[0]
      fname = fname.split(";")[1]
      fname = fname.split(".")[1]
      // fname = Utils.generateName()+"."+fname
      var ctype = input.b64.split(",")[0]
       ctype = ctype.split(";")[0]
       ctype = ctype.split(":")[1]

      const pi = await Utils.uploadtoS3(  b64,fname,ctype );
      let filesave = {
        key: pi.key,
        id: input.id,
        type: "gallery",
        height: 500,
        width: 500
      };
      let t = await Utils.createOttoPartzPhotos(filesave);
      return t;
    }

    var picstosave = []

    this.state.pictures.forEach(z => picstosave.push({id:save.listing.id, b64:z}));
    if (picstosave.length > 0) {
       await Promise.all(picstosave.map(saveapic));
    }


    // Utils.log(result);
    this.props.history.push("/currentlistings");
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", color: "#666" }}>
        <Dialog
          open={this.state.makevisible}
          onClose={() => this.setState({ makevisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={Utils.pureCarMakesList()}
                onDone={() =>
                  this.setState({
                    makevisible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    makevisible: false
                  })
                }
                onSelected={make => {
                  this.setState({
                    makevisible: false,
                    modelvisible: true,
                    makeSelected: make,
                    modelsAvailable: Utils.formattedCarsList().filter(
                      z => z.make === make
                    )[0].model
                  });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.modelvisible}
          onClose={() => this.setState({ modelvisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={this.state.modelsAvailable}
                onDone={() =>
                  this.setState({
                    makevisible: true,
                    modelvisible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    modelvisible: false
                  })
                }
                onSelected={model => {
                  this.setState({
                    makevisible: false,
                    modelvisible: false,
                    modelSelected: model
                  });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.parts1visible}
          onClose={() => this.setState({ parts1visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts1visible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    parts1visible: false
                  })
                }
                items={[...new Set(Utils.partslist().map(z => z.Cat1))]}
                onSelected={input => {
                  this.setState({
                    parts1visible: false,
                    parts2visible: true,
                    parts1choice: input
                  });
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts2visible}
          onClose={() => this.setState({ parts2visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={Utils.getSubcatOfParts(this.state.parts1choice)}
                onDone={() =>
                  this.setState({
                    parts1visible: true,
                    parts2visible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    parts2visible: false
                  })
                }
                onSelected={input => {
                  this.setState({
                    parts2visible: false,
                    parts3visible: true,
                    parts2choice: input
                  });
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts3visible}
          onClose={() => this.setState({ parts3visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts3visible: false,
                    parts2visible: true
                  })
                }
                onAll={() =>
                  this.setState({
                    parts3visible: false
                  })
                }
                items={Utils.getComponentOfSubCatParts(
                  this.state.parts1choice,
                  this.state.parts2choice
                )}
                onSelected={input => {
                  if (
                    (Utils.getSubComponentOfSubCatParts(
                      this.state.parts1choice,
                      this.state.parts2choice,
                      input
                    ).length === 1 &&
                      Utils.getSubComponentOfSubCatParts(
                        this.state.parts1choice,
                        this.state.parts2choice,
                        input
                      )[0] === input) ||
                    (Utils.getSubComponentOfSubCatParts(
                      this.state.parts1choice,
                      this.state.parts2choice,
                      input
                    ).length === 1 &&
                      Utils.getSubComponentOfSubCatParts(
                        this.state.parts1choice,
                        this.state.parts2choice,
                        input
                      )[0] === "")
                  ) {
                    this.setState({
                      parts3visible: false,
                      parts4visible: false,
                      parts3choice: input
                    });
                  } else {
                    this.setState({
                      parts3visible: false,
                      parts4visible: true,
                      parts3choice: input
                    });
                  }
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts4visible}
          onClose={() => this.setState({ parts4visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts3visible: true,
                    parts4visible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    parts4visible: false
                  })
                }
                items={Utils.getSubComponentOfSubCatParts(
                  this.state.parts1choice,
                  this.state.parts2choice,
                  this.state.parts3choice
                )}
                onSelected={input => {
                  this.addBodyTag(input);
                  this.setState({ parts4visible: false, parts4choice: input });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.yearsvisible}
          onClose={() => this.setState({ yearsvisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButtonMulti
                items={this.state.yearsAvailable}
                onDone={() => this.setState({ yearsvisible: false })}
                onSelected={input => {
                  const yearsTagOld = this.state.yearsAvailable;
                  yearsTagOld.forEach(z => {
                    if (z.year === input) z.selected = !z.selected;
                  });
                  this.setState({ yearsAvailable: yearsTagOld });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <div style={{ width: 1000 }} />
        <h2>Edit listed item</h2>
        <TextField
          style={{ color: "#374799" }}
          id="outlined-name"
          label="Name of item"
          placeholder="eg: Used C200 Bumper"
          value={this.state.listing["title"] || ""}
          onChange={change => {
            const listing = this.state.listing;
            listing["title"] = change.target.value;
            this.setState({ listing });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799" }}
          id="outlined-name"
          label="Detailed description"
          placeholder="eg: 2016 front blue"
          value={this.state.listing["details"] || ""}
          onChange={change => {
            const listing = this.state.listing;
            listing["details"] = change.target.value;
            this.setState({ listing });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799" }}
          id="outlined-name"
          label="Condition"
          placeholder="eg: Cosmetic damage"
          value={this.state.listing["condition"] || ""}
          onChange={change => {
            const listing = this.state.listing;
            listing["condition"] = change.target.value;
            this.setState({ listing });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799" }}
          id="outlined-name"
          label="Price"
          placeholder="eg: R1000"
          value={this.state.listing["price"] || ""}
          onChange={change => {
            const listing = this.state.listing;
            listing["price"] = change.target.value;
            this.setState({ listing });
          }}
          margin="normal"
          variant="outlined"
        />
        <div style={{ marginTop: 10, marginBottom: 20, color: "#666" }}>
          The Scrap App fee is 20% of the listing price and you will receive
          remaining 80%. Scrap App will take care of picking the item up from
          you and shipping to the customer. Your payment will be settled once
          the customer is satisfied with the product so be accurate in
          describing the condition of the item. Minimum listing price is R500.
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {this.state.makeSelected !== "" ? (
            <Chip
              onDelete={() => this.setState({ makeSelected: "" })}
              label={this.state.makeSelected}
            ></Chip>
          ) : (
            <div />
          )}
          {this.state.modelSelected !== "" ? (
            <Chip
              onDelete={() => this.setState({ modelSelected: "" })}
              label={this.state.modelSelected}
            ></Chip>
          ) : (
            <div />
          )}
          {this.state.yearsAvailable
            .filter(z => z.selected)
            .map((e, i) => (
              <Chip
                key={i}
                onDelete={yr => {
                  const yearsTagOld = this.state.yearsAvailable;
                  yearsTagOld.forEach(z => {
                    if (z.year === e.year) z.selected = !z.selected;
                  });
                  this.setState({ yearsAvailable: yearsTagOld });
                }}
                label={e.year}
              ></Chip>
            ))}
          {this.state.bodyTags.map((e, i) => (
            <Chip
              key={i}
              onDelete={() => {
                const yearsTagOld = this.state.bodyTags;
                yearsTagOld.splice(i, 1);

                this.setState({ bodyTags: yearsTagOld });
              }}
              label={e}
            ></Chip>
          ))}
          {this.state.listing.tags

            .map((e, i) => (
              <Chip
                key={i}
                onDelete={yr => {
                  const yearsTagOld = this.state.listing;
                  yearsTagOld.tags.splice(i,1)
                  console.log(this.state.listing)
                  this.setState({ listing: yearsTagOld });
                }}
                label={e}
              ></Chip>
            ))}
        </div>
        <div
          style={{
            marginTop: 10
          }}
        >
          <Button
            value="bold"
            variant="contained"
            style={{
              backgroundColor: "#00a0e2",
              color: "#fff"
            }}
            onClick={() => {
              this.setState({ makevisible: true });
            }}
          >
            Filter car make / model
          </Button>
          <Button
            value="bold"
            variant="contained"
            style={{
              backgroundColor: "#00a0e2",
              color: "#fff"
            }}
            onClick={() => {
              this.setState({ yearsvisible: true });
            }}
          >
            Filter model year
          </Button>
          <Button
            value="bold"
            variant="contained"
            style={{
              backgroundColor: "#00a0e2",
              color: "#fff"
            }}
            onClick={() => {
              this.setState({ parts1visible: true });
            }}
          >
            Filter car part description
          </Button>
        </div>
        <TextField
          style={{ color: "#374799" }}
          id="outlined-name"
          label="Custom tag (comma seperated)"
          placeholder="body work, paint remover"
          value={this.state.listing.ctag}
          onChange={change => {
            if (change.target.value.indexOf(",") > -1) {
              const listing = this.state.listing;
              listing["ctag"] = change.target.value.split(",")[1];
              this.setState({
                bodyTags: this.state.bodyTags.concat(
                  change.target.value.split(",")[0]
                ),
                listing
              });
            } else {
              const listing = this.state.listing;
              listing["ctag"] = change.target.value;
              this.setState({ listing });
            }
          }}
          margin="normal"
          variant="outlined"
        />
        <div>
          <ImageUploader
            withIcon={true}
            buttonText="Choose images"
            onChange={(pictureFiles, pictureDataURLs) => {
              // Utils.log(pictureFiles)
              // Utils.log(pictureDataURLs)
              this.setState({ pictures: pictureDataURLs });
            }}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />
        </div>
        <h3>Gallery pictures</h3>
          { this.showPhotos(this.state.listing)}
        {  this.state.pictures.map((item, index) =>
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <img alt="" src={item} height="300" />
              <Button
                style={{
                  margin: 5,
                  marginTop: 15,
                  alignSelf: "center",
                  backgroundColor: "#00a0e2",
                  color: "#fff"
                }}
                variant="contained"
                onClick={() => {
                  var p = this.state.pictures
                  p.splice(index,1)
                this.setState({pictures:p})
                }}
              >
                Delete
              </Button>
            </div>)}
        <Button
          style={{

            marginTop: 15,
            marginBottom: 15,
            backgroundColor: "#00a0e2",
            color: "#fff"
          }}
          variant="contained"
          onClick={() => {
            let valid = true;
            if (
              typeof this.state.listing.title === "undefined" ||
              this.state.listing.title === "" ||
              this.state.listing.title === null
            ) {
              valid = false;
              this.doSnackBar("Name of item cannot be blank ");
            }
            if (
              typeof this.state.listing.details === "undefined" ||
              this.state.listing.details === "" ||
              this.state.listing.details === null
            ) {
              valid = false;
              this.doSnackBar("Details cannot be blank ");
            }
            if (
              typeof this.state.listing.condition === "undefined" ||
              this.state.listing.condition === "" ||
              this.state.listing.condition === null
            ) {
              valid = false;
              this.doSnackBar("Condition cannot be blank ");
            }
            if (
              typeof this.state.listing.price === "undefined" ||
              this.state.listing.price === "" ||
              this.state.listing.price === null
            ) {
              valid = false;
              this.doSnackBar("Price cannot be less than R500 ");
            }
            if (parseFloat(this.state.listing.price, 2) < 500) {
              valid = false;
              this.doSnackBar("Price cannot be less than R500 ");
            }

            if (valid) this.updateOttoPartzListing(this.state.listing);
          }}
        >
          Save
        </Button>

      </div>
    );
  }
}

export default withRouter(EditItem);
