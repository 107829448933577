import React from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CheckIcon from "@material-ui/icons/Check";

export default class TagButtonMulti extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
          }}
        >
          <Button
          style={{
            backgroundColor: "#00a0e2",
            color: "#fff"
          }}
          variant="contained"
            onClick={() => this.props.onDone()}
          >
            <div>Close </div>
          </Button>
        </div>
        <List>
          {this.props.items.map((e, i) => (
            <ListItem key={i} style={{margin:0, padding:0}}>
              <Button
                style={{ width: 500 }}
                onClick={() => this.props.onSelected(e.year)}
              >
                <div style={{ fontSize: 18 , display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                  <div>{e.year}</div>
                  {e.selected ? <CheckIcon /> : null}{" "}
                </div>
              </Button>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}
