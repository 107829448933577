import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
// import Button from "@material-ui/core/Button";

class Privacy extends Component {
  render() {
    return (
      <div style={{ color: "#666" }}>
        <h1>Promotion of Access to Information</h1>
        <p>
          This manual applies to Seneca Auto Group (Pty) Ltd: registration
          number: 2018/453019/07
          <br />
          (the “Company”)
          <br />
          Registered office address:
          <br />
          13 Mill Street, Hamilton, Bloemfontein, South Africa
        </p>
        <h2>1 Introduction</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Promotion of Access to Information Act, 2000 (the “Act”)
                gives third parties the right to approach private bodies and the
                government to request information held by them, which is
                required in the exercise and/or protection of any rights. On
                request, the private body or government is obliged to release
                such information unless the Act expressly states that the
                records containing such information may or must not be released.
                This manual informs requestors of procedural and other
                requirements which a request must meet as prescribed by the Act.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>2 Nature of Business:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company is a scrap or used parts dealer.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>3 Contact Details:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Name of Company: Seneca Auto Group (Pty) Ltd
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Head of Company: Damian Esterhuizen
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Information Officer: Jenny Janse van Rensburg
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Physical Address: 13 Mill Street, Hamilton, Bloemfontein, South
                Africa.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Postal Address: P O Box 35099, Faunasig, Bloemfontein, South
                Africa.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Telephone Number: +27 51 435 5375
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Email address: info@senecaautogroup.co.za
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>4 Guide to Human Rights Commission / Information Regulator:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A guide to the Act (as contemplated under section 10 of the Act)
                is available from the South African Human Rights Commission. The
                guide contains such information as may reasonably be required by
                a person who wishes to exercise any right contemplated in the
                Act.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Any enquiries regarding this guide and its contents should be
                directed to:
                <br />
                The South African Human Rights Commission:
                <br />
                PAIA Unit (the Research and Documentation Department)
                <br />
                Postal address: Private Bag 2700, Houghton, 2041
                <br />
                Telephone: +27 11 484-8300
                <br />
                Fax: +27 11 484-7146
                <br />
                Website: www.sahrc.org.za
                <br />
                E-mail: PAIA@sahrc.org.za
                <br />
                Or, alternatively, its successor,
                <br />
                The Information Regulator (South Africa)
                <br />
                SALU Building, 316 Thabo Sehume Street, Pretoria
                <br />
                Ms. Mmamoroke Mphelo
                <br />
                Tel: 012 406 4818
                <br />
                Fax: 086 500 3351
                <br />
                inforeg@justice.gov.za
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>5 Access to Records Held By The Company:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Records held by the Company may be accessed on request only once
                the requirements for access have been met. A requester is any
                person making a request for access to a record of the Company
                and in this regard, the Act distinguishes between two types of
                requesters:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Personal Requester-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A personal requester is a requester who is seeking access to a
                record containing personal information about the requester.
                Subject to the provisions of the Act and applicable law, the
                Company will provide the requested information, or give access
                to any record with regard to the requester’s personal
                information. The prescribed fee for reproduction of the
                information requested will be charged by the Company.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Other Requester-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                This requester (other than a personal requester) is entitled to
                request access to information pertaining to third parties.
                However, the Company is not obliged to grant access prior to the
                requester fulfilling the requirements for access in terms of the
                Act. The prescribed fee for reproduction of the information
                requested will be charged by the Company.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Request Procedure:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A requester must comply with all the procedural requirements
                contained in the Act relating to a request for access to a
                record. A requester must complete the prescribed form enclosed
                herewith in Appendix 1 and submit same as well as payment of a
                request fee and a deposit, if applicable to the information
                officer at the postal or physical address, fax number or
                electronic mail address stated herein. The prescribed form must
                be filled in with enough particularity to at least enable the
                information officer to identify:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The record or records requested;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The identity of the requester;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                What form of access is required; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The postal address or fax number of the requester.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A requester must state that he or she requires the information
                in order to exercise or protect a right, and clearly state what
                the nature of the right is so to be exercised or protected. The
                requester must also provide an explanation of why the requested
                record is required for the exercise or protection of that right.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company will process a request within 30 (thirty) days,
                unless the requestor has stated special reasons which would
                satisfy the information officer that circumstances dictate that
                this time period not be complied with.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The requester shall be informed in writing whether access has
                been granted or denied. If, in addition, the requester requires
                the reasons for the decision in any other manner, he or she must
                state the manner and the particulars so required. If a request
                is made on behalf of another person, the requester must then
                submit proof of the capacity in which the requester is making
                the request to the satisfaction of the information officer.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If an individual is unable to complete the prescribed form
                because of illiteracy or disability, such a person may make the
                request orally to the information officer.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>6 Decision:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company will, within 30 (thirty) days of receipt of a
                request, decide whether to grant or decline a request and give
                notice with reasons (if required) to that effect. The 30
                (thirty) day period within which the Company has to decide
                whether to grant or refuse a request, may be extended for a
                further period of not more than 30 (thirty) days if the request
                is for a large quantity of information, or the request requires
                a search for information held at another office of the Company
                (other than the head office) and the information cannot
                reasonably be obtained within the original 30 (thirty) day
                period. The information officer will notify the requester in
                writing should an extension be necessary.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>7 Fees:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Act provides for two types of fees:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A request fee, (which will be a standard fee) and an access fee,
                which must be calculated by taking into account reproduction
                costs, search and preparation time and cost, as well as postal
                costs where applicable. When a request is received by the
                information officer of the Company, the information officer
                shall by notice require the requester, other than a personal
                requester, to pay the prescribed request fee (if any), before
                further processing of the request. If a search for the record is
                necessary and the preparation of the record for disclosure,
                including arrangement to make it available in the requested
                form, requires more than the hours prescribed in the regulations
                for this purpose, the information officer shall notify the
                requester to pay as a deposit the prescribed portion of the
                access fee which would be payable if the request is granted.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The information officer shall withhold a record until the
                requester has paid the fee or fees as indicated. A requester
                whose request for access to a record has been granted, must pay
                an access fee for reproduction and for search and preparation,
                and for any time reasonably required in excess of the prescribed
                hours to search for and prepare the record for disclosure
                including making arrangements to make it available in the
                request form. If a deposit has been paid in respect of a request
                for access, which is refused, then the information officer shall
                repay the deposit to the requester.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>
          8 Categories of Records Held by the Company (in terms of Section
          51(1)(E)) may include:{" "}
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Companies Act Records-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Documents of incorporation;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Memorandum of Incorporation;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Minutes of Board of Directors meetings;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Records relating to the appointment of directors / auditor /
                secretary / public officer and other officers; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Share Register and other statutory registers.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Financial Records-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Annual Financial Statements;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Tax Returns;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Accounting Records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Banking Records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Bank Statements;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Electronic banking records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Asset Register;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Rental Agreements; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Invoices
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Tax Records-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                PAYE Records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Documents issued to employees for income tax purposes;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Records of payments made to SARS on behalf of employees; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                All other statutory compliances;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                VAT;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Skills Development Levies;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.4.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                UIF; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3.4.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Workmen’s Compensation.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Personnel Documents and Records-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Employment contracts;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Employment Equity Plan (if applicable);
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Disciplinary records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Salary records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Disciplinary code;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Leave records;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Training records; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.4.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Training manuals.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Client Documents and Records-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.5.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Contact details (telephone numbers and e-mail addresses) of
                clients;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.5.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Company registration details; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.5.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Physical and postal addresses.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>9 Processing of Personal Information:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Purpose of Processing-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company uses the Personal Information under its care in
                terms of its Privacy Policy and inter alia in the following
                ways:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Rendering service according to instructions given by clients;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Staff administration;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Keeping of accounts and records; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Complying with tax laws.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Categories of Data Subjects and their Personal Information-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company may possess records relating to suppliers,
                shareholders, contractors service providers, staff and clients:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Entity Type;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Personal Information Processed;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Clients – Juristic Persons / Entities;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Names of contact persons; Name of Legal Entity; Physical and
                Postal address and contact details; Financial information;
                Registration Number; Founding documents; Tax related
                information; authorised signatories, beneficiaries, ultimate
                beneficial owners;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Clients –
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Names; registration number; contact details; physical and postal
                addresses; Tax related information; confidential correspondence.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Intermediary / Advisor-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Names of contact persons; Name of Legal Entity; Physical and
                Postal address and contact details; Financial information;
                Registration Number; Founding documents; Tax related
                information; authorised signatories, beneficiaries, ultimate
                beneficial owners.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Contracted Service Providers-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.5.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Names of contact persons; Name of Legal Entity; Physical and
                Postal address and contact details; Financial information;
                Registration Number; Founding documents; Tax related
                information; authorised signatories, beneficiaries, ultimate
                beneficial owners.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Employees / Directors
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.6.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Gender, Pregnancy; Marital Status; Colour, Age, Language,
                Education information; Financial Information; Employment
                History; ID number; Physical and Postal address; Contact
                details; Opinions, Criminal behaviour; Well-being.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>
          10 Categories of Recipients for Processing the Personal Information:{" "}
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company may supply the Personal Information to service
                providers who render the following services:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Capturing and organising of data;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Storing of data;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Sending of emails and other correspondence to clients
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Conducting due diligence checks; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Delivering goods
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>
          11 Actual or Planned Trans border Flows of Personal Information:
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company may transfer data trans-border in order to store
                data with third party cloud storage providers; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                To service providers in other territories but only with your
                consent.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>12 General Description of Information Security Measures:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company employs up to date technology to ensure the
                confidentiality, integrity and availability of the Personal
                Information under its care. Measures include-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Firewalls
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Virus protection software and update protocols
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Logical and physical access control;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Secure setup of hardware and software making up the IT
                infrastructure;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Outsourced Service Providers who process Personal Information on
                behalf of the Company are contracted to implement security
                controls.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>13 Remedies Available If Request for Information Is Refused:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Internal Remedies-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Company does not have internal appeal procedures. As such,
                the decision made by the information officer pertaining to a
                request is final, and requestors will have to exercise such
                external remedies at their disposal if a request is refused, and
                the requestor is not satisfied with the response provided by the
                information officer.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                External Remedies-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                13.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A requestor that is dissatisfied with the information officer’s
                refusal to disclose information, may within 30 (thirty) days of
                notification of the decision, apply to a court for relief.
                Likewise, a third party dissatisfied with the information
                officer’s decision to grant a request for information, may
                within 30 (thirty) days of notification of the decision, apply
                to a court for relief. For purposes of the Act, courts that have
                jurisdiction over these applications are the Constitutional
                Court, the High Court or another court of similar status.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>14 List of Applicable Legislation:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Records of the Company’s and other legal entities in which the
                Company has a direct controlling interest or an indirect
                controlling interest through its subsidiaries) may be kept by or
                on behalf of the Company in accordance with the following
                legislation (some of which legislation may not be applicable to
                the Company), as well as with other legislation that may apply
                to the Company and/or its subsidiaries from time to time-
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Basic Conditions of Employment Act 57 of 1997;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Broad-based Black Economic Empowerment Act 53 of 2003 Companies
                Act 71 of 2008;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Compensation for Occupational Injuries and Diseases Act 130 of
                1993;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Copyright Act 98 of 1978;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Currencies and Exchanges Act 9 of 1993;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Electronic Communications and Transactions Act 25 of 2002;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Employment Equity Act 55 of 1998;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Financial Intelligence Centre Act 38 of 2001;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Financial Institutions (Protection of Funds) Act 28 of 2001
                Financial Services Board Act 97 of 1990;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Income Tax Act 58 of 1962;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.11
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                nspection of Financial Institutions Act 80 of 1998 Labour
                Relations Act 66 of 1995;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.12
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Occupational Health and Safety Act 85 of 1993;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.13
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Regulation of Interception of Communications and Provision of
                Communication-Related Information Act 70 of 2002;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.14
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Prevention of Organised Crime Act 121 of 1998;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.15
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Prevention and Combating of Corrupt Activities Act 12 of 2004
                Promotion of Access to Information Act 2 of 2000;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.16
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Protected Disclosures Act 26 of 2000;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.17
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Protection of Constitutional Democracy against Terrorist and
                Related Activities Act 33 of 2004 Skills Development Act 97 of
                1998;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.18
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Skills Development Levy Act 9 of 1999 Securities Transfer Tax
                Act 25 of 2007;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.19
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Securities Transfer Tax Administration Act 26 of 2007 ;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.20
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Trade Marks Act 194 of 1993;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.21
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Trust Property Control Act 57 of 1988;{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.22
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Unemployment Insurance Act 30 of 1966;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.23
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Unemployment Insurance Contributions Act 4 of 2002 ; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                14.1.24
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Value Added Tax Act 89 of 1991.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>15 Availability of The Manual:</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                15.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The manual is available for inspection, on reasonable prior
                notice, at the office of the Company free of charge. Copies of
                the manual of the Company are also available from the SAHRC.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        Appendix 1: Prescribed Form C <br />
        To Be Completed by A Requester and available here:
        <br />
        <a href="https://www.justice.gov.za/forms/paia/J752_paia_Form%20C.pdf">
          https://www.justice.gov.za/forms/paia/J752_paia_Form%20C.pdf
        </a>
      </div>
    );
  }
}
export default withRouter(Privacy);
