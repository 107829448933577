import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import TagButton from "./tagbutton";
import TagButtonMulti from "./tagbuttonmulti";
import Auth from "@aws-amplify/auth";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";
import { isMobile } from "react-device-detect";
import SearchIcon from '@material-ui/icons/Search';


class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchbar: "",
      loading: "",
      makevisible: false,
      makeSelected: "",
      modelSelected: "",
      modelvisible: false,

      yearsvisible: false,
      parts1visible: false,
      parts2visible: false,
      parts3visible: false,
      parts4visible: false,
      parts5visible: false,
      parts1choice: "",
      parts2choice: "",
      parts3choice: "",
      parts4choice: "",
      parts5choice: "",
      yearsTag: [],
      yearsAvailable: [],
      bodyTags: [],
      authed: false,
      searchstring: ""
    };
  }
  async componentDidMount() {
    const currentID = await Utils.checkCurrentUser();
    if (typeof currentID.message !== "undefined") {
      if (typeof currentID.message.username === "undefined")
        currentID.message.username = currentID.message.id;

      const user = await Utils.getUserById(currentID.message.username);
      this.setState({ userData: user.user });
    }
    const yearsAvailable = [];
    for (let i = 2000; i < 2023; i++) {
      yearsAvailable.push({ year: `${i}`, selected: false });
    }
    this.setState({ yearsAvailable });
    Auth.currentAuthenticatedUser()
      .then(res => {
        this.setState({ authed: true });
        // Utils.log(res);
      })
      .catch(err => {});
  }
  addBodyTag(input) {
    let oldbodyTags = this.state.bodyTags;
    oldbodyTags.push(input);
    oldbodyTags = [...new Set(oldbodyTags)];
    this.setState({ bodyTags: oldbodyTags });
  }
  async getNewestItemsUnauth() {
    try {
      let s = await Auth.currentAuthenticatedUser();
      this.setState({ selfuserid: s.username });
    } catch (err) {}
    let result = await Utils.getNewestItemsUnauth();
    result = result
      .filter(z => z.user.id !== this.state.selfuserid)
        .filter(z => z.user.disabled !== true)
      .filter(z => z.available !== false);
    var imagekeys = [];
    result.forEach(a => {
      if (a.user.profilepic !== null) {
        // console.log(a.user.profilepic)
        a.user.profilepic = JSON.parse(a.user.profilepic);
        imagekeys.push(a.user.profilepic.key);
      }
      if (a.photos.items.length > 0)
        a.photos.items.forEach(b => {
          imagekeys.push(b.key);
        });
    });
    let uris = await Promise.all(imagekeys.map(Utils.getLatestPic));
    // console.log(uris)
    var defaultpic = await Utils.getLatestPic("defaultpic.png");
    result.forEach(a => {
      if (a.photos.items.length > 0)
        a.photos.items.forEach(b => {
          uris.forEach(c => {
            if (b.key === c.key) b.uri = c.uri;
          });
        });
      else a.photos.items.push(defaultpic);
    });
    result.forEach(a => {
      if (a.user.profilepic !== null) {
        uris.forEach(r => {
          if (a.user.profilepic.key === r.key) a.user.profilepic = r;
        });
      } else {
        a.user.profilepic = defaultpic;
      }
    });
    this.props.onResult(result);
  }
  async search() {
    let searchstrings = [];
    if (this.state.searchstring.indexOf(",") > -1)
      searchstrings = searchstrings.concat(this.state.searchstring.split(","));
    else searchstrings.push(this.state.searchstring);
    searchstrings.push(this.state.makeSelected);
    searchstrings.push(this.state.modelSelected);
    searchstrings = searchstrings.concat(
      this.state.yearsAvailable.filter(z => z.selected).map(z => z.year)
    );
    searchstrings = searchstrings.concat(this.state.bodyTags);
    searchstrings = [...new Set(searchstrings)];
    searchstrings = searchstrings.filter(z => z !== "");
    if (searchstrings.length === 0) this.getNewestItemsUnauth();
    else {
      let result = await Utils.search(searchstrings);


      result = result.filter(z => z.available !== false);

      var imagekeys = [];
      result.forEach(a => {
        if (a.user.profilepic !== null) {
          a.user.profilepic = JSON.parse(a.user.profilepic);
          imagekeys.push(a.user.profilepic.key);
        }
        if (a.photos.items.length > 0)
          a.photos.items.forEach(b => {
            imagekeys.push(b.key);
          });
      });

      let uris = await Promise.all(imagekeys.map(Utils.getLatestPic));
      result.forEach(a => {
        if (a.photos.items.length > 0)
          a.photos.items.forEach(b => {
            uris.forEach(c => {
              if (b.key === c.key) b.uri = c.uri;
            });
          });
      });
      var defaultpic = await Utils.getLatestPic("defaultpic.png");
      result.forEach(a => {
        if (a.user.profilepic !== null) {
          uris.forEach(r => {
            if (a.user.profilepic.key === r.key) a.user.profilepic = r;
          });
        } else {
          a.user.profilepic = defaultpic;
        }
      });
      this.props.onResult(result);
    }
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  render() {
    return (
      <div style={{ margin: 0 }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.statusbar}
        autoHideDuration={3000}
        onClose={() => this.setState({ statusbar: false })}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{this.state.statusbartext}</span>}
      />
        <Dialog
          open={this.state.makevisible}
          onClose={() => this.setState({ makevisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={Utils.pureCarMakesList()}
                onDone={() =>
                  this.setState({
                    makevisible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    makevisible: false
                  })
                }
                onSelected={make => {
                  this.setState({
                    makevisible: false,
                    modelvisible: true,
                    makeSelected: make,
                    modelsAvailable: Utils.formattedCarsList().filter(
                      z => z.make === make
                    )[0].model
                  });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.modelvisible}
          onClose={() => this.setState({ modelvisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={this.state.modelsAvailable}
                onDone={() =>
                  this.setState({
                    makevisible: true,
                    modelvisible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    modelvisible: false
                  })
                }
                onSelected={model => {
                  this.setState({
                    makevisible: false,
                    modelvisible: false,
                    modelSelected: model
                  });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.parts1visible}
          onClose={() => this.setState({ parts1visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts1visible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    parts1visible: false
                  })
                }
                items={[...new Set(Utils.partslist().map(z => z.Cat1))]}
                onSelected={input => {
                  this.setState({
                    parts1visible: false,
                    parts2visible: true,
                    parts1choice: input
                  });
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts2visible}
          onClose={() => this.setState({ parts2visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                items={Utils.getSubcatOfParts(this.state.parts1choice)}
                onDone={() =>
                  this.setState({
                    parts1visible: true,
                    parts2visible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    parts2visible: false
                  })
                }
                onSelected={input => {
                  this.setState({
                    parts2visible: false,
                    parts3visible: true,
                    parts2choice: input
                  });
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts3visible}
          onClose={() => this.setState({ parts3visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts3visible: false,
                    parts2visible: true
                  })
                }
                onAll={() =>
                  this.setState({
                    parts3visible: false
                  })
                }
                items={Utils.getComponentOfSubCatParts(
                  this.state.parts1choice,
                  this.state.parts2choice
                )}
                onSelected={input => {
                  if (
                    (Utils.getSubComponentOfSubCatParts(
                      this.state.parts1choice,
                      this.state.parts2choice,
                      input
                    ).length === 1 &&
                      Utils.getSubComponentOfSubCatParts(
                        this.state.parts1choice,
                        this.state.parts2choice,
                        input
                      )[0] === input) ||
                    (Utils.getSubComponentOfSubCatParts(
                      this.state.parts1choice,
                      this.state.parts2choice,
                      input
                    ).length === 1 &&
                      Utils.getSubComponentOfSubCatParts(
                        this.state.parts1choice,
                        this.state.parts2choice,
                        input
                      )[0] === "")
                  ) {
                    this.setState({
                      parts3visible: false,
                      parts4visible: false,
                      parts3choice: input
                    });
                  } else {
                    this.setState({
                      parts3visible: false,
                      parts4visible: true,
                      parts3choice: input
                    });
                  }
                  this.addBodyTag(input);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.parts4visible}
          onClose={() => this.setState({ parts4visible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButton
                onDone={() =>
                  this.setState({
                    parts3visible: true,
                    parts4visible: false
                  })
                }
                onAll={() =>
                  this.setState({
                    parts4visible: false
                  })
                }
                items={Utils.getSubComponentOfSubCatParts(
                  this.state.parts1choice,
                  this.state.parts2choice,
                  this.state.parts3choice
                )}
                onSelected={input => {
                  this.addBodyTag(input);
                  this.setState({ parts4visible: false, parts4choice: input });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.yearsvisible}
          onClose={() => this.setState({ yearsvisible: false })}
          width={1}
        >
          <DialogContent>
            <div>
              <TagButtonMulti
                items={this.state.yearsAvailable}
                onDone={() => this.setState({ yearsvisible: false })}
                onSelected={input => {
                  const yearsTagOld = this.state.yearsAvailable;
                  yearsTagOld.forEach(z => {
                    if (z.year === input) z.selected = !z.selected;
                  });
                  this.setState({ yearsAvailable: yearsTagOld });
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
        <div style={{display:'flex', justifyContent: 'space-between'}}>
        <TextField
          id="standard-search"
          label="Search"
          type="search"
          style={{ width: isMobile ? "75%" : "95%", margin: isMobile ? 10 : 0 }}
          value={this.state.searchstring || ""}
          onChange={searchWord => {
            this.setState({ searchstring: searchWord.target.value });
          }}
          onKeyDown={(key) => {
            
            if (key.keyCode===13) this.search();
          }}
        />
        <Button
        transparent="true"
          style={{
            marginTop: 20,

            color: "#00a0e2"
          }}

          onClick={() => {
            this.search();
          }}
        >
          <SearchIcon/>
        </Button>
        </div>

        {this.state.loading}


        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {this.state.makeSelected !== "" ? (
            <Chip
              onDelete={() => this.setState({ makeSelected: "" })}
              label={this.state.makeSelected}
            ></Chip>
          ) : (
            <div />
          )}
          {this.state.modelSelected !== "" ? (
            <Chip
              onDelete={() => this.setState({ modelSelected: "" })}
              label={this.state.modelSelected}
            ></Chip>
          ) : (
            <div />
          )}
          {this.state.yearsAvailable
            .filter(z => z.selected)
            .map((e, i) => (
              <Chip
                key={i}
                onDelete={yr => {
                  const yearsTagOld = this.state.yearsAvailable;
                  yearsTagOld.forEach(z => {
                    if (z.year === e.year) z.selected = !z.selected;
                  });
                  this.setState({ yearsAvailable: yearsTagOld });
                }}
                label={e.year}
              ></Chip>
            ))}
          {this.state.bodyTags.map((e, i) => (
            <Chip
              key={i}
              onDelete={() => {
                const yearsTagOld = this.state.bodyTags;
                yearsTagOld.splice(i, 1);

                this.setState({ bodyTags: yearsTagOld });
              }}
              label={e}
            ></Chip>
          ))}
        </div>

        <br />
        <Button
          value="bold"
          variant="contained"
          style={{
            backgroundColor: "#00a0e2",
            color: "#fff",
              marginLeft: isMobile?10:0
          }}
          onClick={() => {
            this.setState({ makevisible: true });
          }}
        >
          Filter car make / model
        </Button>
        <Button
          value="bold"
          variant="contained"
          style={{
            backgroundColor: "#00a0e2",
            color: "#fff",
            marginLeft: 10
          }}
          onClick={() => {
            this.setState({ yearsvisible: true });
          }}
        >
          Filter model year
        </Button>
        <Button
          value="bold"
          variant="contained"
          style={{
            backgroundColor: "#00a0e2",
            color: "#fff",
            marginLeft: 10
          }}
          onClick={() => {
            this.setState({ parts1visible: true });
          }}
        >
          Filter car part description
        </Button>
        <Button
          value="bold"
          variant="contained"
          style={{
            backgroundColor: "#00a0e2",
            color: "#fff",
            marginLeft: 10
          }}
          onClick={() => {
            this.setState({searchstring:"auto spares"}, ()=>this.search())
          }}
        >
          Auto spares
        </Button>
        <Button
          value="bold"
          variant="contained"
          style={{
            backgroundColor: "#00a0e2",
            color: "#fff",
            marginLeft: 10
          }}
          onClick={() => {
this.setState({searchstring:"tools and machinery"}, ()=>this.search())
          }}
        >
          Tools and machinery
        </Button>

        <div style={{ margin: 20 }} />
      </div>
    );
  }
}
export default SearchBar;
