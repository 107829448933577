import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MoneyIcon from "@material-ui/icons/Money";

class EFT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: props.location.state.cart,
      idOfCart: props.location.state.idOfCart,
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  async emailinvoice() {
    let sum = 0;
    this.doSnackBar("Please wait");
    this.state.cart.forEach((z) => (sum += parseInt(z.price)));
    let s = await Utils.moveCartIntoHistoryINVOICE(this.state.idOfCart, sum);
    if (s) {
      this.doSnackBar("Invoice sent");
      this.props.history.push("/orderhistory");
    }
  }

  render() {
    let sum = 0;
    this.state.cart.forEach((z) => (sum += parseInt(z.price)));
    return (
      <div style={{ color: "#666", margin: 10 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={15000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h2>EFT Instructions</h2>
        <div>
          <ul>
            <li>Click the PAY BY EFT button to generate an invoice</li>
            <li>Invoice will be emailed to you with a reference</li>
            <li>Make payment using the reference</li>
            <li>
              In your Order History, the basket will appear as "processing"
            </li>
            <li>
              You will receive an email notification once payment has been
              processed and shipping has begun
            </li>
          </ul>
        </div>
        <Button
          style={{
            margin: 5,
            backgroundColor: "#00a0e2",
            color: "#fff",
            alignSelf: "center",
          }}
          variant="contained"
          onClick={() => {
            this.emailinvoice();
          }}
        >
          <MoneyIcon /> Pay by EFT {Utils.formatTextToCurrency(sum.toFixed(2))}
        </Button>
      </div>
    );
  }
}
export default withRouter(EFT);
