import React from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

export default class TagButton extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Button
            style={{
              backgroundColor: "#00a0e2",
              color: "#fff",
            }}
            variant="contained"
            onClick={() => this.props.onDone()}
          >
            <div>Back</div>
          </Button>
          <Button
            style={{
              backgroundColor: "#00a0e2",
              color: "#fff",
            }}
            variant="contained"
            onClick={() => this.props.onAll()}
          >
            <div>Close</div>
          </Button>
        </div>

        <List>
          {this.props.items.map((e, i) => (
            <ListItem key={i} style={{margin:0, padding:0}}>
              <Button
                style={{ width: 500,  }}
                onClick={() => this.props.onSelected(e)}
              >
                {e}
              </Button>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}
