import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import ExifOrientationImg from "react-exif-orientation-img";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Auth from "@aws-amplify/auth";
import { isMobile } from "react-device-detect";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: "",
      userProfile: {
        areacode: "",
        availableslots: 0,
        buildingaddress: "",
        businessname: "",
        contact: "",
        contactemail: "",
        contactphone: "",
        contactphonealternate: "",
        federatedid: "",
        city: "",
        id: "",
        listings: {},
        profilepic: { uri: "" },
        province: "",
        streetaddress: "",
        suburb: "",
        vatnumber:"",
        user: null,
        verified: false
      },
      loading: true
    };
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    let cog = await Auth.currentAuthenticatedUser();
    if (cog) {
      this.setState({ authed: true });
      var username = "";
      if (cog.username) username = cog.username;
      else username = cog.id;
      let user = await Utils.getUserProfileById(username);
      if (user.user.profilepic === null)
        user.user.profilepic = await Utils.getLatestPic("defaultpic.png");
      else
        user.user.profilepic = await Utils.getLatestPic(
          JSON.parse(user.user.profilepic).key
        );
      this.setState({
        userProfile: user.user,
        loading: false
      });
    }
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async updateProfilePicture(input) {
    let imgs = await Utils.uploadtoS3(input.split(",")[1]);
    var olduser = this.state.userProfile;
    olduser.profilepic = JSON.stringify({ key: imgs.key });
    await Utils.updateprofilepic(olduser);
    window.location.reload();
  }

  render() {
    if (this.state.loading)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div style={{ color: "#666", margin: isMobile ? 5 : 0 }}>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.statusbar}
            autoHideDuration={3000}
            onClose={() => this.setState({ statusbar: false })}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.statusbartext}</span>}
          />
          <h2>{this.state.userProfile.businessname}</h2>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <div
              style={{
                width: isMobile ? "98%" : "50%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <ExifOrientationImg
                ref={this.myRef}
                alt=""
                src={this.state.userProfile.profilepic.uri}
                style={{ width: "100%", objectFit: "contain" }}
                onLoad={() => {}}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginLeft: isMobile ? 0 : 20,
                marginRight: isMobile ? 0 : 20
              }}
            >
              <div></div>
              <div>
                Contact person: <b>{this.state.userProfile.contact}</b>
              </div>
              <div>
                Building address:{" "}
                <b>{this.state.userProfile.buildingaddress}</b>
              </div>
              <div>
                Street address: <b>{this.state.userProfile.streetaddress}</b>
              </div>
              <div>
                Suburb: <b>{this.state.userProfile.suburb}</b>
              </div>
              <div>
                City: <b>{this.state.userProfile.city}</b>
              </div>
              <div>
                Province: <b>{this.state.userProfile.province}</b>
              </div>
              <div>
                Area code: <b>{this.state.userProfile.areacode}</b>
              </div>
              <div>
                Phone: <b>{this.state.userProfile.contactphone}</b>
              </div>
              <div>
                Phone (alternative):{" "}
                <b>{this.state.userProfile.contactphonealternate}</b>
              </div>
              <div>
                Email: <b>{this.state.userProfile.contactemail}</b>
              </div>
              <div>
                Vat: <b>{this.state.userProfile.vatnumber}</b>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                Verified seller:{" "}
                {this.state.userProfile.verified === true ? (
                  <CheckCircleIcon style={{ color: "#388e3c" }} />
                ) : (
                  <CancelIcon style={{ color: "#757575" }} />
                )}
              </div>

              <Button
                style={{
                  margin: 5,
                  backgroundColor: "#00a0e2",
                  color: "#fff",
                  alignSelf: "center",
                  width: "100%"
                }}
                variant="contained"
                onClick={() => {
                  this.props.history.push("/profileedit");
                }}
              >
                Edit
              </Button>
              <Button
                style={{
                  margin: 5,
                  backgroundColor: "#00a0e2",
                  color: "#fff",
                  alignSelf: "center",
                  width: "100%"
                }}
                variant="contained"
                onClick={() => {
                  this.props.history.push("/orderhistory");
                }}
              >
                Order history
              </Button>
              <Button
                style={{
                  margin: 5,
                  backgroundColor: "#00a0e2",
                  color: "#fff",
                  alignSelf: "center",
                  width: "100%"
                }}
                variant="contained"
                onClick={() => {
                  this.props.history.push("/waybills");
                }}
              >
                Waybills
              </Button>
              {this.state.userProfile.admin === true ? (
                <Button
                  style={{
                    margin: 5,
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center",
                    width: "100%"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.props.history.push("/verifyseller");
                  }}
                >
                  Verify Sellers
                </Button>
              ) : null}
              {this.state.userProfile.admin === true ? (
                <Button
                  style={{
                    margin: 5,
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center",
                    width: "100%"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.props.history.push("/processeft");
                  }}
                >
                  Process EFT
                </Button>
              ) : null}
              {this.state.userProfile.verified === true ? null : (
                <Button
                  style={{
                    margin: 5,
                    backgroundColor: "#00a0e2",
                    color: "#fff",
                    alignSelf: "center",
                    width: "100%"
                  }}
                  variant="contained"
                  onClick={() => {
                    this.props.history.push("/requestaccess");
                  }}
                >
                  Request verification for access to sell
                </Button>
              )}
            </div>
          </div>
          <div style={{ width: 1000 }} />
        </div>
      );
  }
}

export default withRouter(Profile);
