import { graphqlOperation, API } from "aws-amplify";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";
import Storage from "@aws-amplify/storage";
import Auth from "@aws-amplify/auth";
// import  Amplify from "@aws-amplify/core";
// import awsconfig from "./aws-exports"; // your Amplify configuration

const Utils = {
  async getNewestItemsUnauth() {
    // Amplify.configure(awsconfig);
    // console.log(Auth.configure())
    var today = new Date(Date.now());
    today.setHours(today.getHours() - 1744);

    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.listOttoPartzListings.replace(
            "photos {",
            "  photos (limit:20) {  items { id   key   type   height   width}   "
          ),
          {
            limit: 80,
            filter: { listdate: { gt: today } }
          }
        )
      );
      // console.log({makeDynamo});
      return makeDynamo.data.listOttoPartzListings.items;

    } catch (err) {
      console.log({err});
    }
  },
  async findcart(trxid) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(queries.listOttoPartzTransactionss, {
          limit: 10000,
          filter: { transactionstatus: { eq: trxid } }
        })
      );
      return makeDynamo.data.listOttoPartzTransactionss.items[0];
    } catch (err) {
      console.log(err);
    }
  },
  async signout() {
    return await Auth.signOut();
  },
  async getUserById(cognito) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.getOttoPartzUser
            .replace("listings", "listings(limit:1000)")
            .replace("transactions", "transactions(limit:1000)")
            .replace(
              "listdate",
              "  listdate    photos (limit:30) {              items {                id                key                type                height                width              }              nextToken            }"
            ),
          {
            id: cognito
          }
        )
      );
      return { success: true, user: makeDynamo.data.getOttoPartzUser };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async search(input) {
    input = [...new Set(input.filter(z => z !== ""))];
    async function getsingle(oneword) {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.listOttoPartzListings.replace(
            "photos {",
            "  photos (limit:20) {  items { id   key   type   height   width}   "
          ),
          {
            limit: 2000,
            filter: {
              hiddentags: { contains: oneword.toLocaleLowerCase().trim() }
            }
          }
        )
      );
      return makeDynamo.data.listOttoPartzListings.items;
    }

    let res = await Promise.all(input.map(getsingle));
    var results = [];
    res.forEach(a => {
      a.forEach(b => {
        results.push(b);
      });
    });
    results = [...new Set(results)];
    return results;
  },
  async getNewestItems() {
    var today = new Date(Date.now());
    today.setHours(today.getHours() - 744);

    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.listOttoPartzListings.replace(
            "photos {",
            "  photos (limit:20) {  items { id   key   type   height   width}   "
          ),
          {
            limit: 80,
            filter: { listdate: { gt: today } }
          }
        )
      );
      return makeDynamo.data.listOttoPartzListings.items;
    } catch (err) {
      console.log(err);
    }
  },
  async searchUnAuth(input) {
    input = [...new Set(input.filter(z => z !== ""))];
    async function getsingle(oneword) {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.listOttoPartzListings.replace(
            "photos {",
            "  photos (limit:20) {  items { id   key   type   height   width}   "
          ),
          {
            limit: 2000,
            filter: {
              hiddentags: { contains: oneword.toLocaleLowerCase().trim() }
            }
          }
        )
      );
      return makeDynamo.data.listOttoPartzListings.items;
    }

    let res = await Promise.all(input.map(getsingle));
    var results = [];
    res.forEach(a => {
      a.forEach(b => {
        results.push(b);
      });
    });
    results = [...new Set(results)];
    return results;
  },
  async getLatestPic(key) {
    try {

      let s3 = await Storage.get(key.replace("large","thumbs"));
      return { key, uri: s3 };
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getLatestPicHD(key) {
    try {
      let s3 = await Storage.get(key);
      return { key, uri: s3 };
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async saveAdminUser(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzUser, {
          input: {
            id: input.id,
            admin: input.admin
          }
        })
      );
      return { success: true, user: makeDynamo.data.updateOttoPartzUser };
    } catch (err) {
      return { success: false, message: err.message };
    }
  },
  async saveVerifiedUser(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzUser, {
          input: {
            id: input.id,
            verified: input.verified
          }
        })
      );
      return { success: true, user: makeDynamo.data.updateOttoPartzUser };
    } catch (err) {
      return { success: false, message: err.message };
    }
  },
  async saveDisabledUser(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzUser, {
          input: {
            id: input.id,
            disabled: input.disabled
          }
        })
      );
      return { success: true, user: makeDynamo.data.updateOttoPartzUser };
    } catch (err) {
      return { success: false, message: err.message };
    }
  },
  async listusers() {
    const listOttoPartzUsers = `query ListOttoPartzUsers(
      $filter: ModelOttoPartzUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOttoPartzUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          businessname
          contact
          verified
          admin
          disabled
          contactemail
        }
        nextToken
      }
    }
    `;
    const makeDynamo = await API.graphql(
      graphqlOperation(listOttoPartzUsers, {
        limit: 1
      })
    );

    var nextToken = makeDynamo.data.listOttoPartzUsers.nextToken;
    while (nextToken !== null) {
      var i2 = await API.graphql(
        graphqlOperation(listOttoPartzUsers, {
          limit: 9999,

          nextToken: nextToken
        })
      );
      nextToken = i2.data.listOttoPartzUsers.nextToken;
      i2.data.listOttoPartzUsers.items.forEach(function(e) {
        makeDynamo.data.listOttoPartzUsers.items.push(e);
      });
      // console.log(invoicenumbers.data.listTransactionss.items.length)
    }
    return makeDynamo.data.listOttoPartzUsers.items;
  },
  async getuserbyname(name) {
    const listOttoPartzUsers = `query ListOttoPartzUsers(
      $filter: ModelOttoPartzUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOttoPartzUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          businessname
          contact
          verified
          admin
          disabled
          contactemail
        }
        nextToken
      }
    }
    `;
    const makeDynamo = await API.graphql(
      graphqlOperation(listOttoPartzUsers, {
        limit: 1, filter:{businessname:{eq:name}}
      })
    );
console.log(makeDynamo)
    var nextToken = makeDynamo.data.listOttoPartzUsers.nextToken;
    while (nextToken !== null) {
      var i2 = await API.graphql(
        graphqlOperation(listOttoPartzUsers, {
          limit: 9999,
filter:{businessname:{eq:name}},
          nextToken: nextToken
        })
      );
      nextToken = i2.data.listOttoPartzUsers.nextToken;
      i2.data.listOttoPartzUsers.items.forEach(function(e) {
        makeDynamo.data.listOttoPartzUsers.items.push(e);
      });
      // console.log(invoicenumbers.data.listTransactionss.items.length)
    }
    return makeDynamo.data.listOttoPartzUsers.items;
  },
  async getUserProfileById(cognito) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.getOttoPartzUser
            .replace("listings", "listings(limit:1000)")
            .replace(
              "listdate",
              "  listdate    photos (limit:30) {              items {                id                key                type                height                width              }              nextToken            }"
            ),
          {
            id: cognito
          }
        )
      );

      return { success: true, user: makeDynamo.data.getOttoPartzUser };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async getSellerListingById(cognito) {
    const getOttoPartzUser = /* GraphQL */ `
      query GetOttoPartzUser($id: ID!) {
        getOttoPartzUser(id: $id) {
          profilepic
            businessname
          listings {
            items {
              id
              user{
                profilepic
              }
              title
              details
              condition
              price
              available
              listdate
              ref
              tags
              hiddentags
              views
              createdAt
              updatedAt
            }
            nextToken
          }

        }
      }
    `;
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          getOttoPartzUser
            .replace("listings", "listings(limit:1000)")
            .replace(
              "listdate",
              "  listdate    photos (limit:30) {              items {                id                key                type                height                width              }              nextToken            }"
            ),
          {
            id: cognito
          }
        )
      );

      return { success: true, user: makeDynamo.data.getOttoPartzUser };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async getListingById(cognito) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(
          queries.getOttoPartzListing.replace("photos", "photos(limit:1000)"),
          {
            id: cognito
          }
        )
      );
      // console.log(makeDynamo)
      makeDynamo.data.getOttoPartzListing.tags = JSON.parse(
        makeDynamo.data.getOttoPartzListing.tags
      );
      return { success: true, listing: makeDynamo.data.getOttoPartzListing };
    } catch (err) {
      // console.log(err)
      return { success: false, message: err.message };
    }
  },
  async deleteListingById(id) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.deleteOttoPartzListing, {
          input: { id: id }
        })
      );

      return { success: true, listing: makeDynamo.data.deleteOttoPartzListing };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async createOttoPartzListing(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.createOttoPartzListing, {
          input: {
            title: input.title,
            details: input.details,
            condition: input.condition,
            available: input.available,
            price: input.price,
            listdate: input.listdate,
            tags: input.tags,
            hiddentags: input.hiddentags,
            ottoPartzListingUserId: input.ottoPartzListingUserId
          }
        })
      );
      return { success: true, listing: makeDynamo.data.createOttoPartzListing };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async updateOttoPartzListing(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzListing, {
          input: {
            id: input.id,
            title: input.title,
            details: input.details,
            condition: input.condition,
            price: input.price,
            listdate: input.listdate,
            tags: input.tags,
            hiddentags: input.hiddentags,
            ottoPartzListingUserId: input.ottoPartzListingUserId
          }
        })
      );
      return { success: true, listing: makeDynamo.data.updateOttoPartzListing };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async resetGlobalTags() {
    try {
      let gettags = await fetch(
        "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/Production/gettags"
      );
      return (await gettags.json()).message;
    } catch (error) {
      console.error(error);
    }
  },
  async createOttoPartzPhotos(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.createOttoPartzPhotos, {
          input: {
            key: input.key,
            type: input.type,
            height: input.height,
            width: input.width,
            ottoPartzPhotosListingId: input.id
          }
        })
      );
      return { success: true, user: makeDynamo.data.createOttoPartzPhotos };
    } catch (err) {
      console.log(err);

      return { success: false, message: err.message };
    }
  },
  async deleteOttoPartzPhotos(id) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.deleteOttoPartzPhotos, {
          input: {
            id: id
          }
        })
      );
      return { success: true, user: makeDynamo.data.deleteOttoPartzPhotos };
    } catch (err) {
      console.log(err);

      return { success: false, message: err.message };
    }
  },
  async uploadtoS3(b64, ext, ctype) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 100; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    text += "." + ext;
    Storage.configure({ level: "public" });

    let result = await Storage.put("large/"+text, Buffer.from(b64, "base64"), {
      contentType: ctype
    });
    result.id = result.key;

    return result;
  },
  async updateprofilepic(input) {
    let updateprofilepic = await API.graphql(
      graphqlOperation(mutations.updateOttoPartzUser, {
        input: {
          id: input.id,
          profilepic: input.profilepic
        }
      })
    );
    return updateprofilepic.data.updateUser;
  },
  async getThisUserId() {
    let cog = await Auth.currentAuthenticatedUser();
    var username = "";
    if (cog.username) username = cog.username;
    else username = cog.id;
    // let list = await Utils.findUserProfilesByCognitoId(username);
    // let user = await Utils.getUserProfileById(list[0].id);
    return username;
  },
  async createUser(eml, cognito) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.createOttoPartzUser, {
          input: {
            id: cognito,
            contactemail: eml,
            federatedid: cognito
          }
        })
      );

      return { success: true, user: makeDynamo };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async getCart(id) {
    const getOttoPartzUser = `query GetOttoPartzUser($id: ID!) {
      getOttoPartzUser(id: $id) {
        id         transactions(limit:10000) {
          items { id status total listings transactiondate transactioncode transactionstatus }
          nextToken
        }      }    }    `;
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(getOttoPartzUser, {
          id: id
        })
      );
      if (
        makeDynamo.data.getOttoPartzUser.transactions.items.length === 0 ||
        makeDynamo.data.getOttoPartzUser.transactions.items.filter(
          z => z.status === "cart"
        ).length === 0
      )
        return null;
      else
        return makeDynamo.data.getOttoPartzUser.transactions.items.filter(
          z => z.status === "cart"
        )[0];
    } catch (err) {
      console.log(err);
    }
  },
  async checkCurrentUser() {
    try {
      // await Auth.currentCredentials()
      const auth = await Auth.currentAuthenticatedUser();
      if (typeof auth.username === "undefined") auth.username = auth.id;
      return { success: true, message: auth };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async createNewCart(userid) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.createOttoPartzTransactions, {
          input: {
            ottoPartzTransactionsUserId: userid,
            status: "cart"
          }
        })
      );
      return {
        success: true,
        user: makeDynamo.data.createOttoPartzTransactions
      };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async updateCart(transactionid, listings) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: transactionid,
            listings: listings
          }
        })
      );
      return makeDynamo.data.updateOttoPartzTransactions;
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async saveWaybillData(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: input.id,
            waybilldata: input.waybilldata
          }
        })
      );
      return {
        success: true,
        message: makeDynamo.data.updateOttoPartzTransactions
      };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async updateCartTRXID(transactionid, transactionstatus, amount) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: transactionid,
            transactionstatus: transactionstatus,
            total: amount
          }
        })
      );
      return makeDynamo.data.updateOttoPartzTransactions;
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async addCart(input, thisuserid) {
    var cart = await this.getCart(thisuserid);
    // var cartnumber = {};
    if (cart === null) cart = (await this.createNewCart(thisuserid)).user;
    if (cart.listings === null) cart.listings = [];
    else cart.listings = JSON.parse(cart.listings);
    cart.listings.push(input);
    cart.listings = [...new Set(cart.listings)];
    cart.listings = JSON.stringify(cart.listings);
    // console.log(cart);
    await this.updateCart(cart.id, cart.listings);
    // console.log(update);
    // let s = await this.AddToCart(cart.id, input);
    // console.log(s);

    // if (typeof cart === "string") cart = [cart];
    // cart.push(input);
    //
    // cart = [...new Set(cart)];
    // await Cache.setItem("CART", cart);
  },
  async saveUser(input) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzUser, {
          input: {
            id: input.id,
            businessname: input.businessname,

            contact: input.contact,
            buildingaddress: input.buildingaddress,
            streetaddress: input.streetaddress,
            suburb: input.suburb,
            city: input.city,
            province: input.province,
            areacode: input.areacode,
            contactphone: input.contactphone,
            contactphonealternate: input.contactphonealternate,
            profilepic: input.profilepic,
            contactemail: input.contactemail,
            vatnumber: input.vatnumber
          }
        })
      );

      return { success: true, user: makeDynamo.data.updateOttoPartzUser };
    } catch (err) {
      return { success: false, message: err.message };
    }
  },
  async sendwaybillinfo(email, itemtitle, waybill) {
    var body = "<h1>Scrap App Shipping tracking</h1><br/>";
    body = body + "Your item: <b>" + itemtitle + "</b> has been shipped.<br/>";
    body = body + "Your tracking number is :" + waybill + " .<br/>";
    body =
      body +
      "You can track it on https://www.thecourierguy.co.za/tracking_home.php .<br/>";
    body = body + "For any queries, contact info@scrapapp.co.za .<br/>";
    body = body + "Thank you<br/>";
    let mail = await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/plainmailer?to=" +
        email +
        "&subject=" +
        "Scrap App Shipping tracking for " +
        itemtitle +
        "&body=" +
        body,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );
    return await mail.text();
  },
  async removeCartItem(userid, item) {
    let cart = await Utils.getCart(userid);
    if (cart.listings === null) cart.listings = [];
    else cart.listings = JSON.parse(cart.listings);
    var newCart = [];
    cart.listings.forEach(z => {
      if (z !== item) newCart.push(z);
    });
    cart.listings = newCart;
    // console.log(cart);
    cart.listings = JSON.stringify(cart.listings);
    let update = await this.updateCart(cart.id, cart.listings);
    // console.log(update)
    return update;
    // if (typeof cart === "string") cart = [cart];
    // cart.splice(item, 1);
    // await Utils.setCart(cart);
  },
  async setItemsToUnavailable(listings) {
    var cart = JSON.parse(listings);
    // console.log(cart)
    async function setListing(id) {
      await API.graphql(
        graphqlOperation(mutations.updateOttoPartzListing, {
          input: {
            id: id,
            available: false
          }
        })
      );
    }
    let res = await Promise.all(cart.map(setListing));
    return res;
  },
  async sendShippingInstruction(cartid) {
    var cartdata = await API.graphql(
      graphqlOperation(queries.getOttoPartzTransactions, {
        id: cartid
      })
    );
    cartdata = cartdata.data.getOttoPartzTransactions;
    var listingdata = JSON.parse(cartdata.listings);
    var listingdetails = await Promise.all(
      listingdata.map(Utils.getListingById)
    );
    var to = "info@scrapapp.co.za";
    var subject = "Scrap Store transaction success SHIPPING INSTRUCTION";
    var body = "<h1>Shipping instruction</h1><br/>";

    listingdetails.forEach((z, index) => {
      body =
        body +
        "<p><h4>" +
        (index + 1) +
        ") Instruct courier to pick up " +
        z.listing.title +
        " from " +
        z.listing.user.businessname +
        "</h4>";
      body = body + "Item description " + z.listing.details + " <br/>";
      body =
        body +
        "Business address " +
        z.listing.user.buildingaddress +
        ", " +
        z.listing.user.streetaddress +
        ", " +
        z.listing.user.suburb +
        ", " +
        z.listing.user.city +
        ", " +
        z.listing.user.province +
        ", " +
        z.listing.user.areacode +
        ", " +
        " <br/>";
      body = body + "Business phone " + z.listing.user.contactphone + " <br/>";
      body =
        body +
        "Business phone alt " +
        z.listing.user.contactphonealternate +
        " <br/>";
      body = body + "Business email " + z.listing.user.contactemail + " </p>";
        body = body + "Send seller waybill  </p>";
    });
    body =
      body +
      "<p><h4>" +
      (listingdetails.length + 1) +
      ") Instruct courier to DELIVER to  " +
      cartdata.user.businessname +
      "</h4>";

    body =
      body +
      "Business address " +
      cartdata.user.buildingaddress +
      ", " +
      cartdata.user.streetaddress +
      ", " +
      cartdata.user.suburb +
      ", " +
      cartdata.user.city +
      ", " +
      cartdata.user.province +
      ", " +
      cartdata.user.areacode +
      ", " +
      " <br/>";
    body = body + "Business phone " + cartdata.user.contactphone + " <br/>";
    body =
      body +
      "Business phone alt " +
      cartdata.user.contactphonealternate +
      " <br/>";
    body = body + "Business email " + cartdata.user.contactemail + " </p><br/>";
    body = body + "Send buyer waybill  </p>";
    body =
      body +
      "After 30 days of delivery to buyer, if there is no return, send seller the reciept(invoice) and payment  </p>";

    let mail = await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/plainmailer?to=" +
        to +
        "&subject=" +
        subject +
        "&body=" +
        body,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );

    for (var j = 0; j < listingdetails.length; j++) {
       body = "<h1>Scrap Store Item Sold</h1><br/>";
      body =
        body +
        "Your item: <b>" +
        listingdetails[j].listing.title +
        "</b> has been sold for " +
        Utils.formatTextToCurrency(listingdetails[j].listing.price) +
        "<br/>";
      body =
        body +
        "The Courier Guy will contact you regarding the date and time when your item can be picked up.<br/>";
      body = body + "For any queries, contact info@scrapapp.co.za .<br/>";
      body = body + "Thank you<br/>";

       mail = await fetch(
        "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/plainmailer?to=" +
          listingdetails[j].listing.user.contactemail +
          "&subject=" +
          "Scrap Store Item Sold" +
          "&body=" +
          body,
        {
          headers: { "Content-Type": "application/json" },
          crossDomain: true
        }
      );
    }


      await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/sendinvoice?to=" +
        cartdata.user.contactemail +
        "&subject=" +
        "Scrap Store Invoice " +
        "&body=" +
        cartid,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );
    // console.log(rr)
    // console.log(await mail2.text())

    return await mail.text();

  },
  async sendShippingInstructionINVOICE(cartid) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    var cartdata = await API.graphql(
      graphqlOperation(queries.getOttoPartzTransactions, {
        id: cartid
      })
    );
    cartdata = cartdata.data.getOttoPartzTransactions;
    var listingdata = JSON.parse(cartdata.listings);
    var listingdetails = await Promise.all(
      listingdata.map(Utils.getListingById)
    );
    var to = "info@scrapapp.co.za";
    var subject = "Scrap Store EFT transaction ("+text+") ";
    var body = "<h1>Cart info</h1><br/>";

    listingdetails.forEach((z, index) => {
      body =
        body +
        "<p><h4>" +
        (index + 1) +
        ") Seller: " +
        z.listing.title +
        " from " +
        z.listing.user.businessname +
        "</h4>";
      body = body + "Item description " + z.listing.details + " <br/>";
      body =
        body +
        "Business address " +
        z.listing.user.buildingaddress +
        ", " +
        z.listing.user.streetaddress +
        ", " +
        z.listing.user.suburb +
        ", " +
        z.listing.user.city +
        ", " +
        z.listing.user.province +
        ", " +
        z.listing.user.areacode +
        ", " +
        " <br/>";
      body = body + "Business phone " + z.listing.user.contactphone + " <br/>";
      body =
        body +
        "Business phone alt " +
        z.listing.user.contactphonealternate +
        " <br/>";
      body = body + "Business email " + z.listing.user.contactemail + " </p>";
        body = body + "  </p>";
    });
    body =
      body +
      "<p><h4>" +
      (listingdetails.length + 1) +
      ") Buyer:  " +
      cartdata.user.businessname +
      "</h4>";

    body =
      body +
      "Business address " +
      cartdata.user.buildingaddress +
      ", " +
      cartdata.user.streetaddress +
      ", " +
      cartdata.user.suburb +
      ", " +
      cartdata.user.city +
      ", " +
      cartdata.user.province +
      ", " +
      cartdata.user.areacode +
      ", " +
      " <br/>";
    body = body + "Business phone " + cartdata.user.contactphone + " <br/>";
    body =
      body +
      "Business phone alt " +
      cartdata.user.contactphonealternate +
      " <br/>";
    body = body + "Business email " + cartdata.user.contactemail + " </p><br/>";
    body = body + "  </p>";
    body =
      body +
      "</p>";

    let mail = await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/plainmailer?to=" +
        to +
        "&subject=" +
        subject +
        "&body=" +
        body,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );




      await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/sendeftinvoice?to=" +
        cartdata.user.contactemail +
        "&subject=" +
        "Scrap Store EFT Invoice ("+text+") " +
        "&ref="+text+
        "&body=" +
        cartid,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );

await mail.text()
    return text;

  },
  async sendShippingInstructionRECEIPT(cartid) {
    var cartdata = await API.graphql(
      graphqlOperation(queries.getOttoPartzTransactions, {
        id: cartid
      })
    );
    cartdata = cartdata.data.getOttoPartzTransactions;
    var listingdata = JSON.parse(cartdata.listings);
    var listingdetails = await Promise.all(
      listingdata.map(Utils.getListingById)
    );
    var to = "info@scrapapp.co.za";
    var subject = "Scrap Store EFT transaction COMPLETE SHIPPING INSTRUCTION ("+cartdata.transactioncode+") ";
    var body = "<h1>Shipping instruction</h1><br/>";

    listingdetails.forEach((z, index) => {
      body =
        body +
        "<p><h4>" +
        (index + 1) +
        ") Instruct courier to pick up: " +
        z.listing.title +
        " from " +
        z.listing.user.businessname +
        "</h4>";
      body = body + "Item description " + z.listing.details + " <br/>";
      body =
        body +
        "Business address " +
        z.listing.user.buildingaddress +
        ", " +
        z.listing.user.streetaddress +
        ", " +
        z.listing.user.suburb +
        ", " +
        z.listing.user.city +
        ", " +
        z.listing.user.province +
        ", " +
        z.listing.user.areacode +
        ", " +
        " <br/>";
      body = body + "Business phone " + z.listing.user.contactphone + " <br/>";
      body =
        body +
        "Business phone alt " +
        z.listing.user.contactphonealternate +
        " <br/>";
      body = body + "Business email " + z.listing.user.contactemail + " </p>";
        body = body + "Send seller waybill  </p>";
    });
    body =
      body +
      "<p><h4>" +
      (listingdetails.length + 1) +
      ") Instruct courier to DELIVER to  " +
      cartdata.user.businessname +
      "</h4>";

    body =
      body +
      "Business address " +
      cartdata.user.buildingaddress +
      ", " +
      cartdata.user.streetaddress +
      ", " +
      cartdata.user.suburb +
      ", " +
      cartdata.user.city +
      ", " +
      cartdata.user.province +
      ", " +
      cartdata.user.areacode +
      ", " +
      " <br/>";
    body = body + "Business phone " + cartdata.user.contactphone + " <br/>";
    body =
      body +
      "Business phone alt " +
      cartdata.user.contactphonealternate +
      " <br/>";
    body = body + "Business email " + cartdata.user.contactemail + " </p><br/>";
    body = body + "Send buyer waybill  </p>";
    body =
      body +
      "After 30 days of delivery to buyer, if there is no return, send seller the reciept(invoice) and payment  </p>";

    let mail = await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/plainmailer?to=" +
        to +
        "&subject=" +
        subject +
        "&body=" +
        body,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );




      await fetch(
      "https://0dubxprk57.execute-api.eu-west-1.amazonaws.com/productionv2/sendreceipt?to=" +
        cartdata.user.contactemail +
        "&subject=" +
        "Scrap Store EFT Receipt " +
        "&body=" +
        cartid,
      {
        headers: { "Content-Type": "application/json" },
        crossDomain: true
      }
    );


    return await mail.text();

  },
  async genericMutation(table, input) {
    return await API.graphql(
      graphqlOperation(mutations[table], {
        input,
      })
    );
  },
  async genericGet(table, id) {
    return await API.graphql(
      graphqlOperation(queries[table], {
        id,
      })
    );
  },
  async genericGetMOD(table, id,MOD) {
    return await API.graphql(
      graphqlOperation(queries[table].replace(MOD.before,MOD.after), {
        id,
      })
    );
  },
  async genericList(table, filter) {
    try {
      return await API.graphql(graphqlOperation(queries[table], filter));
    } catch (err) {
      console.log(err);
    }
  },
  async moveCartIntoHistory(cartid, total, code) {

    try {
      var invoice = await API.graphql(
        graphqlOperation(queries.getOttoPartzInvoiceCounter, {
          id: "counter"
        })
      );
      invoice = invoice.data.getOttoPartzInvoiceCounter.number;
      var newinvoice = invoice + 1;
      // console.log(invoice);
      // console.log(newinvoice);
 await API.graphql(
        graphqlOperation(mutations.updateOttoPartzInvoiceCounter, {
          input: {
            id: "counter",
            number: newinvoice
          }
        })
      );
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: cartid,
            transactiondate: new Date(Date.now()),
            status: "complete",
              invoiceNumber: invoice,
            total: total,
            transactioncode: code,
            transactionstatus: "complete"
          }
        })
      );
      // console.log(makeDynamo)
        await this.sendShippingInstruction(cartid)
      await this.setItemsToUnavailable(
        makeDynamo.data.updateOttoPartzTransactions.listings
      );

      return makeDynamo.data.updateOttoPartzTransactions;
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async moveCartIntoHistoryINVOICE(cartid, total) {

    try {
      var invoice = await API.graphql(
        graphqlOperation(queries.getOttoPartzInvoiceCounter, {
          id: "counter"
        })
      );
      let oginvoicenumber = JSON.parse(JSON.stringify(invoice.data.getOttoPartzInvoiceCounter.number))

      invoice = invoice.data.getOttoPartzInvoiceCounter.number;
      var newinvoice = invoice + 1;
      // console.log(invoice);
      // console.log(newinvoice);
 await API.graphql(
        graphqlOperation(mutations.updateOttoPartzInvoiceCounter, {
          input: {
            id: "counter",
            number: newinvoice
          }
        })
      );
      await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: cartid,
            transactiondate: new Date(Date.now()),

            status: "processing",
            invoiceNumber: oginvoicenumber,
            total: total,
            transactionstatus: "incomplete"
          }
        })
      );
      let text = await this.sendShippingInstructionINVOICE(cartid)
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: cartid,
            transactiondate: new Date(Date.now()),
            transactioncode:text,
            status: "processing",
            invoiceNumber: oginvoicenumber,
            total: total,
            transactionstatus: "incomplete"
          }
        })
      );
      // console.log(makeDynamo)
      // await this.setItemsToUnavailable(
      //   makeDynamo.data.updateOttoPartzTransactions.listings
      // );

      return makeDynamo.data.updateOttoPartzTransactions;
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async failCartIntoHistory(cartid, code) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(mutations.updateOttoPartzTransactions, {
          input: {
            id: cartid,
            transactioncode: code,
            transactionstatus: "fail"
          }
        })
      );
      // console.log(makeDynamo)
      return makeDynamo.data.updateOttoPartzTransactions;
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  },
  async getProfilePictureForListing(input) {
    const getProfilePictureForListingQRY = `query GetListing($id: ID!) {
      getListing(id: $id) {
        user {
          profilepic
          businessname
        }
      }
    }
    `;
    let result = await API.graphql(
      graphqlOperation(getProfilePictureForListingQRY, {
        id: input
      })
    );
    // console.log("FUNC")
    // console.log(input)
    //console.log(result)
    // console.log(typeof result.data.getListing.user.profilepic)
    // console.log("FUNC")
    var filename = "defaultpic.png";
    if (result.data.getListing.user.profilepic != null)
      filename = result.data.getListing.user.profilepic;
    var businessname = "";
    if (result.data.getListing.user.businessname != null)
      businessname = result.data.getListing.user.businessname;
    return { input, filename, businessname };
  },
  async getListingByIdNoPics(cognito) {
    try {
      const makeDynamo = await API.graphql(
        graphqlOperation(queries.getOttoPartzListing, {
          id: cognito
        })
      );

      return { success: true, listing: makeDynamo.data.getOttoPartzListing };
    } catch (err) {
      return { success: false, message: err.message };
    }
  },
  async listTransactions(nextToken) {
    const makeDynamo = await API.graphql(
      graphqlOperation(queries.listOttoPartzTransactionss, {
        limit: 10,
        nextToken
      })
    );

    return makeDynamo.data.listOttoPartzTransactionss;
  },
  generateName() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 100; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },
  log(input) {
    if (process.env.NODE_ENV === "development") console.log(input);
  },
  formatDate(input) {
    var d1 = new Date(input);
    var d2 = new Date(Date.now());
    var t1 = d1.getTime();
    var t2 = d2.getTime();
    var output = parseInt((t1 - t2) / (24 * 3600 * 1000));
    output = output + 1;
    if (output < 0) output = "Expired";
    else output += " days left";
    return output;
  },
  formatTextToCurrency(input) {
    const n = String(input),
      p = n.indexOf(".");
    return (
      "R " +
      n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
        p < 0 || i < p ? `${m} ` : m
      )
    );
  },
  removeDuplicates(input) {
    input.forEach(function(i) {
      i = i.trim();
    });
    var output = [];
    if (input.length > 0) {
      let unique = {};
      input.forEach(function(i) {
        if (!unique[i]) {
          unique[i] = true;
        }
      });
      Object.keys(unique).forEach(function(e) {
        if (e !== "") output.push(e);
      });
    }
    return output;
  },
  matcher(mainlist, listinglist) {
    var additionallist = [];
    for (var a = 0; a < listinglist.length; a++) {
      listinglist[a] = listinglist[a].trim().toLowerCase();
      for (var i = 0; i < mainlist.length; i++) {
        var keyterm = mainlist[i];
        var keyarr = keyterm[Object.keys(keyterm)[0]];
        for (var j = 0; j < keyarr.length; j++) {
          if (keyarr[j] === listinglist[a]) {
            additionallist.push(Object.keys(mainlist[i])[0]);
          }
        }
      }
    }
    return listinglist.concat(additionallist);
  },
  formattedCarsList() {
    const s = this.carslist();
    const output = [];
    s.forEach(z => {
      const d = z.split(":");

      output.push({ make: d[0], model: d[1].split(",") });
    });
    return output;
  },
  pureCarMakesList() {
    return [
      "ABARTH",
      "AC",
      "ACO",
      "AGCO ALLIS (AGROTEC)",
      "AGRIA-DEUTZ",
      "AGRIA",
      "AGRICO",
      "ALFA ROMEO",
      "AMBIDEX",
      "AMC",
      "ANGLO INTERNATIONAL",
      "APRILIA",
      "ARORA",
      "ASHOK LEYLAND",
      "ASIA WING",
      "ASTON MARTIN",
      "ATUL",
      "AUDI",
      "B.A.W",
      "BACKDRAFT",
      "BAIC",
      "BAJAJ",
      "BELARUS",
      "BELL",
      "BENTLEY",
      "BETA RACING",
      "BIG BOY",
      "BIRKIN",
      "BLACKWOOD HODGE",
      "BMW",
      "BOMBARDIERCANAM",
      "BUYANG",
      "C.A.M",
      "CADILLAC",
      "CASE INTERNATIONAL",
      "CATERPILLAR",
      "CHALLENGER",
      "CHANA - CHANGAN",
      "CHERY",
      "CHEVROLET",
      "CHRYSLER",
      "CITROEN",
      "CLAAS",
      "CMC",
      "CNHTC",
      "COLT",
      "CSR",
      "D A F",
      "DACIA",
      "DAEWOO",
      "DAIHATSU",
      "DAIMLER",
      "DATSUN",
      "DEUTZ FAHR",
      "DFM",
      "DFSK",
      "DMA",
      "DMC",
      "DODGE",
      "DONGBEN",
      "DTV",
      "DUCATI",
      "E R F",
      "ELECTRIC MOTION",
      "FARMTRAC",
      "FAW",
      "FERRARI",
      "FIAT",
      "FIRMACO",
      "FORCE",
      "FORD",
      "FOTON",
      "FREIGHTLINER",
      "FUDI",
      "FUSO",
      "GAZ",
      "GEELY",
      "GOLDEN DRAGON",
      "GOLDEN JOURNEY",
      "GOMOTO",
      "GONOW",
      "GWM",
      "HAFEI",
      "HAISE",
      "HAJADU",
      "HARLEY DAVIDSON",
      "HAVAL",
      "HINO",
      "HONDA",
      "HUMMER",
      "HUNTER",
      "HUSQVARNA",
      "HYUNDAI",
      "INDIAN",
      "INDOTRAC",
      "INFINITI",
      "INTERNATIONAL",
      "ISUZU",
      "IVECO",
      "JAC",
      "JAGUAR",
      "JEEP",
      "JIALING",
      "JIANDA TRADING",
      "JINBEI",
      "JINMA",
      "JMC",
      "JOHN DEERE",
      "KAWASAKI",
      "KIA",
      "KIOTI",
      "KTM",
      "KUBOTA",
      "L D V",
      "LADA",
      "LAMBORGHINI",
      "LANCIA",
      "LAND ROVER",
      "LANDINI",
      "LANTANA",
      "LEXUS",
      "LEYLAND",
      "LIFAN",
      "LOTUS",
      "M A N",
      "MACK",
      "MAHINDRA",
      "MASERATI",
      "MASSEY FERGUSON",
      "MAYBACH",
      "MAZDA",
      "McCORMICK",
      "MCLAREN",
      "MEIYA",
      "MERCEDES-BENZ",
      "MG",
      "MINI",
      "MITSUBISHI",
      "MONDIAL",
      "MORGAN",
      "MOTO GUZZI",
      "MV AGUSTA",
      "NANFENG",
      "NBLUCK",
      "NEW HOLLAND",
      "NISSAN",
      "NOBLE",
      "OPEL",
      "PETERBILT",
      "PEUGEOT",
      "PGO",
      "POLARIS",
      "POLARSUN",
      "PORSCHE",
      "POWERLAND",
      "POWERSTAR",
      "PROTON",
      "PUZEY",
      "RAIDER",
      "RENAULT",
      "ROLLS ROYCE",
      "ROVER",
      "ROYAL ENFIELD",
      "S.A.M.E.",
      "SAAB",
      "SAIC",
      "SAMAG",
      "SCANIA",
      "SEAT",
      "SECMA",
      "SHINERAY",
      "SMART",
      "SONALIKA",
      "SOYAT",
      "SPECIALITY",
      "SSANGYONG",
      "STUD",
      "SUBARU",
      "SUZUKI",
      "TAFE",
      "TATA",
      "TGB",
      "TOYOTA",
      "TRIUMPH",
      "TVR",
      "UD TRUCKS",
      "URSUS",
      "US TRUCK",
      "VALTRA (VALMET)",
      "VESPA",
      "VICTORY",
      "VOLKSWAGEN",
      "VOLVO",
      "WARRIOR",
      "WESTERN STAR",
      "WHITE OLIVER",
      "YAMAHA",
      "YANMAR",
      "YTO",
      "ZAHOW",
      "ZERO",
      "ZOTYE",
      "ZX AUTO"
    ];
  },
  carslist() {
    return [
      "ABARTH:124,500/695,PUNTO SCORPIONE",
      "AC:BACKDRAFT ROADSTER",
      "ACO:LAZER SILVER 1 - 15,Model5893",
      "AGCO ALLIS (AGROTEC):6,AX 5,AX4 5.1",
      "AGRIA-DEUTZ:Model5896,Model5898,Model5900",
      "AGRIA:Model5903,Model5908,Model5909,Model5910,Model5911,Model5914,Model5915",
      "AGRICO:2 PLUS,4 PLUS,DEUTZ FAHR",
      "ALFA ROMEO:33,147,155,159,164,166,145 / 146,156 / GT,ALFA 33,ALFASUD / EXPORT,ALFETTA / SUPER 2000,BRERA/4C,GIULIA,GIULIETTA,GIULIETTA / 159,GTV / ALFETTA 2000 GTV,MiTO,SPIDER / GTV,STELVIO",
      "AMBIDEX:Model5972",
      "AMC:494 / 594 / 694 / 894 / 1594 / 3594,894 EVOLUTION 5 SPEED F/C C/C,AMC BUS,AMC T/T",
      "ANGLO INTERNATIONAL:Model5961,Model5966,Model5969",
      "APRILIA:125,1000",
      "ARORA:XL",
      "ASHOK LEYLAND:1518,AL,CARGO",
      "ASIA WING:AW,CH1012L",
      "ASTON MARTIN:DB 7 / 9,RAPIDE,VANQUISH,VANTAGE,VIRAGE,VOLANTE",
      "ATUL:GEM,SMART",
      "AUDI:100,200,400,500,A1,A3 1998 - 2004,A3 2004 - ON,A4 (B7) 2005 - 2010,A4 (B8) 2008 - ON (B9),A4 1995 - 1999,A4 1999 - 2005,A5,A6 1994 - 2004,A6 2004 - ON,A7,A8 2004 - ON,Q2,Q3,Q5,Q7,R8,TT,TURBO/CABRIOLET/S2/S4/S6/S8/A8",
      "B.A.W:INYATHI,SASUKA",
      "BACKDRAFT:BACKDRAFT ROADSTER",
      "BAIC:D20,X25",
      "BAJAJ:150,125 - 140,QUTE",
      "BELARUS:Model5974,Model5999",
      "BELL:Model6002,Model6005",
      "BENTLEY:ARNAGE / AZURE,BENTAYGA,BROOKLANDS,CONTINENTAL,EIGHT,MULSANNE,TURBO R",
      "BETA RACING:125,RR 250,RR 350/390/430,RR 450",
      "BIG BOY:200,550",
      "BIRKIN:S3 ROADSTER",
      "BLACKWOOD HODGE:Model6007,Model6009,Model6010",
      "BMW:1 SERIES E81 / 87/ F20/F21,2 SERIES,2 SERIES ACTIVE TOURER (F45),3 SERIES (F30),3 SERIES E30 / 36 / 46,3 SERIES E46 2000 - 2005,3 SERIES E90 / 91 / 92 / 93,3 SERIES GT (F34),4 SERIES,4 SERIES GRAN COUPE,5 SERIES E34 / E39,5 SERIES E60 / GT,5 SERIES E8 / 12 / 28,5 SERIES F10,6 SERIES E24 / E63 / E64/ F12/ F13,7 SERIES (750 iL) 1988 - 2002,7 SERIES (G11),7 SERIES E23 / 32 / 38,7 SERIES E65 / F01,8 SERIES E31,C / C1,i3/i8,M COUPE / Z3 / Z4,R 100 / S 1000 / R1100-1200,X1,X2,X3,X3 (G01),X4,X5 E53 / E70,X5 E70 2010 - ON,X6",
      "BOMBARDIERCANAM:90,1000 MAVERICK,500 TRAXTER / RENEGADE / OUTLANDER,650 DS / TRAXTER / OUTLANDER,800-1000 DEFENDER,800-1000 OUTLANDER / RENEGADE / COMMANDER,900 MAVERICK,SPYDER",
      "BUYANG:SANDSTORM",
      "C.A.M:INYATHI,LEO,RHINO",
      "CADILLAC:BLS,CTS,STS / SRX",
      "CASE INTERNATIONAL:4,5,7,2 3 MX,2130 AVJ,4 7,4 9 STX,4 C MX 5,5 & 6,7 & 8 VJ,7 8 MX 4,FARMALL,JX 2 PJN,JX JXN,JX JXN JXT,PUMA AND MAXXUM,QUANTUM",
      "CATERPILLAR:Model6034",
      "CHALLENGER:200-400",
      "CHANA - CHANGAN:BENNI,KINGLONG,STAR,STAR 3,STAR RAINBOW",
      "CHERY:J1,J2,J3,J5,P-10,QQ3,RD,TIGGO",
      "CHEVROLET:AVEO,BLAZER,CAPTIVA,CHEVAIR,COMMODORE,CORSA/UTILITY,CRUZE,LUMINA,OPTRA,ORLANDO,REKORD,SENATOR,SONIC,SPARK,TRAILBLAZER,VIVANT",
      "CHRYSLER:300C,CROSSFIRE,NEON 1996 - 1999,NEON 2000 - 2007,PT CRUISER,SEBRING,VOYAGER 1997 - 2001,VOYAGER 2001 - ON",
      "CITROEN:BERLINGO I / II,BERLINGO II,C1,C2,C3,C4,C5,DISPATCH,PICASSO / C4 PICASSO,RELAY,XSARA 2001 - 2005",
      "CLAAS:5 6 L,ARES/ALTES/AXOS,ARION/AXION,CERES/CELTIS,TALOS,XERION",
      "CMC:AMANDLA,CYRUS,LIFAN,PLUTUS,QUANTIS / SESBUYILE,SAHRA,TIGO,VISTA",
      "CNHTC:SINOTRUK HOWO-7,SINOTRUK HOWO-V7",
      "COLT:COLT 1600 - 2400,COLT 2500,COLT 2600,COLT 2800,COLT 3000",
      "CSR:125,150",
      "D A F:FA,FA / FAS LF45-55,FA 45,FAT / FT CF75,FT / FTT CF85,FTS / FTT / FAT 85-95,FTT XF",
      "DACIA:CRISTAL,CRISTAL / ELAN",
      "DAEWOO:CIELO,ESPERO,LANOS I,LANOS II,MATIZ,NUBIRA,NUBIRA II,TACUMA",
      "DAIHATSU:2.0 / 3.5 / 4.0 TON,CHARADE,CHARADE 2003 - ON,COPEN,CUORE,GRAN MAX,MATERIA,MOVE,MOVE / GRAN MOVE,SIRION,TERIOS / ROCKY / FEROZA,YRV",
      "DAIMLER:3.6 / 4.0,4.0 V8,DOUBLE SIX",
      "DATSUN:GO,GO +",
      "DEUTZ FAHR:AGROFARM/AGROTRAC,AGROKID / AGROLUX / AGROTRON,Model6166,Model6185,Model6187,Model6194,Model6197,Model6203,Model6209,Model6222",
      "DFM:DFM 1063,MINI TRUCK 1.0,MINI TRUCK 1.3,PANEL VAN",
      "DFSK:DFSK",
      "DMA:2.5 TON,4 TON,YC 1050",
      "DMC:DAMARA",
      "DODGE:CALIBER,JOURNEY,NITRO,STEALTH",
      "DONGBEN:200",
      "DTV:ROADRUNNER",
      "DUCATI:1100 / 1200,SCRAMBLER",
      "E R F:EC",
      "ELECTRIC MOTION:SPORT",
      "FARMTRAC:7,FT",
      "FAW:10 SERIES,11 SERIES,12 SERIES,13 SERIES,15 SERIES,16 SERIES,18 SERIES,2 TON,22 SERIES,26 SERIES,28 SERIES,33 SERIES,35 SERIES,7 SERIES,8 SERIES,9 SERIES,SIRIUS,V2",
      "FERRARI:308 / 328,348 / 355 / 360,400 / 430 / 456 / 458,MARANELLO / GTB / CALIFORNIA,MONDIAL / DINO,TESTAROSSA / 512",
      "FIAT:128,500,500X,80/66,BRAVO,CROMA,DOBLO,DOBLO/FIORINO,DUCATO,FIRE / CENTO / RIO,GRANDE PUNTO,LINEA,MIA / TEMPO 1998 - 2010,Model6242,Model6251,Model6283,Model6299,Model6312,Model6320,Model6329,Model6340,MULTIPLA/QUBO,PACER / TURBO / BEAT,PALIO I / II and SIENA I / II,PALIO II,PANDA,PUNTO,SCUDO,SEICENTO,STILO,STRADA / FULLBACK,TIPO,ULYSSE,UNO",
      "FIRMACO:POWERSTAR 17 / 19 /26,POWERSTAR 30 / 33,POWERSTAR 40",
      "FORCE:TRAVELLER",
      "FORD:5640,6000 SERIES,B-MAX,BANTAM 2002 - ON,CARGO,CORTINA,CORTINA BAKKIE,COURIER,D SERIES,ECOSPORT,ESCORT,ESCORT / BANTAM 1983 - 2002,ESCORT 1995 - 1999,EXPLORER,F250 2005 - 2010,FALCON / FUTURA / FAIRMONT,FIESTA 1997 - 2000,FIESTA 2000 - ON,FIGO,FOCUS 2000 - ON,FUSION,GRANADA,HUSKEY / SPECTRON,HUSKY,IKON,KA,KUGA,LASER 1986 - 2002,LNT,METEOR 1986 - 1995,Model6355,Model6360,Model6381,Model6399,Model6415,Model6422,Model6424,Model6433,MONDEO 1997 - 2006,MUSTANG,RANCHERO,RANGER 2000 - 2007,RANGER 2007 - ON,SAPPHIRE 1989 - 1993,SIERRA 1983 - 1993,TELSTAR / TX5,TERRITORY / EVEREST,TOURNEO,TOURNEO CONNECT,TRAC 6640,TRANSIT,TRANSIT / SPECTRON,TRITON",
      "FOTON:BJ,FT,INKUNZI / VIEW,THUNDA,TUNLAND,VIEW",
      "FREIGHTLINER:ARGOSY,COLUMBIA,FL,FLB",
      "FUDI:LION",
      "FUSO:CANTER,F-SERIES",
      "GAZ:GAZELLE",
      "GEELY:CK,EMGRAND,LC,MK",
      "GOLDEN DRAGON:IKUMBI",
      "GOLDEN JOURNEY:IBHUBEZI,MAKOYA",
      "GOMOTO:125,150,170,200",
      "GONOW:GX6,NGUNI,X-SPACE",
      "GWM:C30,C50,FLORID / CB,HOVER / H5/ H6,LDV / STEED,M4,MULTIWAGON,STEED 5E / 6",
      "HAFEI:LOBO,LODA",
      "HAISE:SIYAVAYA",
      "HAJADU:500,800,1100,200/250,400/450,GS MOON",
      "HARLEY DAVIDSON:CVO,SOFTAIL,TRIKE",
      "HAVAL:H1,H2,H6",
      "HINO:20/28 / 700 SERIES,23 - 41 / 700 SERIES,26 - 57 / 700 SERIES,300 SERIES,39 - 42 SERIES,500 SERIES,9 - 17 / 25 / 500 SERIES,KB / KL / KR,PROFIA",
      "HONDA:1000 / 1100 / 1200 / 1300 / 1500,ACCORD,BALLADE 180 1994 - 2000,BALLADE 1984 - 1987,BALLADE 1987 - 1992,BALLADE 1992 - 2000,BALLADE 2011 - ON,BRIO,CIVIC 1996 - 2000,CIVIC 2001 - 2006,CIVIC 2006 - ON,CIVIC 5Dr 2006 - ON,CRV 2015 ON,CRX / CR-Z,FR-V,HR-V,INSIGHT,JAZZ,MOBILIO/ BR-V,ODYSSEY / CRV / HRV,PRELUDE,S 2000",
      "HUMMER:H2,H3",
      "HUNTER:200",
      "HUSQVARNA:250,900,300 / 350,610/650/701",
      "HYUNDAI:ACCENT 1994 - 2000,ACCENT 2000 - ON,ATOZ,AZERA,CRETA,ELANTRA 1993 - 2001,ELANTRA 2001 - ON,GETZ,H-1,H100 / BAKKIE,HD65/72 - EX-8,i10 /  i20 / i30,iX35,MATRIX,MINIBUS,SANTE-FE,SCOUPE,SONATA,TERRACAN,TIBURON/VELOSTER,TRAJET,TUCSON",
      "INDIAN:1800",
      "INDOTRAC:TS",
      "INFINITI:EX/QX50,FX/QX70,G/Q60,M/Q70,Q50,QX80",
      "INTERNATIONAL:8200,9100,9200,4700 - 4900,7400 / 7600,9300 / 9400,9400 / 9670 /  9700 / 9800,PAYSTAR,S / T / TF / SF 2650,TRANSTAR,WORKSTAR",
      "ISUZU:70 SERIES,DHK / DHR,EXR / CXZ,EXZ / CVR /  CXZ,F / GVR SERIES,FRONTIER / RELAY,FSR,FTR,FXR / FXZ / GXR / GXZ,JCR,KB 1989 - 1997,KB 1997 - 2004,KB 2004 - ON,KB 320 1998 - 2004,KB PRE 89,N / NPR / NPS  / NKR / NQR,NHR / NLR / NMR / NKR,SBR,SHR / SHZ / VHZ,SPZ,TLD,TLG,TROOPER,TXD,VPZ,WFR / RELAY / ELFIN",
      "IVECO:682,BIG DAILY 1993 - ON,CARGO,DAILY 1995 - 2007,DAILY 2007 - ON,EUROCARGO 1995 - 2001,EUROCARGO 2000 - ON,EUROTECH,EUROTRAKKER,EUROTRAKKER 2002 - ON,STRALIS,TURBO STAR",
      "JAC:T6,X200",
      "JAGUAR:E-PACE,F-PACE,F-TYPE,S-TYPE,X-TYPE,XE,XF,XJ / XJ8 / XJR,XJ6 / XJS,XK / XK8 / XKR",
      "JEEP:CHEROKEE,COMMANDER,COMPASS,GRAND CHEROKEE 1996 - 2005,GRAND CHEROKEE 2005 - ON,PATRIOT,RENEGADE,WRANGLER",
      "JIALING:50,100",
      "JIANDA TRADING:Model6512",
      "JINBEI:HAISE",
      "JINMA:JM",
      "JMC:BD / BOARDING,CARRYING,CARRYING 3 TON,LANDWIND,VIGUS",
      "JOHN DEERE:1000,3000,4000,5000,6000,7000,8000,9000,1000 2000,5000 E,5325/5425",
      "KAWASAKI:1000",
      "KIA:CARENS,CARNIVAL,CARNIVAL II / SEDONA,CERES,CLARUS,K 2500,K 2700,MAGENTIS,OPTIMA,PICANTO,PREGIO,PREGIO BUS,PRIDE,PROCEED,RIO,SEPHIA,SHUMA,SORENTO,SOUL,SPECTRA / CERATO,SPORTAGE",
      "KIOTI:CS,DK / NX",
      "KTM:500,1300,125 - 390,X-BOW",
      "KUBOTA:B 1-6,L2,L3 L4 STV,ME,ST,T / ZG / ZD / F",
      "L D V:CONVOY,VH / VS / VX",
      "LADA:NIVA/ SIDEKICK",
      "LAMBORGHINI:AVENTADOR,COUNTACH,CRONO,CRONO 7,DIABLO,GALLARDO,HURACAN,JALPA,MURCIELAGO,PREMIUM,R,ROW",
      "LANCIA:DELTA,NUOVA THEMA,THEMA",
      "LAND ROVER:109,DEFENDER / PUMA / PUP,DEFENDER / PUMA 110,DEFENDER / PUMA 90,DISCOVERY 1996 - 1999,DISCOVERY 1999 - 2005,DISCOVERY 3 / 4,DISCOVERY PRE 96,DISCOVERY SPORT,EVOQUE,FREELANDER 1 / 2,PUP V8 PICK-UPS,RANGE ROVER & CUP,RANGE ROVER 2002 - ON,VELAR",
      "LANDINI:10 / SUPER 100-110,2000-4000 / SOLIS 20,5 - 7 DT,7 / ATLANTIS / SUPER,9 ATLANTIS / SOLIS 75-90 / SUPER 90,GLOBALFARM / MULTIFARM,LANDPOWER/ POWER/ LANDFORCE,LEGEND 1 DT,POWERFARM,R 6,R 8 ATLANTIS REX,R5 / SOLIS 50 / SOLIS 60,REX,TECHNOFARM",
      "LANTANA:LANTANA",
      "LEXUS:CT,ES,GS 300 1998 - 2006,GS 300 2006 - ON,IS  1999 - 2006,IS/RC-F 2006 - ON,LFA,LS / SC / LX,NX,RX 300 / 350",
      "LEYLAND:24 / 26 / 33 SERIES,APACHE / CHIEFTAIN,ELAND,MANDATOR / MAMMOUTH,TERRIER / BOXER",
      "LIFAN:200",
      "LOTUS:ELISE,ESPIRIT,ESPRIT,EUROPA,EVORA,EXCEL,EXIGE",
      "M A N:10 / 12 / 14 SERIES 1985 - 2006,10 / 12 SERIES 2006 - ON,15 SERIES 1988 - ON,15 TGM 20 SERIES 2006 - ON,16 / 17 SERIES 1977 - 2001,18 / 19 SERIES 1985 - 2001,18 SERIES 2002 - 2006,25 / 26 SERIES 2006 - 0N,25 SERIES 2000 - 2006,26 SERIES 1990 - ON,27 SERIES 1999 - ON,30 / 33 SERIES 1988 - 2004,30 / 33 SERIES 2002 - 2004,30 SERIES 1978 - 1990,40 SERIES 2000,TGA / TGS 33 2004 - ON,TGA / TGS 34 2006 - ON,TGA 18 SERIES 2004 - ON,TGA/TGS 26/27/28 2004 - ON,TGM 18 SERIES 2006 - ON,TGX 26 2016 - ON",
      "MACK:CH,QUANTUM,R,RD",
      "MAHINDRA:575,BOLERO,CL 340 / 540,GENIO,KUV 100,QUANTO,SCORPIO,SCORPIO / PIK UP,THAR,TUV,XUV 500,XYLO",
      "MASERATI:3200 GT,BI-TURBO / 430,GHIBLI,GRANTURISMO,KARIF / 222,LEVANTE,QUATTROPORTE,SPYDER / 228 / 425",
      "MASSEY FERGUSON:290,35 240,9-Jun,MF 1010 - 1805,MF 220 - 298,MF 2640 - 3660,MF 354 - 399,MF 415 - 475 / 4235 - 4270,MF 5340 - 5710",
      "MAYBACH:57 / 62",
      "MAZDA:2,3,5,6,323 2I,323 FWD,323 RWD,626 1983 - 1993,626 1993 - 2003,ASTINA,B 1600 1971 - 1991,B 1800 1991 - 2003,B 2000 1982 - 2003,B 2200 1983 - 2000,B 2500 / 2600 / BT-50,B 3000 / 3400 1986 - 2000,CX-3,CX-5/CX-7,E 3000 / T 3000,ETUDE,MARATHON,MX-6 / MX5,RUSTLER,RX 7 / 8,SOHO",
      "McCORMICK:B-MAX,C-MAX / D-MAX,F,G-MAX,MB,MC/MTX,XTX/ZTX",
      "MCLAREN:MP4-12C/650S",
      "MEIYA:PICKUP,SUV / MINIBUS",
      "MERCEDES-BENZ:10 / 11 /  12 / 13 / 14 SERIES,14 / 16 SERIES,14 SERIES 1980 - 1992,1418 / 1419 SERIES PRE 2001,15 SERIES,1626 / 1928 / 1933,17 / 18 / 19 SERIES,19 SERIES 1981 - 1992,190 / C180,20 / 22 SERIES,200 W123,2224 / 2225 1986 - 1993,2233 / 24 /25,230 E / C / TE,260 E,2624 1981 - 1988,2628 / 2629 1980 - ON,2628 / 2635-8 /2640-3-4-8 / 2650-4-8,2629 / 2631 / 2632 / 2633,280 C / E / S / SE,300 D,300 E / S 320 / E 320,33 / 35 SERIES,38 / 40 / 41 SERIES,380 SE,420 / 500 SE / S / SL,508 / 608 / 609 / 613 / 616,560 / 600,709 / 800 / 811 / 812 / 817 / 912 / 918,A CLASS,B CLASS,C / E 220 / 230,C 240 / 250 TD,C CLASS (2014),C CLASS 2000 - 2007,C CLASS 2007 - 2014,C/E 200,CL 2000 - ON,CLA,CLC,CLK,E CLASS 1996 - 2002,E CLASS 2002 - ON / CLS,GLA,GLC,GLE,R CLASS,S CLASS 2006 - ON,SL 500 / SLS,SLK 1997 - 2000,SLK 2000 - ON,SPRINTER,UNIMOG,VITO,VITO / VIANO / ML / GL,X-CLASS",
      "MG:MG ZR,MG ZT,MG3,MG6,MGF / TF",
      "MINI:COOPER,COOPER 5DR,COOPER CLUBMAN,COOPER CONVERTIBLE,COOPER COUNTRYMAN,COOPER COUPE,COOPER PACEMAN,COOPER ROADSTER,MINI ONE",
      "MITSUBISHI:ASX,CANTER 1983 - 2001,COLT,FK,FUSO FG / CANTER 2004 - 2012,FUSO FK-FM-FN-FP-FV,GALLANT,L 300 1983 - 1991,L 300 1988 - 2002,L300,LANCER,MIRAGE,Model7078,Model7081,OUTLANDER,PAJERO,TREDIA,TRITON",
      "MONDIAL:125,300",
      "MORGAN:MORGAN CONVERTIBLES",
      "MOTO GUZZI:1200-1400",
      "MV AGUSTA:BRUTALE,F3,F4,STRADALE / TURISMO",
      "NANFENG:EKHAYA",
      "NBLUCK:250,500,600,800",
      "NEW HOLLAND:56,60,80,NUMBER 1,T,T 3.55 - 9.6,TD,TG,TJ,TN,TN TNA,TNN,TNS,TS,TS TB,TS TM,TT TN",
      "NISSAN:1 TONNER 1988 - 1995,1 TONNER V6 1990 - 1995,1400/NP200,200 SX / 280 / 300 ZX,350 Z / 370 Z,620 / 680 / 720 / 1800 / 2300,ALMERA,CABALL,CABSTAR / UD35 / 40,CK,CK / CM / UD / CW,CK / CW 290 / UD 290,CM 8 F F/C C/C,CW 40 / 45 / 55,CW 46 / 47 / 56 / 350,DU / UG,E 20,EKONOVAN,GT-R,HARDBODY 2002 - ON/NP300,INFINITI,INTERSTAR,JUKE,LANGLEY,LAUREL,LEAF,LIVINA / GRAND  LIVINA,MAXIMA,MICRA,MURANO,NAVARA,NV,PATHFINDER,PATROL,PATROL / SAFARI,PATROL / TERRANO,PRIMASTAR/ NV 200/NV350,PRIMERA,PULSAR,QASHQAI,SABRE,SAFARI,SANI / HARDBODY 1995 - 2002,SANI 1988 - 1995,SENTRA 1.8,SENTRA 1992 - 2002,SENTRA 2013,SENTRA PRE 92,SKYLINE / PATROL,STANZA,TIIDA,UD 55 - 100,X TRAIL",
      "NOBLE:M SERIES",
      "OPEL:ADAM,ASCONA,ASTRA 1993 - 1999,ASTRA 1999 - 2005,ASTRA 2004 - ON,ASTRA 2016,CALIBRA,COMBO,COMMODORE,CORSA,CORSA 1996 - 2000,CORSA 2002 - ON,CORSA UTILITY 1997 - 2000,CORSA UTILITY 2000 - 2010,CROSSLAND X,KADETT 1990 - 1999,KADETT PRE 90,MERIVA,MOKKA,MONZA 1990 - 1993,MONZA PRE 90,MOVANO,OMEGA,REKORD 1986 - 1990,REKORD 1990 - 1995,REKORD PRE 86,SENATOR,TIGRA,VIVARO,ZAFIRA",
      "PETERBILT:377,320 / 362",
      "PEUGEOT:107,206,207,208,305,306,307,308,407,504,505,508,605,607,1007,2008,3008,4008,5008,405 / 406,806 / 807,BOXER,EXPERT,PARTNER,PARTNER / EXPERT,RCZ",
      "PGO:BUG RACER",
      "POLARIS:900/1000,RANGER",
      "POLARSUN:INGWE",
      "PORSCHE:911,928,944,968,911 (933),911 (996)(997),BOXSTER,CAYENNE,CAYMAN,MACAN,PANAMERA",
      "POWERLAND:30 SERIES",
      "POWERSTAR:16 SERIES,17 SERIES,26 SERIES,33 SERIES,40 SERIES,FT",
      "PROTON:ARENA,GEN 2,PERSONA,SAGA,SATRIA,SAVVY",
      "PUZEY:200,300",
      "RAIDER:250",
      "RENAULT:5,9,11,1400 TS,CAPTUR,CLIO,DUSTER,ESPACE,FLUENCE,KADJAR,KANGOO,KOLEOS,KWID,LAGUNA I 1996 - 2001,LAGUNA II 2001 - ON,LOGAN,MAJOR / MAGNUM / KERAX,MEGANE C/P,MEGANE I / II 2001 - 2009,MEGANE I 1997 - 2001,MEGANE III/IV 2009 - ON,MIDLUM,Model7200,Model7205,Model7206,Model7209,Model7211,MODUS,PREMIUM,R5 VAN,SAFRANE,SANDERO,SCENIC I / II 2001 - 2009,SCENIC I 2000 - 2001,SCENIC III 2009 - ON,TRAFIC,TWINGO,VEL SATIS",
      "ROLLS ROYCE:WRAITH",
      "ROVER:75,2600,3500,STREETWISE,VAN DEN PLAS",
      "ROYAL ENFIELD:500",
      "S.A.M.E.:16,30 - 55 ARGON/FALCON,35 - 45 DELFINO/SOLARIS,50 - 75 ARGON/VIGNERON,60 - 95 FRUETTO/FRUTTETO/EXPLORER,60 TIGER/MINOTAURUS,75 DORADO/TIERBOSKAT,80 - 100 TIGER,85 FRUETTO / LASER / MERCURY,90 - 95 EXPLORER / SILVER / EXPLORER III,90 FRUTTETO II / FRUTTETO III,IRON,LAZER SILVER 100 - 170,TITAN",
      "SAAB:9000,9 - 3 / 9 - 5 1995 - 2004,9 - 5 2001 - 2010,9-3 2002 - 2010",
      "SAIC:MAXUS",
      "SAMAG:168,190,480,120 / 240,280 / 330 /360,70 L,SAMIL",
      "SCANIA:LBT / P93 / P94,P / R / G SERIES 2002 - ON,P124 / R124 1997 - 2008,P82 / P94,R 112 / R 113,R 142 - 144 1984 - 2002,R164 2002 - 2008",
      "SEAT:ALTEA,IBIZA,LEON",
      "SECMA:F16",
      "SHINERAY:400",
      "SMART:CITY,COUPE / CABRIO/FORTWO,FORFOUR,ROADSTER",
      "SONALIKA:DI",
      "SOYAT:SINGLE / DOUBLE CAB,SUV",
      "SPECIALITY:BICYCLE,BOAT/JETSKI,CARAVAN,GENERATOR,TRAILER,YELLOW METAL",
      "SSANGYONG:ACTYON,ACTYON/ACTYON SPORTS,KORANDO,KYRON,MUSSO 602,MUSSO E230,MUSSO SPORT,REXTON,STAVIC/RODIUS",
      "STUD:300",
      "SUBARU:BRZ,FORESTER 1997 - 2002,FORESTER 2002 - 2005,FORESTER 2005 - ON,IMPREZA 1995 - 2001,IMPREZA 2001 - 2007,IMPREZA 2007 - ON,LEGACY 1992 - 2006,LEGACY 2006 - ON,OUTBACK,SVX,TRIBECA,XV",
      "SUZUKI:ALTO/CELERIO,BALENO,CIAZ,ERTIGA,IGNIS,JIMNY,KIZASHI,SAMURAI,SJ 413/SUPER CARRY,SPLASH,SWIFT,SX4,VITARA 1998 - 2005,VITARA 2008 - ON",
      "TAFE:5000,Model7311",
      "TATA:BOLT,DAEWOO,INDICA,INDIGO,LP / LPO / SFC,LPT / LPTA / LPK / LPT,LPT 1918 - 2523,LPT 709 / 809 / SK 709,NOVUS,PRIMA,SAFARI,SFC 407 / LPT 509,SUPER ACE,TELCOLINE / 207,ULTRA,XENON",
      "TGB:300 - 460,500 - 550",
      "TOYOTA:86,AURIS,AVANZA / INNOVA,AVANZA/INNOVA,AVENSIS,AYGO,C-HR,CAMRY 1992 - 2003,CAMRY 2003 - 2006,CELICA / MR-2,CONDOR,CONQ / COROLLA PRE 88,CONQ / TAZZ 1993 - 2006,CONQ / TAZZ CARRI 1998 - 2006,CONQUEST 1988 - 1993,COROLLA 1988 - 1993,COROLLA 1993 - 1996,COROLLA 1996 - 2002,COROLLA 2002 - ON,COROLLA P/V,COROLLA QUEST,CRESSIDA 1978 - 1989,CRESSIDA 1987 - 1992,CRESSIDA 3.0 1989 - 1992,DA,DYNA,DYNA 1994 - ON,DYNA PRE 94,ETIOS,FORTUNER,HI ACE,HI LUX 1998 - 2005,HI-LUX 1.6-2.2 / 2.4D 1979 - 1998,HI-LUX 2.4 / 2.8 1994 - 1998,HI-LUX 2005 - 2016,HI-LUX RAIDER 1991 - 1994,HILUX 2016 ON,LAND CRUISER / LEXUS,LAND CRUISER PICK UP,LANDCRUISER 70 SERIES,PRADO,PRADO 2002 - ON,PREVIA,PRIUS,QUANTUM,RAV 4,RUNX,SPRINTER 1993 - 1996,STALLION,STOUT,TUV / 1200 / COROLLA P/U,VENTURE,VERSO,YARIS",
      "TRIUMPH:1200 - 2300,600 - 955,695 / 1050",
      "TVR:CERBERA,CHIMAERA,SAGARIS,T 350,TAMORA,TUSCAN",
      "UD TRUCKS:CRONER,QUESTER,QUON,UD",
      "URSUS:2,3,4,5,6,10",
      "US TRUCK:US TRUCKS 1500,US TRUCKS 2500",
      "VALTRA (VALMET):600,700,6 HIGHER,BL NUMBER,BM BH,NUMBER,S,T",
      "VESPA:TMP / PIAGGIO / APE",
      "VICTORY:MAGNUM",
      "VOLKSWAGEN:AMAROK,BEETLE,CADDY,CC,CITI 2009 - ON,CONSTELLATION,CRAFTER,EOS,FOX,GOLF 3,GOLF 4 / 5 / 6/ 7,GOLF BAKKIES 1981 - 2010,GOLFI / II / CITI,JETTA 3,JETTA 4 / 5 / 6,JETTA I / II,JETTA II,KOMBI / MICROBUS,LT 35/46,MICROBUS SYNCRO / T4,P/VANS D/CAB 1975 - 1998,PASSAT,PASSAT 1999 - ON,POLO CLASSIC,POLO PLAYA / POLO,POLO VIVO,SCIROCCO,SHARAN,T5/T6,TIGUAN,TOUAREG,TOURAN,TRANSPORTER,UP!,VIVO",
      "VOLVO:850,960 / S80 / S90 /V90,C30,F / FH 1993 - 2006,FH 16  / 400 - 700 2004 - ON,FL / FM 10 1995 - 2001,FL 6 1995 - 2007,FM 12 2001-2006,FM 2006 - ON,FM 9 2004 - 2006,S40 / V40 / V50,S60,S70 / V70 / C70,V40,V60,V70 / XC60 / XC70,V90,XC 90,XC40,XC60 2011 - ON",
      "WARRIOR:14 SERIES,17 SERIES,2.5 TON,24 SERIES,26 SERIES,7 SERIES,9 SERIES,DFL 375 - 420",
      "WESTERN STAR:47866,69668",
      "WHITE OLIVER:Model7377,Model7383",
      "YAMAHA:250,300,450,700,900,1000 - 1700,115 / 125 / 135",
      "YANMAR:Model7385,Model7386,Model7387,Model7393,Model7396,Model7402",
      "YTO:200-500,LF,X",
      "ZAHOW:Model7406,Model7410,Model7413,Model7415,Model7418,Model7421,Model7422,Model7423,Model7424",
      "ZERO:STEALTHFIGHTER,STREET,STREETFIGHTER",
      "ZOTYE:NOMAD",
      "ZX AUTO:GRANDTIGER,LANDMARK"
    ];
  },
  getSubcatOfParts(category) {
    if (category !== "") {
      return [
        ...new Set(
          this.partslist()
            .filter(z => z.Cat1 === category)
            .map(z => {
              return z.Cat2;
            })
        )
      ];
    }
    return [];
  },
  getComponentOfSubCatParts(cat, subcat) {
    if (subcat !== "") {
      return [
        ...new Set(
          this.partslist()
            .filter(z => z.Cat1 === cat)
            .filter(z => z.Cat2 === subcat)
            .map(z => {
              return z.Cat3;
            })
        )
      ];
    }
    return [];
  },
  getSubComponentOfSubCatParts(cat, subcat, component) {
    if (component !== "") {
      return [
        ...new Set(
          this.partslist()
            .filter(z => z.Cat1 === cat)
            .filter(z => z.Cat2 === subcat)
            .filter(z => z.Cat3 === component)

            .map(z => {
              return z.Cat4;
            })
        )
      ];
    }
    return [];
  },
  partslist() {
    return [
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Bonnet/hood",
        Cat4: "Bonnet/hood"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Bumper",
        Cat4: "Exposed Bumper"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Bumper",
        Cat4: "Unexposed bumper"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Cowl screen",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Decklid",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Fascia rear and support",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Fender (wing or mudguard)",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Front clip",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Front fascia and header panel",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Grille (also called grill)",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Pillar and hard trim",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Quarter panel",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Radiator core support",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Rims",
        Cat4: "Hubcap"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Rims",
        Cat4: "Rims"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Rims",
        Cat4: "Tire/Tyre"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Rocker",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Roof rack",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Spoiler",
        Cat4: "Front spoiler (air dam)"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Spoiler",
        Cat4: "Rear spoiler (wing)"
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Trim package",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Trunk/boot/hatch",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Valance",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Body components, including trim",
        Cat3: "Welded assembly",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Anti-intrusion bar",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Back Door Outer Door Handles",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Central-locking",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Door control module",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Door latch",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Door lock and power door locks",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Door seal",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Door water-shield",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Front Left Side Inner door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Front Left Side Outer door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Front Left Side Window motor",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Front Right Outer door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Front Right Side Inner door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Front Right Side Window motor",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Fuel tank (or fuel filler) door",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Hinge",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Rear Left Side Inner door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Rear Left Side Outer door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Rear Left Side Window motor",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Rear Right Side Inner door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Rear Right Side Outer door handle",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Doors",
        Cat3: "Rear Right Side Window motor",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Front Left Side Door Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Front Right Side Door Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Rear Left Quarter Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Rear Left Side Door Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Rear Right Quarter Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Rear Right Side Door Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Sunroof",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Sunroof Glass",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Sunroof Rail",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Window motor",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Window regulator",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Window seal",
        Cat4: ""
      },
      {
        Cat1: "Car body and main part",
        Cat2: "Windows",
        Cat3: "Windscreen",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Antenna cable",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Antenna assembly",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Radio and media player",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Speaker",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Subwoofer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Tuner",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Audio/video devices",
        Cat3: "Video player",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Cameras",
        Cat3: "Backup camera",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Cameras",
        Cat3: "Dashcam",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Battery",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Door switch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Fan ditch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Frame switch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Ignition switch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Neutral Safety Switch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Parts and functions of starter system",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Power window switch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Steering column switch",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Switch cover",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Switch panel",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Electrical switches",
        Cat3: "Thermostat",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Ammeter",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Clinometer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Dynamometer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Fuel gauge",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Hydrometer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Manometer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Odometer (also called milometer or mileometers)",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Oil pressure gauge",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Speedometer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Tachometer (also called rev counters)",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Temperature gauge",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Tire pressure gauge",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Vacuum gauge",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Voltmeter",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Gauges and meters",
        Cat3: "Water temperature meter",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Distributor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Distributor Cap",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Electronic timing controller",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Glow Plug",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Ignition box",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Ignition coil",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Ignition Coil Connector",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Ignition coil parts",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Ignition magneto",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Spark plug",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Ignition system",
        Cat3: "Sparking cable",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Headlight motor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Tail light cover",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Engine bay lighting",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Fog light",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Headlight",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Indicator light",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Interior light and lamp",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "License plate lamp",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Side lighting",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Spotlight",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Tail light",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Lighting and signaling system",
        Cat3: "Turn signal control",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Alternator",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery Box"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery Cable"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery Cable terminal"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery Cap"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery Control system"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery Plate"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Battery tray"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Distilled Water"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Performance Battery"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Battery",
        Cat4: "Sulphuric Acid (H2SO4)"
      },
      {
        Cat1: "Electrical",
        Cat2: "Low voltage electrical supply system",
        Cat3: "Voltage regulator",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Air bag control module",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Alarm and siren",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Central locking system",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Chassis control computer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Cruise control computer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Door contact",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Engine computer and management system",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Engine control unit",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Fuse",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Grab Handle",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Ground strap",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Navigation system / GPS navigation device",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Performance chip",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Performance monitor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Relay connector",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Remote lock",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Shift improver",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Speed controller",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Speedometer calibrator",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Transmission computer",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Miscellaneous",
        Cat3: "Wiring connector",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "ABS Sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Airbag sensors",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Automatic transmission speed sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Camshaft position sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Coolant temperature sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Crankshaft position sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Fuel level sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Fuel pressure sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Knock sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Light sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "MAP sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Mass airflow sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Oil level sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Oil pressure sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Oxygen sensor (o2)",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Sensors",
        Cat3: "Throttle position sensor",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Starting system",
        Cat3: "Glowplug",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Starting system",
        Cat3: "Starter",
        Cat4: "Starter drive"
      },
      {
        Cat1: "Electrical",
        Cat2: "Starting system",
        Cat3: "Starter",
        Cat4: "Starter motor"
      },
      {
        Cat1: "Electrical",
        Cat2: "Starting system",
        Cat3: "Starter",
        Cat4: "starter pinion gear"
      },
      {
        Cat1: "Electrical",
        Cat2: "Starting system",
        Cat3: "Starter",
        Cat4: "Starter solenoid"
      },
      {
        Cat1: "Electrical",
        Cat2: "Wiring harnesses",
        Cat3: "Air conditioning harness",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Wiring harnesses",
        Cat3: "control harness",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Wiring harnesses",
        Cat3: "Engine compartment harness",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Wiring harnesses",
        Cat3: "floor harness",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Wiring harnesses",
        Cat3: "Interior harness",
        Cat4: ""
      },
      {
        Cat1: "Electrical",
        Cat2: "Wiring harnesses",
        Cat3: "Main harness",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Arm Rest",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Back seat",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Bench seat",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Bucket seat",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Children and baby car seat",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Fastener",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Front seat",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Headrest",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Other seat components",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Seat belt",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Seat bracket",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Seat cover",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Car seat",
        Cat3: "Seat track",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Floor components and parts",
        Cat3: "Carpet and other floor material",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Floor components and parts",
        Cat3: "Center console (front and rear)",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Other components",
        Cat3: "Dash Panels",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Other components",
        Cat3: "Roll cage or Exo cage",
        Cat4: ""
      },
      {
        Cat1: "Interior",
        Cat2: "Other components",
        Cat3: "Trap (secret compartment)",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Cabin Filter",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Clutch",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Compressor",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Condenser",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Condenser Filter",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Cooler",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Discharge Hose Pipe",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Evaporator",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Expansion Valve",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Gas Receiver",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Hose",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C INNER PLATE",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Kit",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Low-pressure Valve",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Relay",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Schroeder Valve",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Suction Hose Pipe",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Air conditioning system (A/C)",
        Cat3: "A/C Valve",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Bearings",
        Cat3: "Grooved ball bearing",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Bearings",
        Cat3: "Needle bearing",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Bearings",
        Cat3: "Roller bearing",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Bearings",
        Cat3: "Sleeve bearing",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Bearings",
        Cat3: "wheel bearing",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Hose",
        Cat3: "Fuel vapour hose",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Hose",
        Cat3: "Non-reinforced hose",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Hose",
        Cat3: "Radiator hose",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Hose",
        Cat3: "Reinforced hose (high-pressure hose)",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Center console",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Flange nut",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Glove compartment",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Hex nut",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "License plate bracket",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Speedometer cable",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Adhesive tape and foil",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Air bag",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Bolt cap",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Cables",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Cotter pin",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Dashboard",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Drag link",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Dynamic seal",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Fastener",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Gasket: Flat, moulded, profiled",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Hood and trunk release cable",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Horn and trumpet horn",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Injection-molded parts",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Instrument cluster",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Label",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Logo",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Mirror",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Name plate",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Nut",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "O-ring",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Paint",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Phone Mount",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Rivet",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Rubber (extruded and molded)",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Screw",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Shim",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Sun visor",
        Cat4: ""
      },
      {
        Cat1: "Miscellaneous auto parts",
        Cat2: "Other miscellaneous parts",
        Cat3: "Washer",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Adjusting mechanism (adjuster star wheel)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Anchor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Anti-lock braking system (ABS)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Bleed nipple",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake backing pad",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake backing plate",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake cooling duct",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake disc",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake drum",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake Fluid",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake lining",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake pad",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake pedal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake piston",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake pump",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake roll",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake rotor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake servo",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake shoe",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Brake warning light",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Calibrated friction brake",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Caliper",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Combination valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Dual circuit brake system",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Hold-down springs (retainer springs)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Hose",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Hydraulic booster unit",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Load-sensing valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Master cylinder",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Metering valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Other braking system parts",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Park brake lever/handle (hand brake)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Pressure differential valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Proportioning valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Reservoir",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Shoe return spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Tyre",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Vacuum brake booster",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Wheel cylinder (slave cylinder)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Braking system",
        Cat3: "Wheel stud",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Battery management system",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Charge port",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Charger",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "DC-DC converter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Electric motor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Fuel cell",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "High voltage battery pack",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Hydrogen tank",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Inverter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Electrified powertrain components",
        Cat3: "Thermal management system",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Accessory belt",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Air duct",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Air intake housing",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Air intake manifold",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Camshaft",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Connecting rod",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Crank case",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Crank pulley",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Crankshaft",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Crankshaft oil seal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Cylinder head",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Diesel engine, petrol engine (gasoline engine)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Distributor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Distributor cap",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Drive belt",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Engine block",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Engine cradle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Engine shake damper and vibration absorber",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Engine valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Fan belt",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Gudgeon pin (wrist pin)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Harmonic balancer",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Heater",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Mounting",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "PCV valve (positive crankcase ventilation valve)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Piston",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Poppet valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Pulley part",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Rocker arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Rocker cover",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Starter motor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Tappet",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Timing tape",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Turbocharger and supercharger",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Valve cover",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Valve housing",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Valve spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Valve stem seal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine components and parts",
        Cat3: "Water pump pulley",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Air blower",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Coolant hose (clamp)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Cooling fan",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Fan belt",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Fan blade",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Fan clutch",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Radiator",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Water neck",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Water neck o-ring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Water pipe",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Water pump",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Water pump gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine cooling system",
        Cat3: "Water tank",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine oil systems",
        Cat3: "Oil filter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine oil systems",
        Cat3: "Oil gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine oil systems",
        Cat3: "Oil pan",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine oil systems",
        Cat3: "Oil pipe",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine oil systems",
        Cat3: "Oil pump",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Engine oil systems",
        Cat3: "Oil strainer",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Catalytic converter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Exhaust clamp and bracket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Exhaust flange gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Exhaust gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Exhaust manifold",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Exhaust manifold gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Exhaust pipe",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Heat shield",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Heat sleeving and tape",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Muffler (Silencer)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Resonator",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Exhaust system",
        Cat3: "Spacer ring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Air filter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Carburetor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Choke cable",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "EGR valve",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel cap",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel cell",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel cooler",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel distributor",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel filter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel filter seal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel injector",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel injector nozzle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel line",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel pressure regulator",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel pump",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel pump gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel rail",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel tank",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Fuel water separator",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Intake manifold",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Intake manifold gasket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "LPG",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Fuel supply system",
        Cat3: "Throttle body",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Air spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Ball joint",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Coil spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Leaf and parabolic leaf spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Rubber spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Spiral spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Axle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Beam axle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Camber arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Control arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Idler arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Kingpin",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Lateral link",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Pan-hard rod",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Pit-man arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Power steering assembly and component",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Rack end",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Shock absorber",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Spindle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Stabilizer bars and link",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering box",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering column assembly",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering pump",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering rack",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering shaft",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Steering wheel (driving wheel)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Strut",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Stub axle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Suspension link and bolt",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Suspension and steering systems",
        Cat3: "Trailing arm",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential casing",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential clutch",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Pinion bearing",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Spider gears",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch pedal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch pressure plate",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch shoe",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential case",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential flange",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential seal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Flywheel clutch",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Flywheel ring gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Gear coupling",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Gear pump",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Gear ring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Gear stick",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Adjustable pedal",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Axle shaft",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Bell housing",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Carrier assembly",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Chain wheel and sprocket",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch assembly",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch cable",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch disk",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch fan",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch fork",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch hose",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch lever",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Clutch lining",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Differential",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Flywheel",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Gearbox",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Idler gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Knuckle",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Master cylinder",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Other belts",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Output shaft",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Pinion",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Planetary gear set",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Prop shaft (drive shaft, propeller shaft)",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Shift cable",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Shift fork",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Shift knob",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Shift lever",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Slave cylinder",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Speed reducer",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Speedometer gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Steering gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Torque converter",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Trans-axle housing",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Transfer case",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Transmission gear",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Transmission pan",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Transmission seal and bonded piston",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Transmission spring",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Transmission yoke",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Universal joint",
        Cat4: ""
      },
      {
        Cat1: "Power-train and chassis",
        Cat2: "Transmission system",
        Cat3: "Universal joint (UJ, card-an joint)",
        Cat4: ""
      }
    ];
  }
};
export default Utils;
