import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import Input from "@material-ui/core/Input";
// import SearchBar from "./searchbar";
import Utils from "./utils";
// import ExifOrientationImg from "react-exif-orientation-img";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from "@material-ui/core/Snackbar";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trx: [],
      nextToken: null,
      disablemore: false,

      loading: true,
      hasaddress: false
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    this.doSnackBar("Getting transactions");
    let trx = await Utils.listTransactions(this.state.nextToken);
    var items = trx.items.filter(z => z.status === "processing");
    items.forEach(z => {
      var listingitems = JSON.parse(z.listings);
      z.listingdetails = [];
      listingitems.forEach(a => {
        z.listingdetails.push({ id: a });
      });
      if (z.waybilldata === null) z.waybilldata = { buyer: "", sellers: {} };
      else z.waybilldata = JSON.parse(z.waybilldata);
    });
    for (var i = 0; i < items.length; i++) {
      for (var j = 0; j < items[i].listingdetails.length; j++) {
        var listing = await Utils.getListingByIdNoPics(
          items[i].listingdetails[j].id
        );
        items[i].listingdetails[j] = listing.listing;
      }
    }
    items = [...new Set(items)]
    this.setState({
      trx: items.sort((a,b)=>a.invoiceNumber-b.invoiceNumber),
      nextToken: trx.nextToken,
      loading: false
    });
  }

  async getMoreTransactions() {
    this.doSnackBar("Getting transactions");
    let trx = await Utils.listTransactions(this.state.nextToken);
    var oldtrx = this.state.trx;
    var items = trx.items.filter(z => z.status === "processing");
    items.forEach(z => {
      var listingitems = JSON.parse(z.listings);
      z.listingdetails = [];
      listingitems.forEach(a => {
        z.listingdetails.push({ id: a });
      });
      if (z.waybilldata === null) z.waybilldata = { buyer: "", sellers: {} };
      else z.waybilldata = JSON.parse(z.waybilldata);
    });
    for (var i = 0; i < items.length; i++) {
      for (var j = 0; j < items[i].listingdetails.length; j++) {
        var listing = await Utils.getListingByIdNoPics(
          items[i].listingdetails[j].id
        );
        items[i].listingdetails[j] = listing.listing;
      }
    }

    oldtrx = oldtrx.concat(items);
  oldtrx = [...new Set(oldtrx)]
    this.setState({ trx: oldtrx.sort((a,b)=>a.invoiceNumber-b.invoiceNumber), nextToken: trx.nextToken });
    if (trx.nextToken === null) this.setState({ disablemore: true });
  }

  async saveWaybillData(input, itemid) {
    let s = await Utils.saveWaybillData(input);
    if (s.success === true) {
      this.doSnackBar("Saved");
      var emailaddi = this.state.trx.filter(z => z.id === input.id);
      await Utils.sendwaybillinfo(
        emailaddi[0].user.contactemail,
        emailaddi[0].listingdetails.filter(z => z.id === itemid)[0].title,
        JSON.parse(input.waybilldata).sellers[itemid]
      );
    }
  }

async markAsPaid(input){
  console.log(input)
  this.doSnackBar("Please wait")
  let item = {
    id :input.id,
    status:"complete",
    transactionstatus:"complete"
  }
  try{

    let trx=this.state.trx.filter(z=>z.id!==input.id)
    await Utils.genericMutation("updateOttoPartzTransactions",item)
    await Utils.sendShippingInstructionRECEIPT(input.id)
    await Utils.setItemsToUnavailable(
      input.listings
    );
    this.setState({trx})
    this.doSnackBar("Completed, ")
  } catch (err) {
    console.log(err)
  }
}
  render() {
    if (this.state.loading)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    else {
      return (
        <div
          style={{
            color: "#666",
            margin: isMobile ? 10 : 0,
            justifyContent: isMobile ? "center" : "flex-start",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.statusbar}
            autoHideDuration={3000}
            onClose={() => this.setState({ statusbar: false })}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.statusbartext}</span>}
          />
          <h2
            style={{
              color: "#666",
              margin: isMobile ? 10 : 0,
              justifyContent: isMobile ? "center" : "flex-start"
            }}
          >
            Waybills
          </h2>
          <Button
            style={{
              margin: 5,
              backgroundColor: "#00a0e2",
              color: "#fff",
              alignSelf: "center",
              width: "100%"
            }}
            disabled={this.state.disablemore}
            variant="contained"
            onClick={() => {
              this.getMoreTransactions();
            }}
          >
            Get More Transactions
          </Button>


          {this.state.trx.map((e, i) => (
            <Card key={i} style={{marginTop:5, marginBottom:5}}>
              <CardContent>
                <div>
                  <div><b>Invoice number:</b>{ " " + e.invoiceNumber}</div>
                  <div><b>REF :{ " " + e.transactioncode}</b></div>
                  <div><b>Date:</b>{ " " + e.transactiondate.substr(0, 10)}</div>
                  <div><b>Total:</b>{ " " + Utils.formatTextToCurrency(e.total)}</div>
                  <div><b>Buyer:</b>{ " " + e.user.businessname}</div>
                  <div>
                    <b>Buyer address:</b>{ " " +
                      (e.user.buildingaddress ? e.user.buildingaddress : "") +
                      (e.user.streetaddress ? "," + e.user.streetaddress : "") +
                      (e.user.suburb ? "," + e.user.suburb : "") +
                      (e.user.city ? "," + e.user.city : "") +
                      (e.user.province ? "," + e.user.province : "") +
                      (e.user.areacode ? "," + e.user.areacode : "")}
                  </div>
                  <Button
                    style={{
                      margin: 5,
                      backgroundColor: "#00a0e2",
                      color: "#fff",
                      alignSelf: "center",
                      width: "100%"
                    }}
                    variant="contained"
                    onClick={() => {
                      this.markAsPaid(e)
                    }}
                  >
                    Mark as paid
                  </Button>


                </div>
              </CardContent>
            </Card>
          ))}



        </div>
      );
    }
  }
}

export default withRouter(Cart);
