import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

class Privacy extends Component {
  render() {
    return (
      <div style={{ color: "#666" }}>
        <h1>Seneca Auto Group Customer Terms and Conditions</h1>
        <b>
          Items in these Terms and Conditions that are of importance or that
          carry a level of risk for you are in bold. Please pay special
          attention to these clauses and make sure you understand them. If you
          don’t understand something please get us to explain it to you.
        </b>
        <h2>1 Definitions</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Acceptance Date</b>” means the date on which you accepted
                the Agreement, by way of electronic medium, for example by
                clicking “I agree” on a web page or via your mobile phone;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Agreement</b>” means the agreement concluded between you and
                Seneca Auto Group which agreement will be exclusively governed
                by these terms and conditions and the privacy policy;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Business Day</b>” means Monday to Friday, but excludes
                Saturdays and Sundays and a day which is an official public
                holiday in the Republic of South Africa;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Business Hours</b>” means the hours between 08h00 and 17h00
                on a Business Day;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>CPA</b>” means the Consumer Protection Act, 2008;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>ECT Act</b>” means the Electronic Communications and
                Transactions Act, 2002;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Seneca Auto Group</b>”,“<b>we</b>”,“<b>us</b>” and “
                <b>our</b>” means Seneca Auto Group (Proprietary) Limited;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Seneca Auto Group Marks</b>” means any trademarks, logos,
                brand names, trade names domain names or other names or marks of
                Seneca Auto Group whether registered or not;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.9
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Intellectual Property Rights</b>” means the copyright in any
                work in terms of the Copyright Act, No. 98 of 1978, and includes
                without limitation the right to reproduce that work, the rights
                in respect of a trade mark conferred by the Trade Marks Act, No.
                194 of 1993, the rights in respect of a design conferred by the
                Designs Act, No. 195 of 1993, and the rights in respect of a
                patent conferred by the Patents Act, No. 57 of 1978 including
                any applications for the aforegoing and any names, licenses,
                know how, trade secrets and data associated with the aforegoing;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.10
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Juristic Person</b>” means a company or close corporation
                and includes a body corporate, partnership, association or
                trust;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.11
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Legal Notices Website</b>” means
                http://scrapapp.co.za/termsandconditions;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.12
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Purchase Date</b>” means date that a successful sale is
                concluded by completing the checkout process including
                successful payment in full;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.13
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Service</b>” means the sale of used auto parts;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.14
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Customer</b>” or “<b>you</b>” means a user of any of our
                products or services;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.15
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Uncontrollable Event</b>” means (including without
                limitation) any fire, flood, earthquake, elements of nature or
                acts of God, superior authorities, pandemics, epidemics;
                riots, civil disorders, rebellions or revolutions in any country
                or any other cause beyond the reasonable control of Seneca Auto
                Group including the termination or suspension of a service or
                product provided by a third party, that may result in a delay or
                a failure to provide any product or service;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.16
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>VAT</b>” means Value Added Tax as provided for in the Value
                Added Tax Act, 1991; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                1.17
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                “<b>Website</b>” means http://scrapapp.co.za;
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>2 Commencement, Duration, Termination and Cooling-off</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The Agreement will commence on the Acceptance Date and endure
                for as long as you have a profile with Seneca Auto Group until
                it is cancelled as provided for in this Agreement.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  This Agreement incorporates by reference the Website Terms and
                  Conditions and Privacy Policy which have been made available
                  to you on this website and which you undertake to read and
                  understand. If you do not understand anything, please ask us
                  to explain it to you.
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group may terminate this Agreement immediately for
                material breach of this Agreement.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Notwithstanding the termination of the Agreement, in the event
                that you continue to use the products or services despite the
                termination of the Agreement, you will remain liable for and
                promptly pay on demand all amounts that would have been due to
                Seneca Auto Group as a result of the use of or access to the
                product or service and this Agreement shall be deemed to
                continue to apply until such time as all amounts due to Seneca
                Auto Group have been paid in full, and we will be entitled to
                terminate the agreement at any time.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                If the Agreement results from any direct approach to you by
                Seneca Auto Group or is an electronic transaction as
                contemplated in the ECT Act, you will be entitled to cancel the
                Agreement on written notice to Seneca Auto Group without reason
                or penalty within 5 (five) Business Days of –
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.5.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                in the case of services only being provided in terms of the
                Agreement - the Purchase Date; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.5.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                in the case where goods are provided and constitute the subject
                of the Agreement, whether in conjunction with services or on its
                own – the date of delivery of such goods.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                2.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                A contract for sale of goods will only come into being on the
                Purchase Date.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>3 Conditions of access</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group will make the service available to you on the
                Acceptance Date.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You must be at least 18 (eighteen) years of age to access the
                Services.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group will, where relevant, issue a user name and
                password to you prior to the Acceptance Date in order to enable
                you to gain access to and/or use a service. In such instance,
                you will not be able to access and/or use a service without a
                user name and password.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You agree that:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                you will use your user name and password for your own personal
                use only;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                you will not disclose your user name and password to any other
                person for any reason whatsoever and that you will maintain the
                confidentiality thereof;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                in the event that your password is compromised, you will
                immediately notify Seneca Auto Group and change your password;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  you, as the holder of the user name and password, acknowledge
                  you are solely responsible for all payments in respect of a
                  service charged to your Seneca Auto Group account,
                  irrespective of whether the service has been utilized or is
                  being utilized by you or not and accordingly the entire amount
                  outstanding on your Seneca Auto Group account will be deemed
                  to have arisen from (or relate to) your access to and/or use
                  of a service;
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  you agree to cause all persons who use any products or
                  services under your account or with your authorization to
                  comply with the Agreement. All acts or omissions of all
                  persons who use services under your account or with your
                  authorization will be treated for all purposes as your acts or
                  omissions;
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  Seneca Auto Group also offers a password reminder service. We
                  will send your password to your registered email address or
                  cell phone number should you have forgotten your password.
                  Subscription to this service is voluntary. Password reminders
                  will only be sent to your registered details and should such
                  details change it is your responsibility to notify us.
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                you will not, at any time, permit and/or initiate a simultaneous{" "}
                <u>network log-in</u>; and
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                3.4.8
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                you will not attempt to circumvent Seneca Auto Group’ user
                authentication processes or engage in attempts to access Seneca
                Auto Group’ network where not expressly authorised to do so.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>4 Service Delivery, Service Availability, Ownership</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  Seneca Auto Group will use reasonable endeavors to make its
                  Services available to you, and to maintain the availability
                  thereof for use by you. However, we provide the Services “as
                  is” and “as available” and do not warrant or guarantee that
                  the Services will at all times be free of errors or
                  interruptions, be always available, fit for any purpose, not
                  infringe any third party rights, be secure and reliable, or
                  will conform to your delivery timeline requirements subject
                  always to the provisions of the CPA where applicable.
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group will use its best endeavors to notify you in
                advance of any maintenance and repairs which may result in
                the unavailability of a Service, but cannot always guarantee
                this.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Goods will be delivered by our courier services to street
                addresses only. Our courier will attempt to deliver 3 (three)
                times, to the street address specified by you in the checkout
                process, whereafter we will cancel the order and refund any
                monies due to you.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The cost of delivery will be specified during the checkout
                process.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                4.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Ownership in the goods will pass on the Purchase Date. Risk will
                pass on delivery.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>5 Communication and Dispute Resolution</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You agree that Seneca Auto Group may from time to time send you
                communications regarding (without being limited to) special
                offers or discounts which Seneca Auto Group may negotiate for
                and offer to its Customers, operational changes that may affect
                the Services and/or new services or products launched. All
                communications will abide by our Privacy Policy and applicable
                law. You will always be entitled to notify us in writing that
                you do not wish to receive or continue to receive such
                communications and if you are a consumer as contemplated in the
                CPA, to pre-emptively block the receipt of such communications.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Any dispute between the parties may be referred to arbitration
                and finally resolved in accordance with the rules of the
                Arbitration Foundation of Southern Africa. Such arbitration
                shall be held either in Cape Town or Johannesburg, and conducted
                in the English language before one arbitrator appointed in
                accordance with the said rules. Any award will be final and not
                subject to appeal. This agreement to arbitrate shall be
                enforceable in, and judgement upon any award may be entered in
                any court of any country having appropriate jurisdiction. A
                dispute shall be deemed to have arisen when either party
                notifies the other party in writing to that effect.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The arbitrator shall have the power to give default judgement if
                any party fails to make submissions on due date and/or fails to
                appear at the arbitration.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                5.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The provisions set out above shall not prevent either party from
                approaching any court of competent jurisdiction to obtain
                interim or other relief in cases of urgency.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>6 Payment</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Billing will commence on the Purchase Date. A valid tax invoice
                will be emailed to your registered email address on the Purchase
                Date.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Payment can be made as follows:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Credit Card Transactions are acquired Peach Payments, a
                registered systems operator and payment gateway in South Africa.
                Card Holders may go to www.peachpayments.com to view their
                security policy. Please note that you will be required to accept
                the terms and conditions for the use of the Peach Payments
                service to place any orders with us. If authorisation is not
                obtained from your acquiring bank, the order is cancelled. You
                warrant that you are authorised to use the credit card for
                payment of your order and that there are sufficient funds in
                this credit card account to cover the costs of any transactions
                you complete on Seneca Auto Group.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                6.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Paying by EFT: When selecting the EFT payment option an invoice
                will be generated and sent to you for payment. Once payment
                reflects in our account the item will be marked for shipping.
                Please ensure timeous payment of EFT invoices to secure the
                item/s.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>7 Privacy</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                7.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group will deal with your personal information in
                accordance with the provisions of our Privacy Policy which is
                available on our http://scrapapp.co.za/privacypolicy and in
                compliance with all relevant laws.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>8 Intellectual Property Rights</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You agree to comply with all laws applicable to any Intellectual
                Property Rights in respect of any data, files and/or information
                accessed, retrieved or stored by you through your use of any of
                our services and/products.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You are prohibited from using any Seneca Auto Group Marks
                without the prior written approval of Seneca Auto Group.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                8.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group (or its Third Party licensors) will wholly and
                exclusively retain all Intellectual Property Rights related to
                the provision of any of the services or products in terms of
                these terms and conditions.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>9 Warranty and Returns</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The items on offer are used goods as defined in the CPA. These goods are likely to have wear and/or damage. The items purchased are only eligible for refund or repair if there is a material difference between the listed condition and the condition on arrival.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                IIn the first six months after the delivery date, should the goods not be suitable for the purpose for which you bought it or should something go wrong with the goods purchased, the Customer will have the rights and remedies set out in the Consumer Protection Act available to them.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Any goods deemed returnable will be at Seneca Auto Group’s risk and expense. Please contact us on info@scrapapp.co.za and ask about our process for return of faulty goods.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                9.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Goods found to be faulty will be repaired or replaced at your option.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>10 Breach</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Subject to any other provisions set out in these terms and
                conditions, should you be in breach of any provision of this
                Agreement, then Seneca Auto Group shall be entitled, without
                prejudice to any other rights that it may have and to the extent
                required or permitted, as the case may be, by law, to forthwith:
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                afford you a reasonable opportunity to remedy the breach, taking
                into account the nature of the breach in question; or
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                suspend your access to the Service; or
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                10.1.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                cancel all agreements concluded between us.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>11 Indemnity</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  You hereby unconditionally and irrevocably indemnify Seneca
                  Auto Group and agree to indemnify and hold Seneca Auto Group
                  harmless against all loss, damages, claims, liability and/or
                  costs, of whatsoever nature, howsoever and whensoever arising,
                  suffered or incurred by Seneca Auto Group as a result of any
                  claim instituted against Seneca Auto Group by a third party
                  (other than you) as a result of (without limitation):
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  your use of our Services other than as allowed or prescribed
                  in the Agreement;
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                11.1.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  any other cause whatsoever relating to the Agreement or the
                  provision of Services to you where you have acted wrongfully
                  or failed to act when you had a duty to so act.{" "}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>
          12 No representations, warranties or guarantees and Limitation of
          liability
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  Save to the extent otherwise provided for in this Agreement or
                  where you are entitled to rely on or receive, by operation of
                  law, any representations, warranties or guarantees, we do not
                  make or provide any express or implied representations,
                  warranties or guarantees regarding the availability, accuracy,
                  reliability, timeliness, quality or security of any product or
                  service.{" "}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  Without limiting the generality of the provisions of clause
                  12.2, Seneca Auto Group shall not be liable for and you will
                  have no claim of whatsoever nature against Seneca Auto Group
                  as a result of{" "}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.2.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  the loss of or access to any usernames and passwords which you
                  are required to safeguard and not allow unauthorized access on
                  the understanding that we will be entitled to assume that you
                  are the person so using or gaining access to any service or
                  account where your username and password is used;{" "}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.2.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  any unavailability of, or interruption in the Service due to
                  an Uncontrolled Event;
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.2.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  any damage, loss, cost or claim which you may suffer or incur
                  arising from any suspension or termination of the service/s
                  for any reason contemplated in the Agreement.
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                12.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  In addition to and without prejudice to any other limitations
                  of liability provided for in the Agreement and to the fullest
                  extent permitted by applicable law, Seneca Auto Group shall
                  not be liable to you for any direct damages howsoever arising
                  and neither party shall be liable to the other for any
                  special, indirect, incidental, consequential or punitive
                  damages arising out of or relating to this Agreement, whether
                  resulting from negligence, breach or any other cause. To the
                  extent that a competent court or tribunal or other competent
                  dispute resolution body or authority finally determines,
                  notwithstanding the exclusion contained in this clause, that
                  Seneca Auto Group is liable to you for any damages, Seneca
                  Auto Group’ liability to you for any damages howsoever arising
                  shall be limited to the amounts paid by you under this
                  Agreement in consideration for the Services during the
                  immediately preceding 12 (twelve) month period in respect of
                  the Services which gave rise to the liability in question.
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2>13 Cession and Delegation</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  You may not sell, cede, assign, delegate or in any other way
                  alienate or dispose of any or all of your rights and
                  obligations under and in terms of this Agreement without the
                  prior written approval of Seneca Auto Group. Seneca Auto Group
                  shall be entitled to sell, cede, assign, delegate, alienate,
                  dispose or transfer any or all of its rights and obligations
                  under and in terms of this Agreement to any of its affiliates
                  or to any third party without your consent and without notice
                  to you provided that you are not unduly prejudiced as a
                  result. “Affiliates” for this purpose includes Seneca Auto
                  Group’ holding company, the holding company(ies) of Seneca
                  Auto Group’ holding company (collectively “its holding
                  companies”), its subsidiaries, subsidiaries of its holding
                  companies and any other companies which are directly or
                  indirectly controlled by Seneca Auto Group or are under common
                  control with Seneca Auto Group.
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>14 Jurisdiction</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You hereby consent to the jurisdiction of the Magistrate's Court
                in the Republic of South Africa in respect of any proceedings
                that may be initiated by Seneca Auto Group arising out of this
                Agreement, provided that Seneca Auto Group shall be entitled,
                in its reasonable discretion, to institute such proceedings in
                the High Court of South Africa and, in such event, you consent
                to the jurisdiction of such court. The jurisdiction of the Small
                Claims Court is specifically excluded, as the parties agreed to
                follow the arbitration process set out in clause 5 above.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>15 Amendment of this agreement</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group reserves the right to amend this agreement
                from time to time. Any new version of the Agreement will be
                displayed on our Website together with the date on which it will
                become effective, which will never be less than 30 (thirty) days
                after the date on which it is first published. It is your
                obligation to visit our website on a regular basis in order to
                determine whether any amendments have been made.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>16 General</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.1
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                The parties acknowledge and agree that this Agreement
                constitutes the whole of the agreement between them and that no
                other agreements, guarantees, undertakings or representations,
                either verbal or in writing, relating to the subject matter of
                this Agreement not incorporated in this Agreement shall be
                binding on the parties. No variation or addition of this
                Agreement or the Application Form will be binding on any of the
                parties unless recorded in writing and signed by both parties.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.2
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                Seneca Auto Group is in terms of section 43 of the ECT Act
                required to make its contact details, its domicilia citandi et
                executandi and certain other information available to its
                customers who enter into electronic transactions with Seneca
                Auto Group. This information is available on the website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.3
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                You agree that any notices we send to you in terms of any
                agreement concluded between us may be sent via e-mail unless
                otherwise prescribed by law.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.4
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                No indulgence, leniency or extension of time which Seneca Auto
                Group may grant or show to you shall in any way prejudice Seneca
                Auto Group or preclude Seneca Auto Group from exercising any of
                its rights in the future.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.5
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                <b>
                  You warrant that as at the date of signature all the details
                  furnished by you to Seneca Auto Group are true and correct and
                  that you will notify Seneca Auto Group in the event of any
                  change to such details.
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.6
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                All our terms and conditions can be accessed, stored, and
                reproduced electronically by you.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.7
              </TableCell>
              <TableCell style={{ borderBottom: "none", color: "#666" }}>
                16.7 The physical address where Seneca Auto Group will receive
                legal service of documents/ domicilium citandi et executandi is
                the following: Seneca Auto Group 13 Mill Street, Hamilton,
                Bloemfontein, South Africa.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <Button
            onClick={() => {
              this.props.history.push("/privacypolicy");
            }}
          >
            Privacy Policy
          </Button>
          <Button
            onClick={() => {
              this.props.history.push("/paiamanual");
            }}
          >
            PAIA Manual
          </Button>
          <Button
            onClick={() => {
              this.props.history.push("/conditionsofuse");
            }}
          >
            Conditions of Use
          </Button>
        </div>
      </div>
    );
  }
}
export default withRouter(Privacy);
