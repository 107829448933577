import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import Snackbar from "@material-ui/core/Snackbar";

class Thanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: ""
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    var id = this.props.history.location.search;
    id = id.split("&")[0];
    id = id.replace("?id=", "");
    let status = await fetch("https://scrapapp.co.za/checkout?status=" + id);
    var result = await status.json();
    // console.log(result)
    if (
      result.result.code === "000.000.000" ||
      result.result.code === "000.100.110" ||
      result.result.code === "000.100.111" ||
      result.result.code === "000.100.112"
    ) {
      this.doSnackBar("Payment success");
      this.setState({
        status: "Success. Items will be shipped as soon as possible."
      });
      let s = await Utils.findcart(id);
      await Utils.moveCartIntoHistory(s.id, s.total, result.result.code);

      this.props.history.push("/orderhistory");
    } else {
      this.doSnackBar("Payment failed");
      let s = await Utils.findcart(id);
       await Utils.failCartIntoHistory(s.id, result.result.code);
      this.props.history.push("/orderhistory");
    }
  }

  render() {
    return (
      <div>
        {" "}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
      </div>
    );
  }
}

export default withRouter(Thanks);
