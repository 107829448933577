import React, { Component } from "react";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
import CardTemplate from "./cardtemplate";
import { isMobile } from "react-device-detect";

import "./App.css";

export default class CardGridTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  maketablecolumns(input) {
    if (input)
      // <td key={item.id}>
      // </td>
      return input.map((item, index) => {
        return (
          <CardTemplate
            key={index}
            item={item}
            onPress={(id) => this.props.onPress(id)}
            onProfilePress={(id) => this.props.onProfilePress(id)}
            soldby={
              this.props.showsoldby === true ? (
                <div>
                  Sold by {" "}

                  <a href={"/sellerlistings?seller="+btoa(item.user.id)}>{item.user.businessname}</a>
                   {" "}and fulfilled by Scrap App
                </div>
              ) : (
                ""
              )
            }
            showRemovalFromCart={this.props.showRemovalFromCart}
            delCartItem={(userid, itemid) =>
              this.props.delCartItem(userid, itemid)
            }
          />
        );
      });
  }

  maketablerows(inputitems) {
    if (inputitems !== null) {
      var total = [];
      var tripple = [];
      for (var i = 0; i < inputitems.length; i++) {
        tripple.push(inputitems[i]);
        if ((i + 1) % 3 === 0) {
          total.push(tripple);
          tripple = [];
        }
      }
      if (tripple.length > 0) total.push(tripple);
      return total.map((item, index) => {
        return <tr key={index}>{this.maketablecolumns(item)}</tr>;
      });
    }
  }

  render() {
    // console.log(this.props.items);
    if (this.props.items !== null)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "flex-start",
          }}
        >
          {this.maketablecolumns(this.props.items || null)}
        </div>
      );
    else return <div />;
  }
}
