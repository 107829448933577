import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import ExifOrientationImg from "react-exif-orientation-img";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Auth from "@aws-amplify/auth";
import ImageUploader from "react-images-upload";
import { isMobile } from "react-device-detect";
import Snackbar from "@material-ui/core/Snackbar";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: "",
      userProfile: {
        areacode: "",
        availableslots: 0,
        buildingaddress: "",
        businessname: "",
        contact: "",
        contactemail: "",
        contactphone: "",
        contactphonealternate: "",
        federatedid: "",
        city: "",
        vatnumber:"",
        id: "",
        listings: {},
        profilepic: { uri: "" },
        province: "",
        streetaddress: "",
        suburb: "",
        user: null,
        verified: false
      },
      loading: true
    };
    this.myRef = React.createRef();
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  doToast(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    let cog = await Auth.currentAuthenticatedUser();
    if (cog) {
      this.setState({ authed: true });
      var username = "";
      if (cog.username) username = cog.username;
      else username = cog.id;
      let user = await Utils.getUserProfileById(username);
      if (user.user.profilepic === null)
        user.user.profilepic = await Utils.getLatestPic("defaultpic.png");
      else
        user.user.profilepic = await Utils.getLatestPic(
          JSON.parse(user.user.profilepic).key
        );
      this.setState({
        userProfile: user.user,
        loading: false
      });
    }
  }
  checkvalidinput(variable, value) {
    console.log(variable)
    console.log(value)
    if (
      variable === "businessname" ||
      variable === "contact" ||
      variable === "buildingaddress" ||
      variable === "streetaddress" ||
      variable === "suburb" ||
      variable === "city" ||
      variable === "province" ||
      variable === "areacode"
    ) {

      if ( typeof value === "undefined") return true;
      else if (value.trim() === "") return true;
      else return false;
    } else if (
      variable === "contactphone" ||
      variable === "contactphonealternate"
    ) {
      if ( typeof value === "undefined") return true;
      if ( value === null) return true;
      else if (value.trim() === "") return true;
      else if (
        value.trim().replace("+27", "0").replace(/\D/g, "").length !== 10
      )
        return true;
      else return false;
    } else return true;
  }
  async updateProfilePicture(input) {
    let imgs = await Utils.uploadtoS3(input.split(",")[1]);
    var olduser = this.state.userProfile;
    olduser.profilepic = JSON.stringify({ key: imgs.key });
    await Utils.updateprofilepic(olduser);
    window.location.reload();
  }
  async saveUser(input) {
    if (input.businessname === "") input.businessname = null;
    if (input.contact === "") input.contact = null;
    if (input.buildingaddress === "") input.buildingaddress = null;
    if (input.streetaddress === "") input.streetaddress = null;
    if (input.suburb === "") input.suburb = null;
    if (input.city === "") input.city = null;
    if (input.province === "") input.province = null;
    if (input.areacode === "") input.areacode = null;
    if (input.contactphone === "") input.contactphone = null;
    if (input.contactphonealternate === "") input.contactphonealternate = null;
    if (input.vatnumber === "") input.vatnumber = null;

    if (typeof input.profilepic.uri !== "undefined")
      delete input.profilepic.uri;
    input.profilepic = JSON.stringify(input.profilepic);
    const save = await Utils.saveUser(input);
    if (save.success) this.props.history.push("/profile");
  }
  async takeImage() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    if (status === "granted") this.setState({ cameravisible: true });
  }

  render() {
    if (this.state.loading)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    else
      return (
        <div style={{ color: "#666", margin: isMobile ? 5 : 0 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
          <h2>{this.state.userProfile.businessname}</h2>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <div
              style={{
                width: isMobile ? "98%" : "50%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <ExifOrientationImg
                ref={this.myRef}
                alt=""
                src={this.state.userProfile.profilepic.uri}
                style={{ width: "100%", objectFit: "contain" }}
                onLoad={() => {
                  // console.log(this.myRef.current.state.orientation);
                  // console.log(this.myRef.current._reactInternalFiber.child.stateNode.clientHeight);
                  // console.log(this.myRef.current._reactInternalFiber.child.stateNode.clientWidth);
                }}
              />
              <ImageUploader
                withIcon={true}
                buttonText="Choose profile image"
                onChange={(pictureFiles, pictureDataURLs) => {
                  // Utils.log(pictureFiles)
                  // Utils.log(pictureDataURLs)
                  // this.setState({ pictures: pictureDataURLs });
                  this.updateProfilePicture(pictureDataURLs[0]);
                }}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginLeft: isMobile ? 0 : 20,
                marginRight: isMobile ? 0 : 20
              }}
            >
              <div></div>
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="Business Name"
                error={this.state.userProfile.businessname===null || this.state.userProfile.businessname.trim()===""? true:false}
                helperText={this.state.userProfile.businessname===null || this.state.userProfile.businessname.trim()===""? "Cannot be blank":false}
                value={this.state.userProfile.businessname}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.businessname = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                error={this.state.userProfile.contact===null || this.state.userProfile.contact.trim()===""? true:false}
                helperText={this.state.userProfile.contact===null || this.state.userProfile.contact.trim()===""? "Cannot be blank":false}

                label="Contact person"
                value={this.state.userProfile.contact}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.contact = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                error={this.state.userProfile.buildingaddress===null || this.state.userProfile.buildingaddress.trim()===""? true:false}
                helperText={this.state.userProfile.buildingaddress===null || this.state.userProfile.buildingaddress.trim()===""? "Cannot be blank":false}

                label="Building address"
                value={this.state.userProfile.buildingaddress}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.buildingaddress = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="Street address"
                error={this.state.userProfile.streetaddress===null || this.state.userProfile.streetaddress.trim()===""? true:false}
                helperText={this.state.userProfile.streetaddress===null || this.state.userProfile.streetaddress.trim()===""? "Cannot be blank":false}

                value={this.state.userProfile.streetaddress}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.streetaddress = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="Suburb"
                error={this.state.userProfile.suburb===null || this.state.userProfile.suburb.trim()===""? true:false}
                helperText={this.state.userProfile.suburb===null || this.state.userProfile.suburb.trim()===""? "Cannot be blank":false}

                value={this.state.userProfile.suburb}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.suburb = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="City"
                error={this.state.userProfile.city===null || this.state.userProfile.city.trim()===""? true:false}
                helperText={this.state.userProfile.city===null || this.state.userProfile.city.trim()===""? "Cannot be blank":false}

                value={this.state.userProfile.city||""}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.city = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="Province"
                error={this.state.userProfile.province===null || this.state.userProfile.province.trim()===""? true:false}
                helperText={this.state.userProfile.province===null || this.state.userProfile.province.trim()===""? "Cannot be blank":false}

                value={this.state.userProfile.province||""}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.province = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                error={this.state.userProfile.areacode===null || this.state.userProfile.areacode.trim()===""? true:false}
                helperText={this.state.userProfile.areacode===null || this.state.userProfile.areacode.trim()===""? "Cannot be blank":false}

                label="Area code"
                value={this.state.userProfile.areacode||""}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.areacode = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="Phone"
                error={this.state.userProfile.contactphone===null || this.state.userProfile.contactphone.trim()===""? true:false}
                helperText={this.state.userProfile.contactphone===null || this.state.userProfile.contactphone.trim()===""? "Cannot be blank":false}

                value={this.state.userProfile.contactphone||""}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.contactphone = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="Phone (alternative)"
                value={this.state.userProfile.contactphonealternate||""}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.contactphonealternate = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799" }}
                id="outlined-name"
                label="VAT number (optional)"
                value={this.state.userProfile.vatnumber||""}
                onChange={change => {
                  var userprofiletemp = this.state.userProfile;
                  userprofiletemp.vatnumber = change.target.value;
                  this.setState({ userProfile: userprofiletemp });
                }}
                margin="normal"
                variant="outlined"
              />

              <Button
                style={{
                  margin: 5,
                  backgroundColor: "#00a0e2",
                  color: "#fff",
                  alignSelf: "center",
                  width: "100%",
                  marginBottom: 50
                }}
                variant="contained"
                onClick={() => {
                  if (this.state.userProfile.businessname === null)
                    this.doToast("Business name cannot be blank");
                  else if (this.state.userProfile.businessname.trim() === "")
                    this.doToast("Business name cannot be blank");
                  else if (this.state.userProfile.buildingaddress === null)
                    this.doToast("Business address cannot be blank");
                  else if (this.state.userProfile.buildingaddress.trim() === "")
                    this.doToast("Business address cannot be blank");
                  else if (this.state.userProfile.streetaddress === null)
                    this.doToast("Business street address cannot be blank");
                  else if (this.state.userProfile.streetaddress.trim() === "")
                    this.doToast("Business street address cannot be blank");
                  else if (this.state.userProfile.city === null)
                    this.doToast("Business city cannot be blank");
                  else if (this.state.userProfile.city.trim() === "")
                    this.doToast("Business city cannot be blank");
                  else if (this.state.userProfile.province === null)
                    this.doToast("Business provice cannot be blank");
                  else if (this.state.userProfile.province.trim() === "")
                    this.doToast("Business provice cannot be blank");
                  else if (this.state.userProfile.areacode === null)
                    this.doToast("Business area code cannot be blank");
                  else if (this.state.userProfile.areacode.trim() === "")
                    this.doToast("Business area code cannot be blank");
                  else if (this.state.userProfile.contactphone === null)
                    this.doToast("Contact phone cannot be blank");
                  else if (this.state.userProfile.contactphone.trim() === "")
                    this.doToast("Contact phone cannot be blank");
                  else if (this.state.userProfile.contact === null)
                    this.doToast("Contact name cannot be blank");
                  else if (this.state.userProfile.contact.trim() === "")
                    this.doToast("Contact name cannot be blank");
                  else if (
                    this.state.userProfile.contactphone.replace("+27", "0").length !==
                    10
                  )
                    this.doToast("Contact phone length invalid");
                else  this.saveUser(this.state.userProfile);
                }}
              >
                Save
              </Button>
            </div>
          </div>
          <div style={{ width: 1000 }} />
        </div>
      );
  }
}

export default withRouter(Profile);
