import React, { Component } from "react";
// import { withRouter } from "react-router-dom";

export default class Privacy extends Component {
  render() {
    return (
      <div>
        <h1>Shipping FAQ</h1>

        <h3>Payment methods</h3>
        <p>
          We accept Visa and Mastercard credit card payments. To pay by EFT,
          send an email to info@scrapapp.co.za with a list of items you intend on
          buying. We will send you an invoice and upon confirmation of the
          deposit, we will pick up the items and ship them to you.
        </p>

        <h3>Shipping</h3>
        <p>
          Once your payment is successful, you will receive and email confirming
          the items with shipping tracking information.
        </p>

        <h3>Cost of shipping</h3>
        <p>
          The cost of shipping is included in the fee paid by the seller. This
          fee covers pick up from the seller and fee to ship to customers.
        </p>

        <h3>Fee to the seller</h3>
        <p>The fee paid by the seller is 20% of the listing price.</p>

        <h3>Refunds</h3>
        <p>
          The items on offer are used goods and likey to have wear and/or
          damage. The items purchased are only eligible for refund if there is a
          material difference between the listed condition and the condition on
          arrival.
        </p>
        <h3>Accuracy of listing</h3>
        <p>
          It is important to list any damage and detail the description of the
          condition of the item. Differences in listing and reality will result
          in refund being requested by the customer. Repeat issues in listing
          accuracy will result in disabling of the account.
        </p>
      </div>
    );
  }
}
