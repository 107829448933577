import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
import Auth from "@aws-amplify/auth";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: "",
      userProfile: {
        areacode: "",
        availableslots: 0,
        buildingaddress: "",
        businessname: "",
        contact: "",
        contactemail: "",
        contactphone: "",
        contactphonealternate: "",
        federatedid: "",
        city: "",
        id: "",
        listings: {},
        profilepic: { uri: "" },
        province: "",
        streetaddress: "",
        suburb: "",
        user: null,
        verified: false
      },
      loading: true
    };
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    let cog = await Auth.currentAuthenticatedUser();
    if (cog) {
      this.setState({ authed: true });
      Utils.log(cog);
      var username = "";
      if (cog.username) username = cog.username;
      else username = cog.id;
      let user = await Utils.getUserProfileById(username);
      Utils.log(user);
      if (user.user.profilepic === null)
        user.user.profilepic = await Utils.getLatestPic("defaultpic.png");
      else
        user.user.profilepic = await Utils.getLatestPic(
          JSON.parse(user.user.profilepic).key
        );
      this.setState({
        userProfile: user.user,
        loading: false
      });
    }
  }

  async updateProfilePicture(input) {
    let imgs = await Utils.uploadtoS3(input.split(",")[1]);
    var olduser = this.state.userProfile;
    // Utils.log(this.state.userProfile)
    console.log(imgs);
    olduser.profilepic = JSON.stringify({ key: imgs.key });
    //this.setState({userProfile:olduser});

    await Utils.updateprofilepic(olduser);
    window.location.reload();
  }

  render() {
      return (
        <div style={{ display: "flex", justifyContent: "center" , flexDirection: 'column'}}>
          <h1 style={{ color: "#444" }}>Verification</h1>
          <div style={{ marginTop: 20, color: "#444" }}>
            The Scrap Store allows only verified sellers to list items on the
            platform.
          </div>
          <h2 style={{ marginTop: 40, color: "#444" }}>How to apply</h2>
          <div style={{ marginTop: 20, color: "#444" }}>
            Send your business name, CIPC registration documents, one month bank
            statement to info@scrapapp.co.za
          </div>
          <div style={{ marginTop: 20, color: "#444" }}>
            Verification takes 48 hours and you you will then have access to
            list products.
          </div>
        </div>
      );

  }
}

export default withRouter(Profile);
